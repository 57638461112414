import React from "react";

const iframeStyle = {
  border: 0,
  width: "100%",
  height: "350px",
  marginTop: "20px",
};

const Contact = () => {
  return (
    <>
      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>VISIT US</h2>
            <p>
              Request you to please contact us on the below mentioned address
              and other details
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box p-4">
                    <i className="bx bx-map"></i>
                    <h3>Our Address</h3>
                    {/* <p>
                      #5/1, 4th Main, MRCR Layout, Magadi Main Rd, Govindaraja
                      Nagar Ward, MC Layout, Vijayanagar, Bengaluru, Karnataka
                      560040
                    </p> */}
                    <div>
                      <iframe
                        style={iframeStyle}
                        /*  src="https://www.google.com/maps/place/CUTIS+Academy+of+Cutaneous+Sciences+-+CUTIS+Hospital+%E0%B2%95%E0%B3%8D%E0%B2%AF%E0%B3%81%E0%B2%9F%E0%B2%BF%E0%B2%B8%E0%B3%8D+%E0%B2%85%E0%B2%95%E0%B3%8D%E0%B2%AF%E0%B2%BE%E0%B2%A1%E0%B3%86%E0%B2%AE%E0%B2%BF+%E0%B2%86%E0%B2%AB%E0%B3%8D+%E0%B2%95%E0%B3%8D%E0%B2%AF%E0%B3%81%E0%B2%9F%E0%B3%87%E0%B2%A8%E0%B2%BF%E0%B2%AF%E0%B2%B8%E0%B3%8D+%E0%B2%B8%E0%B3%88%E0%B2%A8%E0%B3%8D%E0%B2%B8%E0%B3%8D+%E0%B2%B8%E0%B3%8D/@12.9764129,77.5341483,14.77z/data=!4m5!3m4!1s0x3bae3e7745e40249:0xe19453ab9e37d117!8m2!3d12.9769244!4d77.5449202" 
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" 
                       */
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9223560010096!2d77.54275751413523!3d12.97681781827651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e7745e40249%3A0xe19453ab9e37d117!2zQ1VUSVMgQWNhZGVteSBvZiBDdXRhbmVvdXMgU2NpZW5jZXMgLSBDVVRJUyBIb3NwaXRhbCDgspXgs43gsq_gs4Hgsp_gsr_gsrjgs40g4LKF4LKV4LON4LKv4LK-4LKh4LOG4LKu4LK_IOCyhuCyq-CzjSDgspXgs43gsq_gs4Hgsp_gs4fgsqjgsr_gsq_gsrjgs40g4LK44LOI4LKo4LON4LK44LONIOCyuOCzjQ!5e0!3m2!1sen!2sin!4v1616768400781!5m2!1sen!2sin"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              {" "}
              <div className="col-lg-6 mt-4 float-md-right">
                <form
                  action="https://digikit.in/cutis-contact-form/save.php"
                  method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div
                    className="info-box "
                    style={{ boxShadow: "0 0 0px rgb(214 215 216 / 50%)" }}
                  >
                    <i className="bx bxs-contact"></i>
                    <h3>Contact Us</h3>
                  </div>
                  <div className="form-row"></div>
                  <div className="form-row">
                    <div className="col form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                        required
                      />
                      <div className="validate"></div>
                    </div>
                    <div className="col form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                        required
                      />
                      <div className="validate"></div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                      required
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Your Phone Number"
                      minLength="10"
                      maxLength="10"
                      pattern="^[6-9]\d{9}$"
                      className="form-control"
                      required
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      data-rule="required"
                      data-msg="Please write something for us"
                      placeholder="Message"
                    ></textarea>
                    <div className="validate"></div>
                  </div>
                  <div className="mb-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
              <div className="col-lg-6">
                <div className="col-md-12">
                  <div className="info-box mt-4">
                    <i className="bx bx-phone-call"></i>
                    <h3>Call Us</h3>
                    <p className="phone-links">
                      <a href="tel:08023401200">080-2340-1200 (Consultation)</a>
                      <br />
                      {/* <a href="tel:9845925111">98459-25111 (Procedure)</a>
                      <br /> */}
                      <a href="tel:6366588485">63665-88485 (Cutis Kart)</a>
                      <br />
                      <a href="tel:6366589797">63665-89797 (Laboratory)</a>
                      <br />
                      <a href="tel:6366969572">63669-69572 (Pharmacy)</a>

                      {/* 080-2340-1200 (Consultation),&nbsp; <br />
                      98459-25111 (Procedure), <br />
                      63665-88485 (Pharmacy), <br />
                      63665-89797 (Laboratory) */}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="info-box mt-4">
                    <i className="bx bx-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      <a
                        href="mailto:care@cutis.org.in"
                        className="email-links"
                      >
                        care@cutis.org.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="">
          <iframe
            style={iframeStyle}           
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9223560010096!2d77.54275751413523!3d12.97681781827651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e7745e40249%3A0xe19453ab9e37d117!2zQ1VUSVMgQWNhZGVteSBvZiBDdXRhbmVvdXMgU2NpZW5jZXMgLSBDVVRJUyBIb3NwaXRhbCDgspXgs43gsq_gs4Hgsp_gsr_gsrjgs40g4LKF4LKV4LON4LKv4LK-4LKh4LOG4LKu4LK_IOCyhuCyq-CzjSDgspXgs43gsq_gs4Hgsp_gs4fgsqjgsr_gsq_gsrjgs40g4LK44LOI4LKo4LON4LK44LONIOCyuOCzjQ!5e0!3m2!1sen!2sin!4v1616768400781!5m2!1sen!2sin"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div> */}
      </section>
      {/* End Contact Section */}
    </>
  );
};

export default Contact;
