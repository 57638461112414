import React from "react";
import FaqList from "./FaqList";

const Pat = ({ faqArr }) => {
  return (
    <>
      {/* ======= Frequently Asked Questioins Section ======= */}
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 faqSecDiv">
              <div className="section-title"></div>

              <FaqList arr={faqArr} />
            </div>
          </div>
        </div>
      </section>
      {/* End Frequently Asked Questioins Section */}
    </>
  );
};

export default Pat;
