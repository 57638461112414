import React from "react";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import EmptySpace from "./EmptySpace";
import SectionTitle from "../home/SectionTitle";
import downloadPdf from "../../assets/application-fellowship.docx";
import notification from "../../assets/notification-23-24.pdf";
import application from "../../assets/application-fellowship-23-24-1.pdf";
import essay from "../../assets/essay.pdf";
import fellowhipImg from "../../assets/img/felloqhip_img1.jpg";
const App = () => (
  <>
    <Container className="p-3 d-flex justify-content-center">
      <img
        loading="lazy"
        className="fellowshipimage"
        src={fellowhipImg}
        alt="Clinical Fellowship Program Excellence"
      />
    </Container>
    <Container className="p-3">
      <p className="fellowshipPTag text-center">
        CUTIS Academy of Cutaneous Sciences (CACS) is affiliated to Rajiv Gandhi
        University Of Health Sciences (RGUHS).
      </p>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Course</th>
            <th>Number Of Seats</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dermatosurgery</td>
            <td>04</td>
            <td>18 Months</td>
          </tr>
          <tr>
            <td>Aesthetic Dermatology</td>
            <td>04</td>
            <td>12 Months</td>
          </tr>
          <tr>
            <td>Paediatric Dermatology</td>
            <td>04</td>
            <td>12 Months</td>
          </tr>
          <tr>
            <td>Trichology</td>
            <td>04</td>
            <td>12 Months</td>
          </tr>
        </tbody>
      </Table>

      <EmptySpace height={30} />

      <Tabs
        defaultActiveKey="home"
        className="fellowship-tabs"
        id="uncontrolled-tab-example"
      >
        {/*
                defaultActiveKey="mode_of_apply"
                 <Tab
          eventKey="fellowship23"
          title="Fellowship 2022 - 23"
          className="fellowhipNew"
        >
          <div className="tabData">
           

            <ul>
              <li>Fellowship Offered:</li>

              <ul style={{ marginLeft: "5%" }}>
                <li> Dermatosurgery</li>
                <li> Aesthetic Dermatology </li> <li>Pediatric Dermatology</li>
                <li>Trichology- medical & surgical </li>
              </ul>

              <li> Duration of the course: 12 months</li>
              <li> Last date for submission: 5-08-2022</li>
              <li> Examination & Interview to be held on: 11-08-2022</li>
              <li> Nature of the exam: Multiple Choice Questions </li>
              <li>Venue - Written test / Interview at Cutis</li>
            </ul>
          </div>
        </Tab> */}
        <Tab eventKey="home" title="Admission Procedure">
          <div className="tabData">
            <p className="fellowshipTabPTag">
              These courses are affiliated RGUHS. Admission procedure is through
              advertisement for application from eligible candidates, offline
              interview from expert committee for selection of successful
              candidates.
            </p>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Fellowship Eligibility">
          <div className="tabDatas">
            <Table responsive="md" striped bordered hover>
              <thead>
                <tr>
                  <th>4 Seats in each Fellowship</th>
                  <th>Duration of the course</th>
                  <th className="tabWidthth">Working Hours</th>
                  <th>Eligibility</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Institutional Candidates should send their application
                    through proper channel
                  </td>
                  <td>12 Months</td>
                  <td>
                    Monday, Tuesday, Thursday: 9 A.M. – 8 P.M
                    <br />
                    <br />
                    Wednesday, Friday: 10 A.M. – 8 P.M <br />
                    (Class/CME – 09 A.M. – 10 A.M.) <br />
                    <br />
                    Saturday: 9 A.M – 5 P.M
                    <br />
                  </td>
                  <td>
                    <b>The eligibility for admission is as Follows</b>
                    <ol type="I">
                      <li>
                        Candidates with Postgraduate Degree in concerned
                        specialty or
                      </li>
                      <li>
                        Candidates with PG Diploma in concerned specialty with
                        three years experience
                      </li>
                      <li>DM/MCH/DNB in concerned specialty</li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="contact" title="Fellowship Syllabus">
          <EmptySpace height={30} />
          <SectionTitle title={"Fellowship Syllabus"} />

          <Tabs defaultActiveKey="fellow1" id="uncontrolled-tab-example">
            <Tab eventKey="fellow1" title="Dermatosurgery Training">
              <div className="row">
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Basic Principles of Dermatosurgery</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Introduction to Dermato-surgery </li>
                      <li> Documentation </li>
                      <li> Applied Anatomy for the Dermato-surgeon </li>
                      <li> Wound Healing </li>
                      <li> Wound Management </li>
                      <li>Instruments, Equipments and Universal precautions</li>
                      <li> Wound closure materials and suturing techniques </li>
                      <li> Preoperative preparation </li>
                      <li> Anaesthesia in Dermato-surgery </li>
                      <li>Emergencies in Dermato-surgery</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Standard Dermato - Surgical Procedures</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Skin biopsy techniques </li>
                      <li> Electro-surgery </li>
                      <li> Curettage </li>
                      <li>Intralesional Therapy </li>
                      <li>Chemical Cauterization </li>
                      <li> Cryosurgery </li>
                      <li>
                        Elliptical or fusiform excision and its variations
                      </li>
                      <li>
                        Skin Grafting for vitiligo surgeries using blister skin
                        grafting, epidermal skin grafting and split thickness
                        grafting.
                      </li>
                      <li>Epidermal cyst excision </li>
                      <li>Surgical removal (Enucleation) of corn </li>
                      <li>Ablative laser procedures</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Special Surgical Procedures</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Superficial and medium depth chemical peeling </li>
                      <li> Surgical management of infections</li>
                      <li>Scar revision </li>
                      <li>Pitted facial scar revision </li>
                      <li> Dermabrasion </li>
                      <li> Nail Surgery </li>
                      <li>Hair replacement surgery </li>
                      <li>
                        Dermato-surgery for lesions of male genitalia like
                      </li>
                      <ul style={{ marginLeft: "10px" }}>
                        <li>
                          Circumcision for chronic balanoposthitis, recurrent
                          genital herpes
                        </li>
                        <li>Dorsal slitting </li>
                        <li>Fraenulo Plasty</li>
                      </ul>

                      <li>Tattoo removal </li>
                      <li> Liposuction </li>
                      <li> Dermal filler substances like </li>
                      <ul style={{ marginLeft: "10px" }}>
                        <li>Bovine Collagen</li>
                        <li>Porcine Collagen</li>
                        <li>Autologous Collagen</li>
                        <li>
                          Autologous fat for 1) Depressed scars 2) Wrinkles 3)
                          Dermal atrophy 4) Enhancement of facial contour
                        </li>
                      </ul>
                      <li>Cutaneous laser surgery </li>
                      <li>Tissue expansion surgery </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Vitiligo Surgery</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Introduction to Vitiligo Surgery </li>
                      <li>Tattooing </li>
                      <li>Thin Thiersch’s Split thickness skin grafting </li>
                      <li> Suction blister technique </li>
                      <li>Miniature punch grafting </li>
                      <li> Therapeutic spot and regional Dermabrasion </li>
                      <li>
                        Skin culture- cultured epidermal autografts containing
                        keratinocytes and melanocytes for vitiligo
                        repigmintation.
                      </li>
                      <li>Gems in Dermatosurgery like –</li>
                      <ul style={{ marginLeft: "10px" }}>
                        <li>Pinch grafting for nonhealing ulcers (NHU)</li>
                        <li>
                          Narrow hole extrusion technique (NHET) for lipomas and
                          epidermalcysts.
                        </li>
                        <li>
                          Lateral nail matrix phenolization for ingrowing nails.
                        </li>
                        <li>
                          Regional undermining (subcision) followed by cryoslush
                          for scars and wrinkles (at the same sitting)
                        </li>
                        <li>
                          Liquid nitrogen (LN2) cryosurgery immediately followed
                          by podophyllin application for large, recalcitrant
                          venereal warts (at the same sitting).
                        </li>
                        <li>
                          Liquid nitrogen (LN2) cryosurgery with intralesional
                          corticosteroids (ILS) followed after healing, by
                          silastic gel sheet (SGS) dressing for hypertrophic
                          scars and keloids.
                        </li>
                        <li>
                          Minor gems: Scissor excision for benign, pedunculated
                          growths; light fulguration for dermatosis papulosa
                          nigra; LA-dorsal approach for plantar lesions and
                          rapid haemostasis by electrodesiccation in office
                          surgery.
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="fellow2" title="Aesthetic Dermatology Training">
              <div className="row">
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Introduction to Basic science of Cosmetology </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>
                        Basic Science of the Epidermis / Dermis / Subcutaneous
                        Layer
                      </li>
                      <li> Immunology of the Skin </li>
                      <li> Hormones and Aging Skin </li>
                      <li>Photo aging </li>
                      <li> Cigarettes and Aging Skin </li>
                      <li> Nutrition and the Skin </li>
                      <li>
                        Skin Type (The Baumann Skin Typing System, Oily Skin,
                        Dry Skin, Sensitive Skin)
                      </li>
                      <li> Principles governing Cosmetological procedures</li>
                      <li>Instruments, Equipments and Universal precautions</li>
                      <li> Documentation and Photography</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Specific Dermatological Conditions </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Acne and it’s scars </li>
                      <li> Rosacea</li>
                      <li> Burning and Stinging Skin</li>
                      <li>Sensitive Skin</li>
                      <li> Contact Dermatitis</li>
                      <li> Wrinkled Skin </li>
                      <li> Prevention and Treatment of Bruising </li>
                      <li>Androgenic alopecia </li>
                      <li> Hirsutism </li>
                      <li> Hair loss in Women </li>
                      <li> Pigmentary disorders </li>
                      <li>Cosmetic problems of nails </li>
                      <li>Hyperhidrosis/Bromhidrosis/Chromhidrosis </li>
                      <li> Aging skin problems </li>
                      <li> Straie</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Cosmetic Procedures</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Chemical Peels </li>
                      <li> Botulinum Toxin </li>
                      <li> Dermal Fillers -----HA and Bio fillers.</li>
                      <li>Acne Scars: Lasers and Surgeries</li>
                      <li> Pigment Lasers</li>
                      <li>Vascular Lasers</li>
                      <li>Body Contouring</li>
                      <li> Non-surgical face lifts—HIFU, er:YAG</li>
                      <li> Post traumatic scar treatment</li>
                      <li> Laser hair reduction</li>
                      <li> Skin Rejuvenation</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Surgical Procedures in Cosmetology</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Surgery for active acne </li>
                      <li> Mole surgery </li>
                      <li> Ear and Nose piercing </li>
                      <li>Ear lobe repair </li>
                      <li> Decorative Cosmetic tattooing </li>
                      <li> Non ablative radio frequency</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Miscellaneous </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Adverse reactions to cosmetics </li>
                      <li> Professional ethics </li>
                      <li> Personal care and Cosmetic products </li>
                      <li> Household cosmetics </li>
                      <li> Facials </li>
                      <li>
                        Cosmetic care and counselling for normal and problematic
                        skin
                      </li>
                      <li> Nonsurgical hair replacement </li>
                      <li> Cosmetic camouflage </li>
                      <li>Common cosmetic techniques in beauty parlours</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Pharmacotherapy in Cosmetology </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Cosmetic and Drug Regulation </li>
                      <li> Sunscreens</li>
                      <li> Retinoids</li>
                      <li> Cleansing Agents </li>
                      <li> Moisturizing Agents </li>
                      <li> Depigmenting Agents </li>
                      <li> Antioxidants</li>
                      <li>Anti-inflammatory Agents </li>
                      <li> Fragrance </li>
                      <li> Preservatives</li>
                      <li> Cosmeceuticals</li>
                      <li> Biomimetic peptides.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="fellow3" title="Trichology Training">
              <div className="row">
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Essential knowledge </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>History of Trichology </li>
                      <li>Hair Anatomy</li>
                      <ul style={{ marginLeft: "10px" }}>
                        <li>Embryology & genetics </li>
                        <li>Anatomy- follicle, distribution, types (Basics)</li>
                        <li>Physiology- hair cycle, molecular biology</li>
                      </ul>
                      <li>Approach to a patient with hair disorders</li>
                      <ul style={{ marginLeft: "10px" }}>
                        <li>Embryology & genetics </li>
                        <li>Anatomy- follicle, distribution, types (Basics)</li>
                        <li>Physiology- hair cycle, molecular biology</li>
                      </ul>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Genetic disorders </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li> Hair shaft disorders</li>
                      <li> Hair in syndromes</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Hair and nutrition </h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Infections and infestations</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Bacterial infections</li>
                      <li>Fungal infections</li>
                      <li> Infestations</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Scalp disorders</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li> Scaling disorders </li>
                      <li> Itchy scalp & folliculitis </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Pigmentary hair disorders </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li> Premature graying </li>
                      <li> Poliosis </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Hair tumours</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Hair cosmetics</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Hair dyes</li>
                      <li>Cosmetic procedures and their effects</li>
                      <li>Artificial hair –wigs, hair fixtures</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Psychiatric disorders</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Excessive hair</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Hirsuitism</li>
                      <li>Hypertrichosis</li>
                      <li> Management</li>
                      <li>LASER hair reduction</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Hair loss Diffuse & patterned.</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Alopecia areata, </li>
                      <li> Androgenetic alopecia</li>
                      <li> TE, drugs, endocrine</li>
                      <li> Female pattern hair loss</li>
                      <li> Cicatricial alopecia</li>
                      <li>
                        Hair changes due to systemic / internal diseases and
                        malignancy
                      </li>
                      <li> Related to drugs</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Principles of treatment</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Hair Restoration Surgeries</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Follicular unit transplantation</li>
                      <li> Follicular unit extraction </li>
                      <li>Body hair transplantation</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Mesotherapy for hair</h5>
                  <hr />
                  <div className="tabData">
                    <p>
                      Platelet rich plasma,Botolinum toxin, Stem cell therapy (
                      Rigenera )
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Dermoscopic findings & evaluation using FOTO FINDER</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>

                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>
                    Code of ethics & privacy policy in hair and trichology
                    clinic.
                  </h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Essential investigation and diagnostic procedures:</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Methods of hair sample collection</li>
                      <li> Hair pull test</li>
                      <li> Trichogram</li>
                      <li>Microscopic examination of hair</li>
                      <li> Hair window test</li>
                      <li> Dermatoscopy</li>
                      <li> Biopsy and histopathological interpretation</li>
                      <li> Trichoscan/Densitometry</li>
                      <li> Wood’s lamp examination.</li>
                      <li>
                        Hormonal assay for hirsuitism, hair loss in women.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="fellow4" title="Pediatric Dermatology Training">
              <div className="row">
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Neonatal Dermatology </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Physiology of newborn skin</li>
                      <li>Skin care of the newborn</li>
                      <li>Transient neonatal dermatoses</li>
                      <li>Developmental abnormalities</li>
                      <li>Neonatal infections</li>
                      <li>Neonatal erythroderma</li>
                      <li>Collodion baby and harlequin ichthyosis</li>
                      <li>Neonatal lupus erythematosus</li>
                      <li>Disorders of the subcutaneous tissue in newborns</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Infections and Infestations </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Bacterial infections</li>
                      <li>
                        Viral infections/Viral exanthema Fungal infections
                      </li>
                      <li>Mycobacterial infections</li>
                      <li>Leprosy</li>
                      <li>HIV infection in children</li>
                      <li>Parasitic infestations</li>
                      <li>Scabies</li>
                      <li>Pediculosis</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Eczemas </h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Atopic dermatitis</li>
                      <li>Seborrheic dermatitis</li>
                      <li>Pompholyx</li>
                      <li>Allergic contact dermatitis</li>
                      <li>Napkin dermatitis</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Urticaria and Erythemas</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Urticaria</li>
                      <li>Papular urticaria</li>
                      <li>Mastocytosis</li>
                      <li>Annular erythemas</li>
                      <li>Erythema nodosum</li>
                      <li>
                        Erythema multiforme, Stevens Johnson syndrome and Toxic
                        Epidermal
                      </li>
                      <li>Necrolysis</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Papulosquamous disorders</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Psoriasis</li>
                      <li>Pityriasisrubra pilaris</li>
                      <li>Pityriasisrosea</li>
                      <li>Lichen planus and lichen nitidus Lichen striatus</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Vesiculobullous disorders</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Linear lgA bullous disorder of childhood</li>
                      <li>Pemphigus and bullous pemphigoid </li>
                      <li>Dermatitis herpetiformis</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Nodular disorders</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Granuloma annulare</li>
                      <li>Angiolymphoid hyperplasia with eosinophilia</li>
                      <li>Adnexal disorders</li>
                      <li>
                        Cutaneous calcification and ossification Knuckle pads
                      </li>
                      <li>Fibromatoses</li>
                      <li>Cutaneous malignancies</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Lymphocytic disorders and histiocytoses</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Pityriasislichenoides</li>
                      <li>Jessner's lymphocytic infiltrate</li>
                      <li>Cutaneous lymphomas</li>
                      <li>Histiocytosis</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Disorders of pigmentation</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Disorders of hypopigmentation</li>
                      <li>Disorders of hyperpigmentation</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Vascular and lymphatic abnormalities</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Infantile hemangiomas and other vascular turnours</li>
                      <li>Vascular malformations</li>
                      <li>Lymphatic abnormalities</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Disorders of cutaneous appendages</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Disorders of oral mucosa</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Disorders of hair and nail</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Anogenitaldiorders</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Genital disease in children</li>
                      <li>Vulvovaginitis and lichen sclerosis</li>
                      <li>Sexually transmitted diseases</li>
                      <li>Child abuse</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Systemic disorders</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Acute hemorrhagic edema of infancy</li>
                      <li>HenochSchonleinpurpura/PAN</li>
                      <li>Purpurafulminans</li>
                      <li>The vasculitiides</li>
                      <li>Urticarial vasculitis</li>
                      <li>Kawasaki disease</li>
                      <li>Sweet syndrome</li>
                      <li>Sarcoidosis</li>
                      <li>Amyloidosis</li>
                      <li>Inherited metabolic disorders</li>
                      <li>Nutritional dermatoses</li>
                      <li>Cutaneous manifestations of endocrine disease</li>
                      <li>Collagen vascular diseases</li>
                      <li>Autoinflammatory syndromes</li>
                      <li>Immunodeficiency syndromes</li>
                      <li>GVHD</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Psychocutaneous disorders in children</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Genodermatoses</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Principles of genetics</li>
                      <li>Epidermolysis bullosa</li>
                      <li>Ichthyosis</li>
                      <li>Netherton syndrome</li>
                      <li>Ectodermal dysplasias</li>
                      <li>Erythrokeratodermas</li>
                      <li>Palmoplantar keratodermas</li>
                      <li>Darier disease</li>
                      <li>Porokeratosis</li>
                      <li>Neurofibromatosis</li>
                      <li>Tuberous sclerosis</li>
                      <li>Pigmentary mosaicism</li>
                      <li>Incontinentiapigmenti</li>
                      <li>Nevoid basal cell carcinoma syndrome</li>
                      <li>Premature aging syndromes</li>
                      <li>
                        Photosensitive genodermatoses Silvery grey hair
                        syndromes
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Disorders of connective tissue</h5>
                  <hr />
                  <div className="tabData">
                    <ul>
                      <li>Ehlers Danlos syndromes</li>
                      <li>Cutis laxa</li>
                      <li>Pseudoxanthoma elasticum</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Disorders of subcutaneous fat</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Photosensitive disorders</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Drug reactions in children</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5> Paediatric dermatological emergencies</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
                <div className="col-md-6">
                  <EmptySpace height={30} />
                  <h5>Dermatosurgery and laser therapy in children</h5>
                  <hr />
                  <div className="tabData"></div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Tab>

        <Tab eventKey="tab4" title="Learning Objectives">
          <EmptySpace height={30} />
          <SectionTitle title={"Learning Objectives"} />

          <Tabs defaultActiveKey="fellow1" id="uncontrolled-tab-example">
            <Tab eventKey="fellow1" title="Aesthetic Dermatology">
              <div className="col-md-12">
                <div className="tabData">
                  <ul>
                    <li>
                      The fellowship student will be taught skills in Aesthetic
                      and clinical dermatology consultation. It would include
                      postings in Medical Trichology, Face clinic and
                      Dermatosurgery clinic
                    </li>
                    <li>
                      They will be able to perform basic cosmetology procedures
                      such as Chemical peels, Radio Surgery, Microneedling,
                      Platelet rich plasma and Microdermabrasion with
                      confidence.
                    </li>
                    <li>
                      They will be able to learn and completely understand the
                      various Lasers and light devices including ablative,
                      Fractional, Hair removal, Pigment and Vascular lasers.
                    </li>
                    <li>
                      Focused training on facial rejuvenation procedures such as
                      Mesobotox, Vampire lift, Radiofrequency facial tightening
                      and Injectables (Dermal Fillers and Botulinum toxin).
                      Hands on training sessions will be conducted periodically.
                    </li>
                    <li>
                      They will be sensitized on basic surgeries jand able to
                      perform Ear lobe repair, Mole surgery, Acne scar surgery
                      and Ear and Nose piercing.
                    </li>
                    <li>
                      The fellowship students will be able to identify the gaps
                      in their understandings and performance based on teaching
                      and training sessions.
                    </li>
                    <li>
                      The knowledge gained will bridge those gaps to reduce
                      complication and improve clinical outcome leading to
                      better patient care
                    </li>
                  </ul>
                </div>
              </div>
            </Tab>
            <Tab eventKey="fellow2" title="Dermatosurgery">
              <div className="col-md-12">
                <div className="tabData">
                  <ul>
                    <li>
                      One year of fellowship in Dermatosurgery is a
                      comprehensive coaching programme that includes hands on
                      training in Basic Dermatosurgery, Laser and light devices,
                      Specific and Advanced Dermatosurgery.
                    </li>
                    <li>
                      Basic Dermatosurgery includes performing mole surgeries
                      (complete/ serial excision), keloid surgeries (debulking
                      /excision / Autoflap), cyst, lipoma and other excision
                      surgeries, radiosurgery and cryosurgery.
                    </li>
                    <li>
                      Training in Laser and light devices include Ablative and
                      Fractional lasers for scars (acne scars/ post traumatic
                      scars), various benign tumours and skin resurfacing; Hair
                      removal lasers for hirsutism, acne keloidalis nuchae, etc;
                      Pigment lasers for nevus, tattoo removal, freckles and
                      various other indications; Vascular lasers for
                      haemangiomas, rosacea, etc.
                    </li>
                    <li>
                      Specific Dermatosurgery includes performing Vitiligo
                      surgeries like mini punch grafting with power punches
                      epidermal cell suspension, melanocyte culture and suction
                      blister grafting; Nail surgeries (complete/ partial nail
                      avulsion, biopsy); Sclerotherapy for varicose veins;
                      various acne scar revision surgeries (excision and
                      grafting/ subcision).
                    </li>
                    <li>
                      Advanced Dermatosurgery includes Scar revision surgery,
                      Flap surgery, Skin grafting, Autologous fat transfer and
                      Hair transplantation (Follicular unit transplantation/
                      Follicular unit extraction/ Body hair transplantation/
                      Aesthetic enhancement of eyebrows/ Beard and moustache
                      reconstruction, etc).
                    </li>
                    <li>
                      Fellowship students also acquire practicing skills in
                      running a Clinical Dermatology, Trichology, Dermatosurgery
                      and Hair transplantation clinic.
                    </li>
                    <li>
                      They will be sensitized in Aesthetic procedures like
                      Botulinum toxin, Fillers, Thread lifts with focus training
                      and hands on sessions.
                    </li>
                    <li>
                      At the end of the Fellowship programme one can confidently
                      perform all the procedures on his/ her own with maximum
                      skill and precision.
                    </li>
                    <li>
                      There will be ample opportunity to have publications in
                      various journals, to contribute chapters to textbooks and
                      to be a part of various research activities.
                    </li>
                    <li>
                      This one year programme is a complete learning of
                      Dermatosurgery, Aesthetic surgery, Trichology and Clinical
                      practising skills provided all under one roof.
                    </li>
                  </ul>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Tab>

        <Tab eventKey="mode_of_apply" title="Mode of applying">
          <div className="tabData">
            {/* <p className="fellowshipTabPTag">
              <a href={downloadPdf} download>
                Download
              </a>
              the application form and apply with Bio-data and relevant
              certificates and application. Kindly upload the scanned copies of
              the filled application form along with the mentioned ( check list
              in application form ) documents to
              <a href="mailto:admin@cutis.org.in">admin@cutis.org.in</a>.
            </p> */}

            <p className="fellowshipTabPTag">
              For More Information Contact Us At <br /> <br />
              <a href="tel:9148894271">9148894271</a> &nbsp; &nbsp;
              <a href="mailto:academy@cutis.org.in">academy@cutis.org.in</a>.
            </p>

            {/* <p className="fellowshipTabPTag">
              Download the fellowship Notification form and Application form
            </p>
            <p className="fellowshipTabPTag">
              Notification form - &nbsp;
              <a target="_blank" href={notification}>
                Link
              </a>
            </p>
            <p className="fellowshipTabPTag">
              Application form - &nbsp;
              <a target="_blank" href={application}>
                Link
              </a>
            </p>
            <p className="fellowshipTabPTag">
              Essay - &nbsp;
              <a target="_blank" href={essay}>
                Link
              </a>
            </p> */}
          </div>
        </Tab>
        <Tab eventKey="admission-fee" title="Admission fee">
          <div className="tabDatas">
            <p className="text-center">
              <strong>
                The admission fee Rs.22,400/- per candidate for fellowship
                course
              </strong>
            </p>
          </div>
        </Tab>
        <Tab eventKey="stipend" title="Stipend">
          <div className="tabData">
            <p className="fellowshipTabPTag">
              <strong>As per the government orders</strong>
            </p>
          </div>
        </Tab>

        <Tab eventKey="Results" title="Results -  2023 batch">
          <div className="tabData table-width justify-content-center align-items-center">
            <p className="fellowshipTabPTag">
              <strong>
                List Of Selected Candidates - & Waiting Candidates - 2023 Batch
              </strong>
            </p>
            <br />
            <p className="fellowshipTabPTag">
              <strong>SUBJECT: DERMATOSURGERY</strong>
              <br />
              <br />
              <Table striped bordered hover width="476px">
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Selected Candidates</th>
                    <th>Waiting Candidates</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>DR.CHETHAN R.H</td>

                    <td>DR.APOORVA V BHARADWAJ </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>DR.VARSHA R KOTI</td>
                    <td>DR.POORNIMA H.R </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>DR.NEHETE SANKET SANJAY</td>
                    {/* <td>DR.SHRIYA KAUSHIK</td> */}
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>DR.KIRAN SHAJ</td>
                  </tr>
                </tbody>
              </Table>
            </p>
            <br />
            <br />
            <p className="fellowshipTabPTag">
              <strong>SUBJECT: AESTHETIC DERMATOLOGY</strong>
              <br />
              <br />

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Selected Candidates</th>
                    {/* <th>Waiting Candidates</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td> DR.SHILPA BINU THOMAS</td>
                    {/* <td>DR.RANJITHA K</td> */}
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>DR.ASHWINI D</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>DR.DHANYA NAYAK</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>DR.MANILA P </td>
                  </tr>
                </tbody>
              </Table>
            </p>
            <br />
            <br />
            <p className="fellowshipTabPTag">
              <strong>SUBJECT: TRICHOLOGY</strong>
              <br />
              <br />

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Selected Candidates</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>DR.CHETAN K</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>DR.DIVYA NAGA SREE</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>DR.BRINDA</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>DR.GRISHMA</td>
                  </tr>
                </tbody>
              </Table>
            </p>
            <br />
            <br />
          </div>
        </Tab>

        {/* <Tab
          eventKey="FellowshipDermatologyApplication"
          title="Fellowship  for Procedural Dermatology  Application"
        >
          <div className="tabData table-width justify-content-center align-items-center">
            <SectionTitle
              title={
                "CUTIS ACADEMY OFFERS FELLOWSHIP PROGRAM IN PROCEDURAL DERMATOLOGY"
              }
            />

            <p className="fellowshipTabPTag fellowshipTabPTagApplication ">
              For More Information Please check the details
              <ul>
                <li>
                  <a href="/procedural-dermatology-brochure.pdf">
                    Procedural Dermatology Brochure
                  </a>
                </li>
                <li>
                  <a href="/application-for-fellowship-programme-in-procedural-dermatology-2024-2026-batch.pdf">
                    <strong> Click here to download the - </strong> Application
                    For Fellowship Programme In Procedural Dermatology -
                    2024-2026 Batch
                  </a>
                </li>
              </ul>
              <br /> <br />
              <br />
            </p>
            <br />
            <br />
            <br />
          </div>
        </Tab> */}

        {/* <Tab
          eventKey="FellowshipDermatologyApplication"
          title="Application For Fellowship 24-25"
        >
          <div className="tabData table-width justify-content-center align-items-center">
            <p className="fellowshipTabPTag fellowshipTabPTagApplication ">
              For More Information Please check the details
              <ul>
                <li>
                  <a href="/fellowship-pdf/notification-fellowship-24-25.pdf">
                    Notification for Fellowship 2024 - 2025
                  </a>
                </li>
                <li>
                  <a href="/fellowship-pdf/application-fellowship-24-25.pdf">
                    Application for Fellowship programme 2024 - 2025
                  </a>
                </li>
                <li>
                  <a href="/fellowship-pdf/essay-topic.pdf">Essay topic</a>
                </li>
              </ul>
              <br /> <br />
              <br />
            </p>
            <br />
            <br />
            <br />
          </div>
        </Tab> */}
      </Tabs>
    </Container>
  </>
);

export default App;

{
  /* <Tab eventKey="Results" title="Results -  2022 batch">
<div className="tabData table-width justify-content-center align-items-center">
  <p className="fellowshipTabPTag">
    
    <strong>
      List Of Selected Candidates - Fellowship Programme - 2022 Batch
    </strong>
  </p>
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: DERMATOSURGERY</strong>
    <br />
    <br />
    <Table striped bordered hover width="476px">
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dr. Ruta Ujjval Joshi</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Faleiro karla Nadine</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Dr. Kulkarni Ojas Dipak</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Dr. Abishek Kumar K. V</td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: AESTHETIC DERMATOLOGY</strong>
    <br />
    <br />

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dr. Ritu Agrawal</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Supreet Kaur Dhillon</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Dr. Prasanna. E. d</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Dr. Ashwini. K. C </td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: PEDIATRIC DERMATOLOGY</strong>
    <br />
    <br />

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dr. Shilpa Mary Philip</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Kadimi Lalithya</td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: TRICHOLOGY</strong>
    <br />
    <br />

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>

          <td>Dr. Vaishnavi. R. Batchu</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Niveditha. N</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Dr. Shaik Sunaina</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Dr. Sandesh Kumar. C</td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
</div>
</Tab> */
}
