import React from 'react';

import img1 from '../assets/img/service/bg/service_banner.jpg';
const img1Div = { backgroundImage: 'url(' + img1 + ')'};

const BgSubHeader = ({title="Title",titleClass="",docDesc=""}) => {
    return (
        <>
            <div className="serviceBg InnerText" style={img1Div}>
                <div className="inner">
                    <h1 className={titleClass}>{title}</h1>

                    {/* <br/>
                    {(docDesc != "") &&
                        <> 
                            <br/><h3 className="docDescClass">{docDesc}</h3>
                        </>
                    } */}
                    <div className="small-separator"></div>
                </div>
            </div>
        </>
    );
}

export default BgSubHeader;
