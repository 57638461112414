import React from "react";
import Layout from "./layout";
import Faq from "./home/Faq";
import {FaqSchema} from '../components/services/ServiceDataFaq'

// import ServiceData from './ServiceData';
// import MoreServices from '../home/featuredService/MajorServices';

import BgSubHeader from "./BgSubHeader";
import HomeFaq from "../data/Faq/home.json";
import img1 from "../assets/img/service/bg/05.jpg";
const img1Div = { backgroundImage: "url(" + img1 + ")" };

const Faqs = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"FAQS"} />
            <Faq faqArr={HomeFaq} />
          </section>
          {
            FaqSchema.map((schema)=><iframe
              srcDoc={schema.schemaCode}
              title="Faq Schema"
              width="0"
              height="0"
            />)
          }
        </main>
      </Layout>
    </>
  );
};

export default Faqs;

{
  /* 
import React from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Layout from './layout';
import Faq from './home/Faq';

// import ServiceData from './ServiceData';
// import MoreServices from '../home/featuredService/MajorServices';

import BgSubHeader from './BgSubHeader';

import HomeFaq from '../data/Faq/home.json';

import img1 from '../assets/img/service/bg/05.jpg';
const img1Div = { backgroundImage: 'url(' + img1 + ')'};


const Faqs = () => {
    let {pathname} = window.location;
    // console.log('href',pathname);

    return (
        <>
            <Layout>
                <main id="main">
                    <section id="serviceHero">
                        <BgSubHeader title={"FAQS"}/>

    <Container className="p-3">
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="Home">
                <Faq faqArr={HomeFaq}/>
            </Tab>
            <Tab eventKey="home1" title="Home">
                <Faq faqArr={HomeFaq}/>
            </Tab>
            <Tab eventKey="home2" title="Home">
                <Faq faqArr={HomeFaq}/>
            </Tab>
        </Tabs>
    </Container>
                    </section>
                </main>
            </Layout>
        </>
    );
}

export default Faqs;

*/
}
