import React from "react";
import FaqList from "./FaqList";
const Faq = ({ faqArr, tlt = "" }) => {
  if (tlt != "") {
    var _i = faqArr.filter((d) => {
      if (d.title == tlt) {
        return d;
      }
    }).length;
    if (_i == 0) return false;
  }
  return (
    <>
      {/* ======= Frequently Asked Questioins Section ======= */}
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 faqSecDiv">
              <div className="section-title">
                <h2>Frequently Asked Questioins</h2>
                <p>
                  Because of environmental changes skin disease will also be on
                  rise . With Corona around, you are not able to meet skin
                  specialist / Dermatologist. So, here are the tips to follow
                  and get relieved from skin disease.
                </p>
              </div>

              <FaqList arr={faqArr} tlt={tlt} />
            </div>
          </div>
        </div>
      </section>
      {/* End Frequently Asked Questioins Section */}
    </>
  );
};

export default Faq;
