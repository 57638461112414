import React from "react";
import { useState } from "react";
//styles
import styles from "./pop.module.css";
// import { AiFillCloseCircle } from "react-icons/ai";
import { useEffect } from "react";
import { useLocation } from "react-router";
export default function PopUp({ show, setShow, handleActiveClick }) {
  const [form, setForm] = useState({
    fname: "",
    email: "",
    phone: "",
  });

  const { location } = useLocation();

  const handleChange = (e) => {
    let { name, value } = e.target;

    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    const TimeoutBlog = setTimeout(() => {
      setShow(true);
    }, 2000);
  }, [window.location.href, location]);

  return (
    <section
      className={`${styles.pop_up_container} ${
        show && styles.pop_up_container_active
      }`}
    >
      <div className={styles.pop_up_bg}></div>
      <div
        className={`${styles.pop_up_width}   ${
          show && styles.pop_up_form_show
        }`}
      >
        <div className={styles.pop_up_title}>
          <h3>Get In Touch</h3>
          {/* <AiFillCloseCircle onClick={() => handleActiveClick()} /> */}
          <svg
            onClick={() => handleActiveClick()}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2zm5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4l-1.6 1.6z"
            />
          </svg>
          <p>{form.fname.length > 1 && `Hi ${form.fname}.`}</p>
        </div>

        <form
          className={`${styles.pop_up_form}`}
          method="POST"
          action="https://digikit.in/forms/cutis/blog/popup.php"
        >
          <div>
            <input
              type="text"
              name="fname"
              placeholder="Name"
              value={form.fname}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={form.email}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="hidden"
              name="url"
              placeholder=""
              value={window.location.href}
            />
          </div>
          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={form.phone}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    </section>
  );
}
