import React from "react";
import HeaderDynamicMenu from "./HeaderDynamicMenu";
import HeaderDynamicMenuHeight from "./HeaderDynamicMenuHeight";

import ServiceNavbar from "../../data/navbar.json";

import resources from "../../data/Navbar/resources.json";
import gallery from "../../data/Navbar/gallery.json";
import ourDoctors from "../../data/Navbar/ourDoctors.json";
import academics from "../../data/Navbar/academics.json";

const Header = () => {
  let ServiceList = ServiceNavbar[0].subMenu;
  let galleryList = gallery[0].subMenu;
  let resourcesList = resources[0].subMenu;
  let ourDoctorsList = ourDoctors[0].subMenu;
  let academicsList = academics[0].subMenu;
  // console.log(ServiceList);
  function menuprevent(e) {
    e.preventDefault();
  }
  return (
    <>
      {/*  ======= Top Bar ======= */}
      <div
        id="topbar"
        className="d-none d-lg-flex align-items-center fixed-top"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-center ">
            {/* <i className="icofont-clock-time"></i> Monday - Saturday, 8AM to
            10PM */}
            <p className="top-bar-mask-content" style={{ margin: 0 }}>
              Now we are NABH Accredited
            </p>
          </div>
          <div className="d-flex align-items-center">
            {/* <i className="icofont-phone"></i> Call us now 080-23401200 */}
            <div className="social-links">
              {/* <a href="#" className="twitter">
                <i className="bx bxl-twitter"></i>
              </a> */}
              <a
                href="https://www.facebook.com/CutisHospitals/"
                className="facebook"
              >
                <i className="bx bxl-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/cutishospitalbengaluru/"
                className="instagram"
              >
                <i className="bx bxl-instagram"></i>
              </a>
              {/* <a href="#" className="google-plus">
                <i className="bx bxl-skype"></i>
              </a> */}
              <a
                href="https://www.linkedin.com/company/cutis-hospital-skin-hair-nail"
                className="linkedin"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
              <a
                href="https://www.youtube.com/c/CutisHospital"
                className="youtube"
              >
                <i className="bx bxl-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <a target="_blank" href="https://appointment.cutis.org.in/" className="appointment-btn-sticky">Book Appointment</a> */}

      {/* <a target="_blank" href="https://www.cutiskart.com/" className="buyMedicine-btn-sticky">Buy Medicine Online</a> */}

      <div className="buyMedicine-btn-sticky ">
        <i className="icofont-cart-alt"></i>
        <span style={{ marginLeft: "5px" }}>
          {" "}
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://www.cutiskart.com/"
          >
            Buy Medicine Online
          </a>
        </span>
      </div>

      <div
        className="buyMedicine-btn-sticky phn mt-5 bg-success text-white text-nowrap"
        id="hideMeAtSmall"
      >
        <i className="icofont-phone"></i>
        <span style={{ marginLeft: "5px" }}>
          {" "}
          <a style={{ color: "white" }}>
            Call us now &nbsp;<br></br>
            080-2340-1200
          </a>
        </span>
      </div>
      <div
        className="buyMedicine-btn-sticky phn mt-5 bg-success text-white text-nowrap"
        id="showMeAtSmall"
        style={{ display: "none" }}
      >
        <i className="icofont-phone"></i>
        <span style={{ marginLeft: "5px" }}>
          <a style={{ color: "white" }} href="tel:08023401200">
            Call us now &nbsp;<br></br>
            080-2340-120011
          </a>
        </span>
      </div>
      <div
        className="buyMedicine-btn-sticky phn  text-white text-nowrap cristello cristello2"
        id="hideMeAtSmall"
      >
        <img
          src="https://d2pyicwmjx3wii.cloudfront.net/s/62a2e504121cc82fd67c88e0/62a8479446d6df0aa69ab5a6/apple-touch-icon.png"
          alt="Cristello"
        />
        <span style={{ marginLeft: "5px" }}>
          {" "}
          <a
            style={{ color: "#000", fontWeight: "bold" }}
            href="https://www.cristello.in/"
          >
            Cutis x Cristello <br />
          </a>
        </span>
      </div>
      <div
        className="buyMedicine-btn-sticky phn  text-white text-nowrap cristello cristello2"
        id="showMeAtSmall"
        style={{ display: "none" }}
      >
        <img src="https://d2pyicwmjx3wii.cloudfront.net/s/62a2e504121cc82fd67c88e0/62a8479446d6df0aa69ab5a6/apple-touch-icon.png" />
        <span style={{ marginLeft: "5px" }}>
          <a
            style={{ color: "#000", fontWeight: "bold" }}
            href="https://www.cristello.in/"
          >
            Cutis x Cristello <br />
          </a>
        </span>
      </div>

      {/* 
			<a
				target="_blank"
				href="https://www.cutiskart.com/"
				className="buyMedicine-btn-sticky phn mt-5 bg-success text-white text-nowrap"
			>
				<i className="icofont-phone"></i>
				<span className="">Call us now &nbsp;</span>
				<br></br>
				080-2340-1200
			</a> */}

      {/* ======= Header ======= */}
      <header id="header" className="fixed-top">
        <div
          className="container-fluid container-md d-flex align-items-center"
          id="outer-container"
          style={{ paddingLeft: "5px" }}
        >
          <a href="/" className="logo mr-5 mr-md-auto">
            <img
              src="/assets/img/cutis-logo-with-tm-600-new.png"
              alt="Cutis Logo"
            />
          </a>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <h1 className="logo mr-auto"><a href="index.html">Medicio</a></h1> */}

          {/* <div className="d-flex align-items-center callUsNow">
                        <i className="icofont-phone"></i> 
                        <span className="d-none d-md-inline">Call us now &nbsp;</span>
                        080-2340-1200
                    </div> */}

          <div
            id="mobile-nav"
            className=" d-flex flex-column-reverse justify-content-end flex-lg-row d-lg-inline-flex  "
          >
            {/* <a
              target="_blank"
              href="https://appointment.cutis.org.in/"
              className="appointment-btn scrollto"
            >
              <span className=" d-md-inline">Book Appointment </span>
            </a> */}
            <div id="mobile-nav-button-container">
              {/* <a
                href="/procedural-dermatology"
                className="appointment-btn scrollto header-fellowhip-button"
              >
                <span className="d-md-inline header-fellowhip-button-span">
                  <i class="bx bxs-hand-right"></i>
                  <span>Procedural</span> <span>Dermatology</span>&nbsp;
                  <span>Fellowship</span>
                </span>
              </a> */}
              <a
                target="_blank"
                href="https://www.cristello.in/"
                className="appointment-btn scrollto header-cristello-button"
              >
                <span className=" d-md-inline"> Cutis x Cristello </span>
              </a>
              {/* <a href="/fellowship" className="appointment-btn scrollto ">
                <span className=" d-md-inline"> Fellowship Announcement </span>
              </a> */}
              {/* <a
                href="/fellowship"
                className="appointment-btn scrollto header-fellowhip-button header-fellowhip-application-button-span"
              >
                <span className="d-md-inline header-fellowhip-button-span ">
                  <i class="bx bxs-hand-right"></i>
                  <span>Fellowship</span> <span>Application</span>&nbsp;
                </span>
              </a> */}
              <a
                target="_blank"
                href="https://appointment.cutis.org.in/"
                className="appointment-btn scrollto"
              >
                <span className="d-md-inline">Book Appointment </span>
              </a>
            </div>

            <nav className="nav-menu d-lg-block">
              <span className="menuText d-none d-lg-block">Menu</span>
              <a
                href="/"
                onClick={menuprevent}
                className="smobitrigger icofont-navigation-menu float-right float-lg-none mt-lg-1"
              >
                <span>Menu DAW</span>
              </a>
              <ul className="mobimenu">
                <li className="active">
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li className="drop-down ">
                  <a href="#">Services</a>
                  <ul className="innerMenuServicesList mobile-inner-menu ">
                    {ServiceList &&
                      ServiceList.map((service) => (
                        <HeaderDynamicMenu key={service.id} service={service} />
                      ))}
                  </ul>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li className="drop-down">
                  {/* <span className="drop-down-mobile">&gt;</span> */}
                  <a href="#">Our Doctors</a>
                  <ul className="innerMenuourDoctorsList mobile-menu-main">
                    {ourDoctorsList &&
                      ourDoctorsList.map((service) => (
                        <HeaderDynamicMenu key={service.id} service={service} />
                      ))}
                  </ul>
                </li>
                <li className="drop-down">
                  <a href="#">Academics</a>
                  <ul className="innerMenuacademicsList mobile-menu-main">
                    {academicsList &&
                      academicsList.map((service) => (
                        <HeaderDynamicMenu key={service.id} service={service} />
                      ))}
                  </ul>
                </li>
                <li className="drop-down">
                  <a href="#">Gallery</a>
                  <ul className="innerMenugalleryList mobile-menu-main">
                    {galleryList &&
                      galleryList.map((service) => (
                        <HeaderDynamicMenuHeight
                          key={service.id}
                          service={service}
                        />
                      ))}
                  </ul>
                </li>
                <li className="drop-down ">
                  <a href="#">Resources</a>
                  <ul className="innerMenuresourcesList mobile-menu-main">
                    {resourcesList.map((sub) => (
                      <HeaderDynamicMenuHeight key={sub.id} service={sub} />
                    ))}
                  </ul>
                </li>
                <li>
                  <a href="/handout">Patient Educational Handout</a>
                </li>
                <li>
                  <a href="/careers">Careers</a>
                </li>
                <li>
                  <a href="https://www.cristello.in/"> Cutis x Cristello</a>
                </li>
                <li>
                  <a target="_blank" href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;
