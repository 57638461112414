import React from "react";
import Layout from "./layout";
import ConsultantsC from "./ConsultantsC";

import BgSubHeader from "./BgSubHeader";

const ConsultantC = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"CONSULTANT"} />
            <ConsultantsC />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default ConsultantC;
