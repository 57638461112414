import React from "react";
import SectionTitle from "./home/SectionTitle";

const iframeStyle = { border: 0, width: "100%", height: "350px" };

const NurseEducators = () => {
  return (
    <>
      <div className="container">
        <div className="container_nurse">
          <SectionTitle title={"Job Description Nurse Educators"} desc={""} />
          <ul>
            <li>
              To assist nursing staff in developing and maintaining their
              competencies, advancing their professional nursing practice and
              facilitating their achievement of academic and career goals.
            </li>
            <li>
              As Nurse educators they should understand the challenges faced by
              nurses and how to overcome them.
            </li>
            <li>
              They should identify opportunities to improve processes and
              mitigate risks to the patient, nurse, and hospital.
            </li>
            <li>
              They are the people tasked with fostering staff in their continual
              growth and development to improve care decisions by serving as
              role models for evidence-based decision making.
            </li>
            <li>
              Clinical nurse educators use multiple modalities to educate staff,
              such as simulations, online education, and traditional
              face-to-face education.
            </li>
            <li>
              Nurse educators serve as partners for practice transitions,
              learning facilitators, change agents, mentors, leaders, champions
              of scientific inquiry, and advocates for the specialty.
            </li>
            <li>
              To create and implement cost-effective, efficient, and timely
              education using innovative teaching methods and technology
            </li>
            <li>
              They should have a goal to help nurses gain competence and
              confidence in their practice and to foster lifelong learning
            </li>
            <li>
              Nurse educators should motivate and educate staff to adopt new
              practice changes incorporating evidence-based practices that
              promote positive patient outcomes.
            </li>
            <li>
              To serve as mentors, role models, and preceptors to influence
              staff nurses’ professional development
            </li>
            <li>
              The nurse educator possesses influence as an organizational
              leader—leading teams, managing programs and projects, ensuring
              compliance with regulatory requirements, and evaluating outcomes
            </li>
          </ul>
          <p>
            <strong> Nurse Educators in an Academic Setting </strong>
          </p>
          <ul>
            <li>
              These nurse educators work within academic systems and hospital
              training settings to assist in the development of new nurses.
            </li>
            <li>
              They may teach nursing fellowship program students within a
              hospital setting
            </li>
            <li>
              Include developing curriculum, teaching, advising, mentoring, and
              conducting research.
            </li>
            <li>
              Should take the ownership of the entire fellowship programme
              curriculum and academic activities
            </li>
          </ul>
          <p>
            Send resume to <a href="mailto:hr@cutis.org.in">hr@cutis.org.in</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default NurseEducators;
