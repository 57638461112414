import React from "react";
import { Link } from "react-router-dom";
import Layout from "./layout";

const Sitemap = () => {
  return (
    <>
      <Layout>
        <div className="container" id="sitemap-buttons">
          <nav
            style={{ "--bs-breadcrumb-divider": ">" }}
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb mt-2">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sitemap
              </li>
            </ol>
          </nav>
          <div className="row my-sm-5 my-3 ml-sm-5">
            <div className="col">
              <div className="col-button">
                <Link to="/" className="btn btn-danger d-inline-block my-2">
                  Home
                </Link>
              </div>
              <div className="col-button">
                <Link
                  to="/about"
                  className="btn btn-danger d-inline-block my-2"
                >
                  About us
                </Link>
              </div>
              <div className="col-button">
                <Link
                  to="/service/service-at-cutis/"
                  className="btn btn-danger d-inline-block my-2"
                >
                  Sevices
                </Link>
                <div className="col-button ml-sm-4 ml-2">
                  <Link
                    to="#"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Skin
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/atopic-dermatitis"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Atopic Dermatitis
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/fungal-infection"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Fungal Infection
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/melasma"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Melasma
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/papular-urticaria"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Papular Urticaria
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/pigmentation"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Skin pigmentation
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/pimples"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Pimples
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/psoriasis"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Psoriasis
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/skin-allergy"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Skin Allergy
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin/vitiligo"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Vitiligo
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="#"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Hair
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/alopecia-areta"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Alopecia Areta
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/hair-loss/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Hair Loss
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/hairfall-control-and-hair-regrowth"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    hairfall Control and Hair Regrowth
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/hair-transplantation"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Hair Transplantation
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/low-level-laser-therapy"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Low Level Laser Therapy
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/male-hair-loss"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Male Hair Loss
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/platelet-rich-plasma"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Platelet Rich Plasma
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/rigenera"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Rigenera
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/scalp-micropigmentation"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Scalp Micropigmentation
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link to="#" className="btn btn-danger d-inline-block my-2">
                    Nail
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/ingrow-toe-nail"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Ingrow Toe Nail
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/nail-discoloration"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Nail Discoloration
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/nail-dystrophy/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Nail Dystrophy
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/nail-infection"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Nail Infection
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/nail-tumors"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Nail Tumors
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/nail-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Nail Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/traumatic-nail"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Traumatic Nail
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link to="#" className="btn btn-danger d-inline-block my-2">
                    Skin Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/acne-scar-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Acne Scar Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/active-acne-procedure"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Active Acne Procedure
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/blepharoplasty"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Blepharoplasty
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/corn-removal"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Corn Removal
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/dimple-creation"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Dimple Creation
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/autologous-fat-transfer"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Autologous Fat Transfer
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/hair/hair-transplantation/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Hair-Transplantation
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/lip-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Lip Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/skin-surgery/micro-pigmentation/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Micro Pigmentation
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/skin-suregry/moles-cysts-lipoma-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Moles Cysts Lipoma Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/nail/nail-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Nail Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/sclerotherapy"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Sclerotherapy
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/thread-lift"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Thread Lift
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery/vitiligo-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Vitiligo Surgery
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/skin-surgery"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    And More
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="/service/hair/hair-transplantation"
                    className="btn btn-danger d-inline-block my-2"
                  >
                    Hair Transplantation
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link to="#" className="btn btn-danger d-inline-block my-2">
                    Aesthetics
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/acne-scar/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Acne Scar
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/body-contouring"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Body Contouring
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/botox-fillers"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Botox Fillers
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/chemical-peels"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Chemical Peels
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/facial-rejuvination"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Facial Rejuvination
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/gold-toning"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Gold Toning
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/laser-hair-reduction"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Laser Hair Reduction
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/laser-toning"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Laser Toning
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/lip-tint-lip-toning"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Lip Tint Lip Toning
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/oxy-hydrafacial"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Oxy Hydrafacial
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/post-traumatic-scar"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Post Traumatic Scar
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/ruvy-touch"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Ruvy Touch
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/shining-peel"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Shining Peel
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/skin-radiance"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Skin Radiance
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/4d-facelift"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    4d Facelift
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/aesthetics/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    And More
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link to="#" className="btn btn-danger d-inline-block my-2">
                    Anti Ageing
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/body-shaping"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Body Shaping
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/botox-fillers"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Botox Fillers
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/anti-ageing/fat-fillers"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Fat Fillers
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/anti-ageing/hifu"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    HIFU
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/laser-hair-reduction"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Laser Hair Reduction
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/laser-toning"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Laser Toning
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/thread-lift"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Thread Lift
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/anti-ageing/vampire-lift"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Vampire Lift
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/4d-facelift"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    4d Facelift
                  </Link>
                </div>
                <div className="col-button ml-sm-5 ml-2">
                  <Link
                    to="/service/anti-ageing"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    And More
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="/service/lasers-and-lights"
                    className="btn btn-danger d-inline-block my-2"
                  >
                    Lasers-and-lights
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="/service/pediatric-dermatology"
                    className="btn btn-danger d-inline-block my-2"
                  >
                    Pediatric Dermatology
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="/service/geriatric-dermatology"
                    className="btn btn-danger d-inline-block my-2"
                  >
                    Geriatric Dermatology
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="/service/nutrition"
                    className="btn btn-danger d-inline-block my-2"
                  >
                    Nutrition
                  </Link>
                </div>
                <div className="col-button ml-sm-4 ml-1">
                  <Link
                    to="/service/biological-therapy"
                    className="btn btn-danger d-inline-block my-2"
                  >
                    Biological Therapy
                  </Link>
                </div>
              </div>
              <div className="col-button ml-sm-3 ml-0">
                <Link
                  to="https://cutis.org.in/blog"
                  className="btn btn-danger d-inline-block my-2"
                >
                  Blog
                </Link>
              </div>
              <div className="col-button ml-sm-3 ml-0">
                <Link to="#" className="btn btn-danger d-inline-block my-2">
                  Doctors
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/chief-dermatologist/"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Chief Dermatologist
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/lead-dermatosurgeon"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Lead Dermatosurgeon
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/lead-aesthetic-dermatologist"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Lead Aesthetic Dermatologist
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/consultants"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Consultants
                </Link>
              </div>

              <div className="col-button ml-sm-3 ml-0">
                <Link to="#" className="btn btn-danger d-inline-block my-2">
                  Acadmics
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/educational-programme"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Educational Programme
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/fellowship"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Fellowship
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/observership"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Observership
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/publications"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Publications
                </Link>
              </div>

              <div className="col-button ml-sm-3 ml-0">
                <Link to="#" className="btn btn-danger d-inline-block my-2">
                  Gallery
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/our-doctors-at-scientific-forums"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Our Doctors at Scientific Forums
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/infrastructure-at-cacs"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Infrastructure at Cacs
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/departments-staffs"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Departments Staffs
                </Link>
              </div>

              <div className="col-button ml-sm-3 ml-0">
                <Link to="#" className="btn btn-danger d-inline-block my-2">
                  Resources
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/faqs"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Faqs
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/patient-stories"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Patient Stories
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/patient-educational-video"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Patient Educational Video
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/cutis-events"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Cutis Events
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/patient-testimonials"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Patient Testimonials
                </Link>
              </div>
              <div className="col-button ml-sm-4 ml-1">
                <Link
                  to="/celebrity-testimonials"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Celebrity Testimonials
                </Link>
              </div>
              <div className="col-button ml-sm-3 ml-0">
                <Link
                  to="/handout"
                  className="btn btn-danger d-inline-block my-2"
                >
                  Handout
                </Link>
              </div>
              <div className="col-button ml-sm-3 ml-0">
                <Link
                  to="/careers"
                  className="btn btn-danger d-inline-block my-2"
                >
                  Careers
                </Link>
              </div>
              <div className="col-button ml-sm-3 ml-0">
                <Link
                  to="/contact"
                  className="btn btn-danger d-inline-block my-2"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Sitemap;
