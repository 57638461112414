import React from "react";
import SectionTitle from "./home/SectionTitle";

const iframeStyle = { border: 0, width: "100%", height: "350px" };

const VisitingConsultant = () => {
  return (
    <>
      <div className="container">
        <div className="container_nurse">
          <SectionTitle
            title={"Job Description Visiting Consultant"}
            desc={""}
          />
          <center>
            <p>
              <strong>
                {" "}
                NOTE: A visiting consultant should not have a private practice
                other than the association with medical colleges.
              </strong>
            </p>
          </center>
          <p>Timings: 5 - 8PM</p>
          <p>
            Send resume to <a href="mailto:hr@cutis.org.in">hr@cutis.org.in</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default VisitingConsultant;
