export const FaqSchema = [
  {
    id: "1",
    title: "Acne Scar Treatment",
    schemaCode: `
   <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What are sequelae of untreated acne pimple?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Acne scarring is an unfortunate complication of acne, an aesthetically unpleasant and embarrassing skin condition striking most adolescents and adults. Acne/pimple appears primarily on the face, back, and chest. It is the disease of pilosebaceous unit (Hair follicle with sebaceous / oil gland). In patients with acne, sebaceous secretions are increased with qualitatively different sebum leading to oily skin and blocking of duct. The pent up secretions are broken down by bacteria in the follicle leading to inflammation that causes pain, redness, bump and pus discharge. It heals with brown spots and scarring depending on the degree of inflammation. Severely inflamed acne results in unsightly facial scars and emotional stress leading to psychic trauma, general insecurity and feeling of inferiority. This psychosocial distress posed by acne makes a powerful argument for its timely and adequate treatment to prevent cosmetically disfiguring scars."
      }
    },{
      "@type": "Question",
      "name": "Why acne scars are common?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Acne management, though greatly improved with modernization of time, relies heavily on the    patients’ desire to seek treatment. About half of patients use self-medications rather than a  Dermatologist prescribed medications. Thus a vast majority of acne patients develop scars and is maximally related to severity and duration of acne before adequate therapy is instituted."
      }
    },{
      "@type": "Question",
      "name": "What are the types of acne scars?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Acne scars resulting due to tissue destruction leads to depressed / atrophic scars / pits. Scars due to tissue excess leads to raised/hypertrophic scars. Accordingly acne scars can be classified as flat/ depressed/ raised or shallow/ deep scars."
      }
    },{
      "@type": "Question",
      "name": "What are the treatment options for acne scars?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The treatment of acne scarring includes resurfacing techniques (chemical peels, microdermabrasion and lasers), dermal fillers, skin rolling techniques (microneedling/dermaroller) and surgical methods such as dermabrasion, subcision, punch excision techniques, Scar revisions and dermal grafting techniques. The choice of therapy depends on color, texture, depth and morphology of these scars."
      }
    },{
      "@type": "Question",
      "name": "Treatment of post pimple marks?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Red, white, or brown marks are best treated with topical therapies, fractionated and pigment or vascular-specific lasers and, occasionally, pigment transfer techniques."
      }
    },{
      "@type": "Question",
      "name": "Treatment of mild scarring?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Mild scarring as seen primarily in the mirror is treated by fractional laser and non-fractionated lasers as well as dermaroller therapy and chemical peels."
      }
    },{
      "@type": "Question",
      "name": "Treatment of moderate and severe scars?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Scars, visible at conversational distance but distensible, are best managed by traditional resurfacing techniques or with fractional non- ablative or ablative devices, sometimes including preparatory surgical procedures. Severe non-distensible scarring requires a combined approach."
      }
    },{
      "@type": "Question",
      "name": "What to expect after treatment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Post acne scarring remains a challenge and multiple modalities are often necessary to attain excellent results. It should be noted that none of the currently available treatments can achieve complete resolution of the scar but only make them look better or best."
      }
    },{
      "@type": "Question",
      "name": "Are there any side effects?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "These treatments can also cause transient pain, redness and pigmentation."
      }
    },{
      "@type": "Question",
      "name": "Prevention of scars?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The best method of preventing or limiting scarring is to treat acne early enough to minimize the extent and duration of inflammation and avoidance of picking the acne. Early and aggressive treatment is, therefore, vital to minimize if not prevent its occurrence."
      }
    },{
      "@type": "Question",
      "name": "Who is the best person to treat?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your dermatologist is the best person to offer you all the treatment modalities."
      }
    },{
      "@type": "Question",
      "name": "Treatment of moderate and severe scars?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Scars, visible at conversational distance but distensible, are best managed by traditional resurfacing techniques or with fractional non- ablative or ablative devices, sometimes including preparatory surgical procedures. Severe non-distensible scarring requires a combined approach."
      }
    }]
  }
  </script>
  `,
  },
  {
    id: "2",
    title: "Ageing And Antiageing",
    schemaCode: `
      <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is the Current scenario of ageing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Over the last century there has been a dramatic increase in the ageing population all over world. Longevity is one of the striking achievements of India. It has second largest aged population in the world. Hence there is increased social pressure to look younger and increased awareness to preserve youthfulness which is the prerequisite for beauty. The paradox of aging is, it is an on- going pleasant phenomenon but some changes like wrinkles, brown pigment and sagging are not acceptable. When we look back into history there is always been a fascination to overcome aging and conserve eternal youth. However even in this era efforts are made to look younger and increased awareness for preventive and treatment measures in both young and old, has led to great demand for effective cosmetic treatments for skin and facial rejuvenation."
      }
    },{
      "@type": "Question",
      "name": "What age onwards does it start (As early as 24-25 years of age)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The human skin is incredibly durable, like all other organ systems it is also affected by aging. It is the most exposed part of our body hence signs of aging is first seen on skin. As face is the focal point of human beauty the signs of aging are first Visible on face and prompts some interventions. The aging is determined by thickness of skin. The adult skin thins progressively at a rate that accelerates with age. This phenomenon occurs in all layers of the skin. It is most pronounced in exposed areas, such as the face, neck, upper part of the chest and the extensor / Outer surface of the hands and forearms. The overall thickness decreases at about 6% per decade decreasing faster in women than in men. So aging can begin after 20 years of age and is accelerated by external factors like sun exposure, smoking, unhealthy diet."
      }
    },{
      "@type": "Question",
      "name": "What are the first visible signs of ageing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Skin mirrors the first signs of aging. As we age, the skin thins, dries, wrinkles, and becomes unevenly pigmented. A loss of underlying fat, bone and cartilage, manifests as sagging skin and fallen nasal tips. The first signs of aging are fine lines around eyes, mouth and forehead, greater Visibility of bony landmarks, hollowing of the cheeks and perioral area and deepening nasolabial folds. This is followed by flat eyebrows, upper eyelid laxity, atrophy of lips, decent of the corners of the mouth and lower face and neck sagging. Surface changes like roughness, enlarged pores and pigmentary blemishes are also part of aging process."
      }
    },{
      "@type": "Question",
      "name": "Self management",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Adequate use of proper sunscreen, moisturizers and avoiding extrinsic factors like sun exposure and environmental pollutants can delay aging. A good nutrition with antioxidants can retard aging. Some factors like skin type and extent of outdoor activity of the individual should be considered while choosing proper creams and lotions. The results will be promising with the aid of dermatologist."
      }
    },{
      "@type": "Question",
      "name": "How can expert dermatologist advice help?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The aging involves both intrinsic and extrinsic processes occurring in parallel. Intrinsic aging proceeds at different rates in each of us at genetically determined pace. Aging is accelerated by extrinsic factors like ultraviolet radiations from sun and smoking. The Dermatologist is the right person to give anti ageing solution based on extent of aging. There are various treatment options ranging from Antiageing creams, Superficial and Medium peels, Non-ablative facial rejuvenation, Laser and fractional resurfacing, Botulinum toxin type A injections, Fillers to new generations of cosmeceuticals. Allow your Dermatologist to analyse aging face and offer appropriate treatment. With aging, skin functions deteriorate and can results in palette of diseases like dryness, infectious diseases and benign, premalignant and malignant tumours that can jeopardise life which needs consultation by expert."
      }
    },{
      "@type": "Question",
      "name": "How an expert care is superior than self management of ageing?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Successful rejuvenation of face requires proper scaling of age related counter changes (bony prominences and soft tissue changes) and textural changes (superficial and deep wrinkles, pigmentary changes and loss of skin elasticity). Based on above changes only dermatologist can offer individualised treatment plan to give youthful and natural result. they have thorough knowledge of aesthetics and an exact idea of what is beautiful and what is not.Dermatologist monitor the skin on a regular basis and alter treatment depending on the need of the patients skin. This outscores self management which should be deferred."
      }
    },{
      "@type": "Question",
      "name": "What should ageing patient do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The aging process can have array of changes requiring combination of therapies. Superficial rejuvenation is by chemical peels and non ablative lasers, Deeper rejuvenation is by fractional lasers and non ablative radiofrequency, Soft tissue loss is treated safely and effectively by Fillers, Botulinum toxin, that not only corrects dynamic wrinkles but also counters and sculpts the face. The right thing to do is to go and consult a dermatologist/ cosmetic surgeon and assess aging process and receive treatment in safe hands to have flawless skin without aging blemishes"
      }
    }]
  }
  </script>
  
      `,
  },
  {
    id: "3",
    title: "Botulinum Toxin",
    schemaCode: `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What Is Botulinum Toxin Treatment?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is the most common aesthetic procedure to improve wrinkles. Its use in medical practice is for more than 50 years, not only for anti-ageing treatment, but also to treat certain medical conditions."
          }
        },{
          "@type": "Question",
          "name": "What is botulinum toxin?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is a protein produced by culture of a micro-organism called Clostridium botulinum. However, for medical purpose, it is derived from bacteria, Streptococcus sui."
          }
        },{
          "@type": "Question",
          "name": "What does Botulinum toxin do?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Botulinum toxin releases the muscle into which the injection is given, thus reducing its ability to form wrinkles and line, giving you a smooth, youngerlooking skin."
          }
        },{
          "@type": "Question",
          "name": "What is Botulinum toxin used for?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "To treat lines and wrinkles on face caused by over action of facial muscles.
      Facial contouring.
      Other aesthetic and medical conditions"
          }
        },{
          "@type": "Question",
          "name": "What are the lines and wrinkles treated by botulinum toxin?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Forehead lines – Arise during expression of surprise.
      Frown lines – Arise between eyebrows
      Bunny line – Arise on nose
      Crow’s feet – Arise on outer aspect of eyes
      Neck lines – Horizontal lines on neck"
          }
        },{
          "@type": "Question",
          "name": "What are the aesthetic indications of botulinum toxin?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Botulinum toxin can address other aesthetic issues like shaping of eyebrow/ eyebrow lift, lifting the tip of depressed nose, lifting the down turned angles of mouth which gives us sad look even while smiling, jaw line definition, smoothening the vertical bands in neck and gummy smile (exposure of gums during smiling)."
          }
        },{
          "@type": "Question",
          "name": "Is botulinum toxin used for facial recontouring?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, it is used for facial contouring. The bulky muscle at angle of jaw gives squared shaped face, which can be treated to contour the face. in to oval or round shape"
          }
        },{
          "@type": "Question",
          "name": "What are the medical indications of botulinum toxin?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Sweating of Palms, Soles and Armpits, Blepharospasm and Migraine are some medical conditions treated with botulinum toxin."
          }
        },{
          "@type": "Question",
          "name": "Who can undergo Botulinum toxin injection?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "There are no age constraints; any individual desiring a smooth wrinkle free skin is a candidate for injection. However, it is not done in pregnancy, lactating women and people with neuromuscular degenerative disease like myasthenia gravis and people on medicines like amiodarone."
          }
        },{
          "@type": "Question",
          "name": "How long do the effects last?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can expect results as early as 48 hours and you have to wait for 2 weeks for the maximum results.
      The results last usually for 4-8 months. Repeated sessions every 4 to 6 months are advised to maintain the smooth, younger looking skin. The people who deeply concentrate and work need a botulinum toxin touch up earlier. For facial contouring, the toxin requirement decreases over a period of time."
          }
        },{
          "@type": "Question",
          "name": "If botulinum toxin is stopped, will lines worsen?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No. The botulinum toxin cannot adversely affect the ageing process or muscle action."
          }
        },{
          "@type": "Question",
          "name": "Is botulinum toxin safe for aesthetic indications?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is safe treatment with minimal or no side effects. The side effects are due to wrong dosage, wrong technique and wrong patient selection rather than toxin itself. The side effects are temporary lasting for few weeks. There are reported no permanent side effects."
          }
        },{
          "@type": "Question",
          "name": "How is the procedure done?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Botulinum toxin is delivered by fine injections on the skin overlying the muscle of target. There can be mild discomfort as expected with any injection. This can be ameliorated by using topical anesthesia cream 45 minutes prior to the procedure or by using ice cube massage prior to injection shots. The injection marks will clear off in 30 to 60 minutes. If bruises occur at the site of injection, it resolves in less than 7 – 10 days."
          }
        },{
          "@type": "Question",
          "name": "What are the pre and post procedure instructions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Avoid blood thinning agents (aspirin, Vitamin E, Ginkgo Biloba, pain killers) 5days prior to the procedure to decrease the risk of bruising at injection sites.
      1. Avoid alcohol 24 hours prior to treatment.
      2. Inform your dermatologist if history of cold sores which may require you to start on antiviral drugs to prevent outbreak of infection.
      3. Do not touch or massage the injected area for 48 hours.
      4. To be upright position for 4 hours after the procedure.
      5. Do not indulge in exercise or heavy work for 1 -2 days.
      6. Do not undergo facial massage, laser or any other procedure for 2 weeks.
      7. Follow up with your doctor after 1 week and get a touch up session done if required"
          }
        }]
      }
      </script>
      `,
  },
  {
    id: "4",
    title: "Chemical Peel",
    schemaCode: `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What is chemical peel?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "A Chemical peel is a technique of applying chemicals (fruit derived and purified) used to improve the appearance of the skin on the face, neck, back or hands."
          }
        },{
          "@type": "Question",
          "name": "What is the benefit of chemical peel?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It causes the upper skin layers to exfoliate and eventually peel off. The new, regenerated skin is usually smooth, less wrinkled and more even in color. It improves the texture of skin, decreases the pigmentation and fine lines, and improves the appearance of scars. It is not only for beautification of skin but also to treat some skin conditions like, Melasma, Pimples and Pigmentatio"
          }
        },{
          "@type": "Question",
          "name": "Where chemical peels are used?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Acne, superficial/mild acne scars and acne induced pigmentation.
      Pigmentary conditions – Melasma, Post inflammatory hyperpigmentation, pigmentation on hands and back, Freckles, Lentigines and dark circles.
      Cosmetic – Fine wrinkling, Photo ageing, sun damage, pores and to improve texture and color of dull skin."
          }
        },{
          "@type": "Question",
          "name": "Are there different types of peels?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, each above mentioned conditions requires specific peels that depend on the severity of the condition, skin type of the patient. Your dermatologist is the best person to choose what is the peel you need."
          }
        },{
          "@type": "Question",
          "name": "How peels are performed?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is a non-invasive technique performed in a dermatology clinic. The type of peel, the amount and number of coats will be decided by your dermatologist. A chemical (fruit derived substance) is applied to the cleansed face in step by step manner and monitored for the end results by trained personnel. Depending on the type of peel, either they can be neutralized or left for 6 hours and washed. You may expect mild stinging sensation that may last for few minutes."
          }
        },{
          "@type": "Question",
          "name": "How many sessions are required?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It requires multiple sessions for better cosmetic outcome. To get full benefit approximately, 4-6 peels are required. The number of sessions also depends on the indication, severity of the condition and it may require maintenance sessions too. Normally the peels are done every 2-4 weeks. recommended?
      Severe wrinkles, deep pimple scars, Sags and bulges on the face do not respond to peels, their requires different cosmetic procedures. Peels are not recommended on irritated skin."
          }
        },{
          "@type": "Question",
          "name": "Are there any side effects?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Reactivation of cold sores, Persistent redness and risk of scarring and pigmentations are some of the rare complications. The risk of pigmentation and scarring is more in dark skin individuals."
          }
        },{
          "@type": "Question",
          "name": "What are the risk factors for chemical peels?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Sun exposure prior and after the procedure Irritated skin because of waxing, threading, facial and recent sun damaged skin."
          }
        },{
          "@type": "Question",
          "name": "Precautions you need to take after peels.",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The most crucial factor for the outcome of peel is to protect underlying smooth, fresh, sensitive skin by direct sun exposure. So use broad spectrum sunscreen as advised by your dermatologist and strictly avoid direct sun exposu"
          }
        },{
          "@type": "Question",
          "name": "DO's",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Always get your peel decided by your dermatologist who will assess your skin type condition and select suitable peel for your skin.
      Follow the regimen recommended by your dermatologist.
      For best results, book a series of treatments
      Use moisturizer to keep your skin smooth and supple. If your skin dries and cracks, it can scar and pigment.
      If your skin burns, itches or swells, contact your dermatologist at the earliest."
          }
        },{
          "@type": "Question",
          "name": "DON'T'S",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Don’t try to do a chemical peel treatment yourself.
      Don’t overexpose yourself to sun, harsh environments or pollutants – most crucial.
      Don’t wax or use any form of hair removal 1 week before and 1 week after treatment.
      Don’t pick at your skin that may be peeling.
      Don’t use exfoliating products until the recommended time, based on the depth of your peel.
      Don’t pick, pull or prematurely remove peeling skin. Removing skin prematurely causes complications including hyper and hypo pigmentation and will increase the risk of infection."
          }
        }]
      }
      </script>
      `,
  },
  {
    id: "5",
    title: "Dermal Fillers",
    schemaCode: `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What are fillers?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is a technique in which filler substance is injected into the skin to restore the volume and fullness of the skin that is usually lost due to ageing. The dermal fillers are made up of Hyaluronic acid or other ingredients which are natural substances found in the skin."
          }
        },{
          "@type": "Question",
          "name": "What are the types of fillers?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Fillers can be of 3 types. Temporary fillers (the effect lasts for 6-12 months). Semi permanent fillers (effects lasts for 1-2 years). Permanent fillers (effects lasts for 3-5 years)."
          }
        },{
          "@type": "Question",
          "name": "What is Hyaluronic acid?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is a substance naturally present in human body. It is responsible for the water holding capacity of the skin."
          }
        },{
          "@type": "Question",
          "name": "What does fillers do?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Fillers increase the volume in desired areas. It hydrates the skin and improves its elasticity and tone. Thus giving a younger looking skin."
          }
        },{
          "@type": "Question",
          "name": "What are fillers used for?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Normally fillers are injected to fill depressions or static wrinkles especially, folds between the nose and lip called Nasobial folds.
      It is used to enhance the shape and volume of lip, reshape nose and cheek augmentation (Filling).
      Improving the Facial contour, Breast and Buttock augmentation, and for depressed scar and hand rejuvenation."
          }
        },{
          "@type": "Question",
          "name": "How is the procedure performed?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Pre procedure photographs and consents are mandatory. After proper marking, the product is injected into the skin with a fine needle. To ensure minimal pain, topical or injection anesthesia can be used."
          }
        },{
          "@type": "Question",
          "name": "When can you see the results and how long it lasts?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The results are instant; however the initial redness and injection marks will decrease in less than 6 hours. Depending on the type of fillers, area treated and ones skin type, the effect may last from 6 – 18 months."
          }
        },{
          "@type": "Question",
          "name": "What are the adverse effects may be seen after the procedure?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Redness, swelling, itching may be seen at injection site. It is usually transient as mentioned earlier. And if any bruises occur (as expected due to rich blood supply of the face) it subsides in less than 2 weeks."
          }
        },{
          "@type": "Question",
          "name": "What are the pre procedure instructions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Avoid in take of blood thinning agents (Aspirin, Vitamin E, Ginkgo Biloba, Pain killers) 5 days prior to the procedure to decrease the risk of bruising at injection sites.
      Avoid drinking alcohol 24 hours prior to treatment.Inform your Dermatologist if history of cold sores which may require you to start on antiviral drugs to prevent outbreak of infection."
          }
        },{
          "@type": "Question",
          "name": "What is the care to be taken after undergoing fillers?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Do not touch the area for six hours post procedure.
      Do not massage for 48 hours.
      Do not undergo facial massage, laser or any other procedure for 2 weeks.
      Avoid exercise and exposure to extreme heat or cold.
      Undergo touch up session after 2 weeks if necessary."
          }
        },{
          "@type": "Question",
          "name": "Can filler be removed?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "If you are unhappy, it can be dissolved with antidote injection."
          }
        },{
          "@type": "Question",
          "name": "How safe is the procedure?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The side effects are very minimal as discussed. The products are natural substances (hypo allergic) found in the skin that are used since decades very safely."
          }
        }]
      }
      </script>
      `,
  },
  {
    id: "6",
    title: "Hair Transplantation",
    schemaCode: `
      <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is hair transplantation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Hair transplantation is a procedure in which hair is removed from back of scalp the permanent hair-bearing region (the “donor” area) and transferred to bald or balding areas of the scalp, (the “recipient” area). The transplanted hair has the same color and other characteristics as the hair of the donor area."
      }
    },{
      "@type": "Question",
      "name": "Where hair transplantation is indicated?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Hair transplantation is indicated in Male pattern baldness, Female Pattern Hair Loss (FPHL) and also in hair loss secondary to scarring after surgery, trauma or burns. It is also indicated for Eyebrow, Eyelash, Moustache and Beard reconstruction."
      }
    },{
      "@type": "Question",
      "name": "What are the techniques of hair transplantation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Hair transplantation can be performed by two techniques. The first method is strip method in which an 1 to 1.2 cm wide strip of Scalp skin thatis removed from the donor area, stiched to leave a narrow scar. Follicular units/hair grafts are separated using magnifying loupes and transplanted on balding scalp. In Follicular Unit Extraction (FUE), small, round punch is used to extract each individual Follicular units directly from the donor area. It has the advantage of avoiding the production of a linear scar in the donor area, but implantation remains the same."
      }
    },{
      "@type": "Question",
      "name": "What is the sequelae following hair transplantation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Usually all of the transplanted hairs will initially fall out within a period of two to three weeks. Approximately, two to three months later, they regrow in the recipient area and thus require 6-9 months to see the satisfactory results."
      }
    },{
      "@type": "Question",
      "name": "Is the procedure painful?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You may feel the initial injection prick of anesthesia after that there is no pain and you will be conscious throughout procedure, without pain."
      }
    },{
      "@type": "Question",
      "name": "How many sessions will be required?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The number of sessions depends on area to be covered."
      }
    },{
      "@type": "Question",
      "name": "What is the duration of surgery and down time of procedure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The whole procedure is day care and total stay in hospital would be for 8 to 10 hours. You can resume normal activity almost immediately, but it is advised to relax for 3 days."
      }
    },{
      "@type": "Question",
      "name": "What are the expected Side effects?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pain after surgery is well taken care with medicines. The chances of infection is also zero as the procedure is done in well equipped sterile Operation theater under aseptic precaution. As Local anesthesia is injected some of you can develop swelling of forehead and swelling around the eyes after 3 days, so avoid bending and sleep in lateral position. You can do ice massage on forehead and usually it subsides in a week. Some patients can develop rare side effect like boils but it is treatable. There are no permanent side effects at all."
      }
    },{
      "@type": "Question",
      "name": "When would be your follow-ups?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your First follow-up is on day 3 for head wash and at day 1 5 to start regular medicines, and thereafter every 6 weeks if you are enrolled for Platelet rich plasma sessions."
      }
    },{
      "@type": "Question",
      "name": "When can you go back for work?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can resume your work on 3rd day Onwards."
      }
    },{
      "@type": "Question",
      "name": "Treatment after transplantation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You have to continue your medical treatment even after transplantation to save your existing hair on balding area. Transplanted hair do not require any treatment."
      }
    },{
      "@type": "Question",
      "name": "Post surgery instructions?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Its very simple and you can read our Post op instruction sheet. I will be handed once to individuals is accepting surgery."
      }
    }]
  }
  </script>
  `,
  },
  {
    id: "7",
    title: "Laser Hair Removal",
    schemaCode: `
      <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How lasers remove hair?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Laser hair removal utilizes beams of highly concentrated light designed to selectively penetrate into the hair follicles/roots, to be absorbed by the pigment in the hair roots and to destroy the hair. A variety of lasers and light sources have been developed that may be employed in laser hair removal."
      }
    },{
      "@type": "Question",
      "name": "Are there different types of lasers?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Various types of lasers are utilized for laser hair removal. The most common of which are diode 810/980, Nd-YAG 1064 and intense pulsed light sources, each targeting different hair sizes."
      }
    },{
      "@type": "Question",
      "name": "Who can get Laser Hair Removal (LHR) done?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Laser hair removal can remove unwanted hair in individuals with disease states / normal states, in whom unwanted hair growth can occur. Anybody above the age of 12-14 years can opt for the procedure"
      }
    },{
      "@type": "Question",
      "name": "Do I require any investigations / tests?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, in patients of suspected hormonal abnormality may require to do some blood tests and ultrasound/ scan."
      }
    },{
      "@type": "Question",
      "name": "How the procedure performed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your hairs will be trimmed with sterile trimmer. Pre cooling is done for 10 min. The prior decided laser parameters by the doctor are set and procedure is done."
      }
    },{
      "@type": "Question",
      "name": "How many sessions are needed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The procedure is done every 3 to 6 weeks till hair become fine and sparse. At each successive laser hair removal session, less hair should be found in the treatment area. lt is truly impossible to determine in advance who will require how many treatments and how long the hair will remain, laser hair removal works best in those with dark hairs on lighter skin."
      }
    },{
      "@type": "Question",
      "name": "What to expect out of Laser Hair Removal (LHR)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "These lasers and light sources are FDA cleared for permanent hair reduction, which means reduction over time. You should expect your hair to become fine, sparse and slowly growing, which is cosmetically acceptable."
      }
    },{
      "@type": "Question",
      "name": "Do’s",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Use sunscreen as advised."
      }
    },{
      "@type": "Question",
      "name": "Don't's",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Do not wax or epilate prior to laser. Do not expose to sunlight prior and after procedure."
      }
    }]
  }
  </script>
  `,
  },
  {
    id: "8",
    title: "Mole",
    schemaCode: `
      <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is a mole?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Mole commonly termed as birth mark is referred to as ‘NEVUS or NEVI’ in medical language. Moles are benign growths on the skin that are either brown or black in colour."
      }
    },{
      "@type": "Question",
      "name": "Why does a mole occur?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Moles occur due to localised division of pigment producing cells called melanocytes. They appear either before birth or after birth."
      }
    },{
      "@type": "Question",
      "name": "Do moles require treatment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Usually no treatment is required since they are benign growths. Treatment is issued for those who seek for better cosmetic appearance, for moles causing mechanical problems and fear of malignant changes."
      }
    },{
      "@type": "Question",
      "name": "Word of caution!!!",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Watch for the changes in the mole like sudden increase in size, change in the color, irritation, pain and bleeding, in such a case immediately consult your dermatologist in view of malignant changes
  Do not do any home remedies to remove the mole which can leave behind unsightly scar and pigmentation."
      }
    },{
      "@type": "Question",
      "name": "Are there any different types of moles or birth marks?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Moles need not be small. They can be seen spread over a large area of skin. They appear either at birth or during puberty. They are Nevus of Ota / Ito, Bathing trunk nevus and Beckers’s nevus. These require combination of laser therapies."
      }
    },{
      "@type": "Question",
      "name": "What are the treatment options?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The treatment options are grouped into less invasive procedures like radio frequency technique for small and superficial moles but it may require multiple sessions for complete removal. If the mole is large and deep, then surgical technique is required Some large moles may be treated by laser."
      }
    },{
      "@type": "Question",
      "name": "How is the procedure done?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The procedure is done under injection or topical anaesthesia to make the procedure pain free. With the radio frequency technique, the mole is vaporised and antibiotic cream is applied. In surgical mole removal, there are different options like mole removal and suture placement and grafting techniques. Your dermatologist is the right person to decide the procedure. The duration of the procedure can range from 30 to 60 minutes."
      }
    },{
      "@type": "Question",
      "name": "What are the pre and post procedure instructions?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Inform your doctor if any keloid tendency in the past (abnormal wound healing leading to ugly scar).
  Keep the treated area clean and dry.
  You may be given ointment or cream for application to the treated area and also oral medication to alleviate pain and to prevent infection.
  Do not pick the lesions till they fall off by themselves in a week.
  You may require touch up sessions done usually after 2-3 weeks.
  Avoid sun exposure and sunscreen is mandatory.
  If stitches are placed, they have to be removed on 6th or 7th day.
  See your dermatologist if there is unusual pain, bleeding and any discharge from the wound.
  It is very important that you follow the advice of your dermatologist to ensure the success of the procedure and reduce the risk of complications."
      }
    }]
  }
  </script>
  `,
  },
  {
    id: "9",
    title: "Platelet Rich Plasma therapy (PRP)",
    schemaCode: `
      <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is Platelet Rich Plasma (PRP)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Platelet rich plasma is our own blood plasma enriched with platelets. It contains several different growth factors that stimulate soft tissue healing, hair growth, rejuvenation of skin and modifying acne scars."
      }
    },{
      "@type": "Question",
      "name": "Where is it used?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For hair growth, skin rejuvenation, ulcer treatment, acne scars, wrinkles and stretch marks."
      }
    },{
      "@type": "Question",
      "name": "How is it performed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It is very simple, 10-2oml of your own blood is drawn from your vein, under sterile conditions in prior labelled bulbs to get PRP in 40 minutes. This growth factor rich substance is delivered at site by fine injection shots or by Derma roller tool. To make procedure pain free it can be done under injection / topical anaesthesia."
      }
    },{
      "@type": "Question",
      "name": "How often to be done?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For hair loss, the procedure is repeated every 3-6 weeks and requires minimum 6 sessions. For facial rejuvenation, its done every 4th week upto 6 sessions. It may require maintenance session every 3 to 6 months. For ulcer treatment, procedure is repeated every 2 weeks, till the ulcer heals."
      }
    },{
      "@type": "Question",
      "name": "What is vampire lift?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Vampire lift is the procedure done on the face and neck that makes the face glow and toned up. The PRP is delivered by Derma roller tool or it is spread over laser resurfaced skin."
      }
    },{
      "@type": "Question",
      "name": "How safe is the procedure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your own blood is drawn and PRP is prepared under sterile conditions which is pyrogen free and is perfectly safe. PRP contains growth factors which aids in healing process."
      }
    },{
      "@type": "Question",
      "name": "What are pre and post procedure instructions?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If feasible keep hair short or zero trim prior to the procedure.
  Wash your scalp on the day of procedure and do not apply any lotions, gels or sprays.
  You can wash your scalp regularly the next day morning."
      }
    },{
      "@type": "Question",
      "name": "Are there any side effects and what is the down time?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can expect minimal redness and swelling which subsides in less than 24 hours. The whole procedure takes one to one and half hours and you can resume your normal activity immediately after the procedure."
      }
    }]
  }
  </script>
  
  `,
  },
  {
    id: "10",
    title: "Radio Surgery",
    schemaCode: `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What is Radio frequency/ Radio surgery?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Radiosurgery is the cutting/vapourization of tissues using a high frequency alternate current. This causes heat destruction of target tissue without damaging the surrounding normal skin to produce good aesthetic results."
          }
        },{
          "@type": "Question",
          "name": "What is radio surgery unit and how is the procedure done?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is a machine to generate high frequency radiowaves, at 4.0 MHZ, to deliver low temperatures through radiofrequency (RF) micro-fibre electrodes. It has got ground plate that is kept in contact with the patient during the treatment. The therapist/dermatologist will select the settings and suitable electrode for a particular condition for optimal results. To make the procedure less painful or painless topical / injection anesthesia can be used. You may feel small tugging sensations in the area during treatment and also burnt smell and smoke as the growth is removed. The wound normally heals by 5-7 days."
          }
        },{
          "@type": "Question",
          "name": "What is radio frequency used for?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is used for removal of skin tags, warts, benign growths (seborrheic keratoses, syringoma, and trichoepithelioma), ageing blemishes, pigmented spots (freckles), moles and Papular acne scars on nose and chin."
          }
        },{
          "@type": "Question",
          "name": "What are the benefits of this procedure?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "There are various advantages of selecting the radiosurgery technique. It aids treatment in less time, no or less bleeding with rapid healing to give aesthetically better or no scars."
          }
        },{
          "@type": "Question",
          "name": "Are there any side effects?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is a simple office based procedure. A very safe technique with minimal downtime. You can expect minor swelling, redness, bruising and tenderness in the area for a few days as it heals."
          }
        },{
          "@type": "Question",
          "name": "What are the pre and post procedure instructions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Inform your doctor about implanted pace makers or electric devices if any.
      Inform your doctor if any keloid tendency in the past (abnormal wound healing leading to ugly scar).
      Keep the treated area clean and dry.
      You may be given ointment or cream for application to the treated area and also oral medication to alleviate pain and to prevent infection depending on the condition treated.
      You may also require touch up sessions usually done after 3 weeks.
      Do not pick the lesions till they fall off by themselves in a week.
      See your dermatologist if there is unusual pain, bleeding and any discharge from wound.
      Avoid sun exposure and sunscreen is mandatory.
      It is very important that you follow the advice of your dermatologist carefully after any radiosurgery treatment to help to ensure the success of the procedure and reduce the risk of complications."
          }
        },{
          "@type": "Question",
          "name": "What are pre and post procedure instructions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "If feasible keep hair short or zero trim prior to the procedure.
      Wash your scalp on the day of procedure and do not apply any lotions, gels or sprays.
      You can wash your scalp regularly the next day morning."
          }
        },{
          "@type": "Question",
          "name": "Are there any side effects and what is the down time?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can expect minimal redness and swelling which subsides in less than 24 hours. The whole procedure takes one to one and half hours and you can resume your normal activity immediately after the procedure."
          }
        }]
      }
      </script>
      `,
  },
  {
    id: "11",
    title: "Sclerotherapy",
    schemaCode: `<script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What is sclerotherapy?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Sclerotherapy is a very cost-effective procedure for treatment of varicose leg veins and also for other vascular lesions, that seldom leaves a scar or produces adverse effects."
          }
        },{
          "@type": "Question",
          "name": "How is it performed?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "It is typically performed on an outpatient basis by a dermatologic surgeon. Prior Doppler testing is done to know the under Lying condition of the veins. A specially developed chemical solution is injected with a very small needle into the spider or varicose vein. The solution causes the vein to close up or collapse and become scar tissue that is eventually absorbed by the body. The work of carrying the blood is shifted to other healthy blood vessels nearby."
          }
        },{
          "@type": "Question",
          "name": "What are the conditions amenable for therapy?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Varicose veins, spider veins, Venous lakes, Pyogenic granuloma and vascular nevus."
          }
        },{
          "@type": "Question",
          "name": "How many sessions are required?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Sclerotherapy generally requires multiple treatment sessions. One to three injections are usually required to effectively treat any vein. The same area should not be retreated for four to six weeks to allow for complete healing, although other areas may undergo treatment during this time."
          }
        },{
          "@type": "Question",
          "name": "What are the post procedure management?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Post-treatment therapy includes wearing bandages and support hose for two days to three weeks (most commonly one week) following treatment. Walking and moderate exercise also helps speedy recovery. Although sclerotherapy works for existing spider and varicose veins, it does not prevent new ones from developing, but may decrease this risk."
          }
        },{
          "@type": "Question",
          "name": "What are the side effects?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Most patients report few, minor side effects, which usually disappear in time. Temporary reactions can include a slight swelling of the leg or foot, minor bruising, pigmentation, the temporary appearance of new blood vessels, redness and mild soreness."
          }
        }]
      }
      </script>
      `,
  },
  {
    id: "12",
    title: "Vitiligo Surgery",
    schemaCode: `
      <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Is pigmentation possible in vitiligo?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Vitiligo is a social disease and despite the many advances in medical treatment, repigmentation can be achieved in only 60-70% of patients. Certain areas like extremities, bony and non- hairy areas, lesions with white hair/lips may not respond adequately. Most patients with extensive lesions, have resistant residual lesions, which can be better targeted by surgeries."
      }
    },{
      "@type": "Question",
      "name": "What are the surgical options?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Surgical options include minipunch grafts, Suction blister graft, Thin split thickness graft, Hair follicle graft, Smash graft, Non-cultured Melanocyte Keratinocyte cell suspension, tattooing and removal of lesion. Based on Size, site and pattern of lesion and Equipment available, the surgical technique is chosen by your Dermatosurgeon."
      }
    },{
      "@type": "Question",
      "name": "When can you plan for vitiligo surgery?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Surgeries are planned in cases with stabilized disease(No new or spread of existing patches for atleast 1 year) with resistant patches (no signs of repigmentation after medical therapy). Normally Surgeries are preferred in cases with less than 30% body surface involvement."
      }
    },{
      "@type": "Question",
      "name": "What are the preoperative work up?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You will be asked to do some blood test to know your fitness for the procedure."
      }
    },{
      "@type": "Question",
      "name": "What is the principle behind surgery?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The white patch lacks pigment producing cells called melanocytes. In surgery we are transferring these cells from your normal skin to white patch so that normal pigmentation comes back."
      }
    },{
      "@type": "Question",
      "name": "How is the procedure done?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In almost all techniques your normal skin is taken from Thigh/arm/behind ear, either the tiny skin or separated cells can be used to treat the area. This depends on size, site and expertise of your doctor. Each technique has its own merits and demerits. Your doctor will adapt surgical procedures as per the patient’s needs."
      }
    },{
      "@type": "Question",
      "name": "What are the instructions to be followed after surgery?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Strict immobilization of part is important. You will be asked to take pain killers and antibiotics for 5 to 7 days."
      }
    },{
      "@type": "Question",
      "name": "When can you expect results?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The pigmentation starts at 3 weeks and completed by 3 to 6 months. Surgery followed by NBUVB/Excimer can repigment the treated lesions in less than 3 months."
      }
    },{
      "@type": "Question",
      "name": "Does surgery cure the disease?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "No, it only helps to regain pigmentation in resistant patches"
      }
    },{
      "@type": "Question",
      "name": "What are the side effects of surgery?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absence of pigmentation, varied pigmentation, cobble stoning are few side effects, which can be corrected."
      }
    }]
  }
  </script>
  
  
  `,
  },

  {
    id: "13",
    title: "Acne Scar Surgery",
    schemaCode: `
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Can acne scars be removed surgically?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Acne scars can be removed with a minor surgical procedure called punch excision or subcision. The procedure includes the use of local anaesthesia to numb the area of the face."
        }
      },{
        "@type": "Question",
        "name": "Can surgery remove facial scars?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, surgery can remove facial scars depending on how deep the scar is, treatments are given accordingly which are Excision f/b closure, Excision f/b grafts, and Scar revision."
        }
      },{
        "@type": "Question",
        "name": "What surgery is best for acne scars?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Depending on the severity of the scars, different surgeries are available. Here are some of the best surgical ways to treat acne scars:
    Subcision
    Microneedling/Derma rolling
    Microneedling Radiofrequency(MNRF)
    Punch excision
    Excision and closure"
        }
      },{
        "@type": "Question",
        "name": "Why are my acne scars getting worse?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Your acne scars will start to worsen when your skin is exposed to sunlight without any protection, when you pick your pimples, or due to ageing."
        }
      },{
        "@type": "Question",
        "name": "Which type of acne scar is most difficult to treat?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Most acne scars are easier to treat than ice-pick scars. They are narrow and V-shaped and start from deep underneath the skin, they might be difficult to treat."
        }
      },{
        "@type": "Question",
        "name": "Can acne scars be permanently removed?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, Acne scar treatment with a procedure can improve the appearance and texture to a significant level but cannot be removed completely."
        }
      },{
        "@type": "Question",
        "name": "How long does acne scar removal take?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The procedure of acne scar surgery will take around 30-45 min"
        }
      },{
        "@type": "Question",
        "name": "Can dermatologists treat acne scars?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Dermatologists can treat acne scars depending on the severity."
        }
      }]
    }
    </script>
    
  `,
  },

  {
    id: "14",
    title: "Cosmelan Peel",
    schemaCode: `
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How many days do you peel after Cosmelan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Your skin may start to peel after 2 - 3 days of initial application of the mask till 15-20 days."
        }
      },{
        "@type": "Question",
        "name": "How long does it take to see results from Cosmelan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Cosmelan treatment can take 4 to 8 weeks depending on your skin condition."
        }
      },{
        "@type": "Question",
        "name": "Does pigmentation come back after Cosmelan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Cosmelan treatment reduces the pigmentation that you had earlier, but without proper skin care and sun protection, pigmentation can form again on your skin."
        }
      },{
        "@type": "Question",
        "name": "Can Cosmelan make pigmentation worse?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Cosmelan will not make your pigmentation worse after the treatment rather it helps to reduce the pigmentation."
        }
      },{
        "@type": "Question",
        "name": "What should you not do after Cosmelan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Avoid going out in the sun
    Do not get any other treatment or surgeries in the area where cosmelan treatment is done for the next 30 days."
        }
      },{
        "@type": "Question",
        "name": "How to wash your face after Cosmelan?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Remove the cosmelan mask with cold water by using a gentle cleanser. 
    Use the cream suggested by your dermatologist."
        }
      },{
        "@type": "Question",
        "name": "Can you wear makeup after Cosmelan peel?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "After 20 days of cosmelan treatment, you can start wearing makeup."
        }
      }]
    }
    </script>
    
    
  `,
  },
];
