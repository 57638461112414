import React from "react";
import Layout from "../layout";
import ImageList from "./HandoutList";

import BgSubHeader from "../BgSubHeader";
import EmptySpace from "../academics/EmptySpace";
const Handout = ({ tlt = "" }) => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero" className="nandout">
            <BgSubHeader title={"PATIENT EDUCATIONAL HANDOUT"} />
            <EmptySpace height="30" />
            <ImageList tlt={tlt} />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Handout;
