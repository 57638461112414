import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Index = ({ children }) => {
  let { pathname } = window.location;
  // console.log(pathname);

  return (
    <React.Fragment>
      <Header />

      {/* {(pathname !== "/" || pathname !== "") && (
        <div className="emptyDiv"></div>
      )} */}
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default Index;
