import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import galleryArr1 from "../../data/Gallery/facacs_ext.json";

const ImageGallery = ({ imgData = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [galleryList, setGalleryList] = useState([]);

  //	console.log("imgData",imgData);

  const setOpenFunc = (tf = true) => {
    setIsOpen(tf);
    let pIndex = 0;
    if (imgData.id) {
      pIndex = imgData.id - 1;
    }
    setPhotoIndex(pIndex);
  };

  useEffect(() => {
    let newArr = [];
    for (let ga of galleryArr1) {
      let docImg = require(`../../assets/img/gallery/exterior/${ga.image}`);
      docImg = docImg.default;
      newArr.push(docImg);
    }
    setGalleryList(newArr);
  }, []);

  let { docImg = "", title = "",alt="" } = imgData;

  return (
    <>
      <div className="col-lg-3 imageGallery">
        <img
          onClick={() => setOpenFunc(true)}
          src={docImg}
          className="img-fluid1 imageGalleryImg"
          alt={alt}
        />
        {/* 
				<div className="imageText">
					<p>{title}</p>
				</div> */}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={galleryList[photoIndex]}
          nextSrc={galleryList[(photoIndex + 1) % galleryList.length]}
          prevSrc={
            galleryList[
              (photoIndex + galleryList.length - 1) % galleryList.length
            ]
          }
          onCloseRequest={() => setOpenFunc(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryList.length - 1) % galleryList.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryList.length)
          }
        />
      )}
    </>
  );
};

const App = () => {
  const [imageTopSectionList, setImageTopSectionList] = useState([]);

  useEffect(() => {
    let galleryNewArr = galleryArr1.map((ga) => {
      let docImg = require(`../../assets/img/gallery/exterior/${ga.image}`);
      docImg = docImg.default;
      ga.docImg = docImg;
      return ga;
    });

    console.log("galleryNewArr", galleryNewArr);
    setImageTopSectionList(galleryNewArr);
  }, []);

  return (
    <div className="container">
      <div className="row">
        {imageTopSectionList &&
          imageTopSectionList.map((img) => (
            <ImageGallery key={img.id} imgData={img} />
          ))}
      </div>
    </div>
  );
};
export default App;
