import React from "react";
const ServiceSideBarMenu = ({ service, totalLength, index }) => {
  let { title, link } = service;
  let notLastIndex = true;
  if (totalLength - index === 1) {
    notLastIndex = false;
  }
  return (
    <>
      <li>
        <a href={link}>{title}</a>
        {notLastIndex && <hr />}
      </li>
    </>
  );
};

export default ServiceSideBarMenu;
