import React from "react";
import Layout from "./layout";
import Registers from "./Registrars";

import BgSubHeader from "./BgSubHeader";

const Register = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"REGISTRAR"} />
            <Registers />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Register;
