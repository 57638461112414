import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Testimonials = () => {
  const TestimonialList = [
    {
      id: 1,
      TestimonialContent:
        "Very happy with the hospitality. Proper medicines were given healing time is very quick . a comfortable  experience. Thank you CACS.",
      TestimonialTitle: "- Rashmi Karthik",
    },
    {
      id: 2,
      TestimonialContent:
        "Cutis hospital ıs very good treatment for loss. For loss of haır Doctors are very good. Medıcıne suggest for haır growing  thıs ıs hundred percent truth I am the real patient",
      TestimonialTitle: "- Venkatesh K",
    },
    {
      id: 3,
      TestimonialContent:
        "I am happy to share my experience with Cutis, I underwent HT in Sep 18 and the results are amazing. Which has helped me in regaining my levels of confidence. Happy to have come to the right place. Excellent team of Doctors, great value for the money. Thanks to Cutis",
      TestimonialTitle: "- Venkat",
    },
    {
      id: 4,
      TestimonialContent:
        "Cutis is one solution for all skin related problems. You get best treatment and medicines in cutis. Doctors diagnose the problem properly and provides the right treatment for that. All doctors are very friendly, cooperative staff, great  ambience and awesome experience.!!.",
      TestimonialTitle: "- Jayalakshmi",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cseEase: "linear",
    lazyLoad: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    swipeToSlide: true,
  };
  return (
    <>
      <div
        className="container my-3 my-sm-5"
        id="testimonials_services_home"
        data-aos="fade-up"
      >
        <div className="need-heading">
          <h2 className="text-center text-til my-3">TESTIMONIALS</h2>
        </div>
        <p className="text-center">
          Here is some reviews about our Cutis Academy
        </p>
        <Slider {...settings}>
          {TestimonialList.map((value, index) => (
            <div className="row mx-2" key={index} data-aos="fade-up">
              <div className="col-12">
                <div className="card border-0">
                  <div className="card-title p-3">
                    <p>
                      <i class="fas fa-quote-left mr-2"></i>
                      {value.TestimonialContent}
                      <i class="fas fa-quote-right ml-2"></i>
                    </p>
                    <small className="float-right mr-4 font-weight-bold">
                      <span>{value.TestimonialTitle}</span>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Testimonials;
