import React from "react";
import Layout from "./layout";
import SectionTitle from "./home/SectionTitle";
// import ServiceData from './ServiceData';
// import MoreServices from '../home/featuredService/MajorServices';

import BgSubHeader from "./BgSubHeader";

// import patientStoriesFaq from "../data/Faq/patientStories.json";

import img1 from "../assets/img/service/bg/05.jpg";

const img1Div = { backgroundImage: "url(" + img1 + ")" };

const PatientStories = () => {
  // let { pathname } = window.location;
  // console.log("href", pathname);
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"PRIVACY POLICY"} />
            <div className="container">
              <div className="container_nurse">
                <SectionTitle title={""} desc={""} />
                <p>
                  These terms and conditions of website and mobile application
                  shall govern by the site www.cutis.org.in, and all other URLs
                  forming part thereof (hereinafter referred to as "the Sites")
                  belongs to www.cutis.org.in The Sites host the sole
                  information about Cutis Academy of Cutaneous Science Hospital
                  and there is no dispute in any circumstances. These terms and
                  conditions shall apply on the mobile applications also.
                </p>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default PatientStories;
