import React from "react";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

const App = () => (
  <Container className="p-3">
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Course</th>
          <th>Number Of Seats</th>
          <th>Duration (1 Month)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Observership</td>
          <td>02</td>
          <td>It will start on the 1st of every month</td>
        </tr>
        {/* <tr>
          <td>Observership</td>
          <td>02</td>
          <td>Feb-April</td>
        </tr>
        <tr>
          <td>Observership</td>
          <td>02</td>
          <td>May-July</td>
        </tr>
        <tr>
          <td>Observership</td>
          <td>02</td>
          <td>Aug-Oct</td>
        </tr> */}
      </tbody>
    </Table>
    <p>
      To enroll for observership programme at CUTIS Academy of Cutaneous
      sciences Resume/CV should be forwarded in advance.
    </p>
    <a href="../../assets/img/pdf/ObserverGuidelines.pdf" target="_blank">
      Click here to download the guidelines and instructions for observers in
      CACS
    </a>
    <br />
    <br />
    <p>
      For More Information Contact Us At <br />
      <br />
      <a href="tel:9148894271">9148894271</a> &nbsp; &nbsp;
      <a href="mailto:academy@cutis.org.in">academy@cutis.org.in</a>.
    </p>
  </Container>
);

export default App;
