import React from "react";
import "./errorstyle.css";
import errorimg from "../assets/error.jpg";
import Header from "../../src/components/layout/Header";
const ErrorPage = () => {
  return (
    <>
      <div>
        <Header />
      </div>
      <br />
      <div>
        <div className="container" style={{ marginTop: "90px" }}>
          <div id="errorpage" className="text-center">
            <div className="text-center">
              <img src={errorimg} alt="Not Available" />
            </div>
            <h1 className="text-danger">Page Not Found. Sorry !!!</h1>
            <h2 className="mt-4">Please bear with us</h2>
            <h3>
              For appointment booking Kindly contact OUR CALL CENTER{" "}
              <a href="tel:8023401200">080-23401200</a>{" "}
            </h3>
            <a href="/" className="mt-5r">
              Go to Main page
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
