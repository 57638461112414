import React from "react";
import Layout from "./layout";
import VisitingConsultant from "./VisitingConsultant";

import BgSubHeader from "./BgSubHeader";

const VisitingConsultantMain = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"VISITING CONSULTANT "} />
            <VisitingConsultant />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default VisitingConsultantMain;
