import React from "react";
import Layout from "./layout";
import PediatricDermatologist from "./PediatricDermatologist";

import BgSubHeader from "./BgSubHeader";

const PediatricDermatologistMain = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"PEDIATRIC DERMATOLOGIST "} />
            <PediatricDermatologist />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default PediatricDermatologistMain;
