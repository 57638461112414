import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";

import SectionTitle from "../home/SectionTitle";

import gallery from "../../data/Gallery/index.json";

const Gallery = () => {
  const [galleryList, setGalleryList] = useState([]);

  useEffect(() => {
    setGalleryList(gallery);
  }, []);
  // console.log(galleryList);
  return "";
  return (
    <>
      {/* ======= Gallery Section ======= */}
      <section id="gallery" className="gallery">
        <div className="container alignCenterBeforeservices" data-aos="fade-up">
          <div className="row">
            <div className="col-md-6">
              <SectionTitle title={"Before & After Gallery"} />
              <p>
                We are CUTIS Academy of Cutaneous Sciences (CACS) , with the
                training, expertise and high-level skills, to make you look and
                feel at its very best.
              </p>
              <p>
                We are confident in our ability to achieve outstanding results
                for our patients. Check out the before and after images to see
                the differences achieved.
              </p>
            </div>
            <div className="col-md-6 ">
              <OwlCarousel
                items={1}
                className="owl-carousel gallery-carousel"
                data-aos="fade-up"
                data-aos-delay="100"
                loop
                autoplay={true}
                autoplayTimeout={4000}
                margin={8}
                nav={false}
                dots={true}
                navText={[
                  '<i className="fa fa-angle-left" aria-hidden="true"></i>',
                  '<i className="fa fa-angle-right" aria-hidden="true"></i>',
                ]}
              >
                {/* galleryList.map((data) => <GalleryItem key={data.id} data={data}/>) */}
                <a
                  href="/assets/img/home/before_after/01.png"
                  className="venobox"
                  data-gall="gallery-carousel"
                >
                  <img src="/assets/img/home/before_after/01.png" alt="" />
                </a>
                <a
                  href="/assets/img/home/before_after/01.png"
                  className="venobox"
                  data-gall="gallery-carousel"
                >
                  <img src="/assets/img/home/before_after/01.png" alt="" />
                </a>
                <a
                  href="/assets/img/home/before_after/02.png"
                  className="venobox"
                  data-gall="gallery-carousel"
                >
                  <img src="/assets/img/home/before_after/02.png" alt="" />
                </a>
                <a
                  href="/assets/img/home/before_after/03.png"
                  className="venobox"
                  data-gall="gallery-carousel"
                >
                  <img src="/assets/img/home/before_after/03.png" alt="" />
                </a>
                <a
                  href="/assets/img/home/before_after/04.png"
                  className="venobox"
                  data-gall="gallery-carousel"
                >
                  <img src="/assets/img/home/before_after/04.png" alt="" />
                </a>
                <a
                  href="/assets/img/home/before_after/05.png"
                  className="venobox"
                  data-gall="gallery-carousel"
                >
                  <img src="/assets/img/home/before_after/05.png" alt="" />
                </a>
                {/* <a href="/assets/img/home/before_after/06.png" className="venobox" data-gall="gallery-carousel"><img src="/assets/img/home/before_after/06.png" alt="" /></a>
                        <a href="/assets/img/home/before_after/07.png" className="venobox" data-gall="gallery-carousel"><img src="/assets/img/home/before_after/07.png" alt="" /></a>
                        <a href="/assets/img/home/before_after/08.png" className="venobox" data-gall="gallery-carousel"><img src="/assets/img/home/before_after/08.png" alt="" /></a>
                        <a href="/assets/img/home/before_after/09.png" className="venobox" data-gall="gallery-carousel"><img src="/assets/img/home/before_after/09.png" alt="" /></a>
                        <a href="/assets/img/home/before_after/10.png" className="venobox" data-gall="gallery-carousel"><img src="/assets/img/home/before_after/10.png" alt="" /></a> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* End Gallery Section */}
    </>
  );
};

export default Gallery;
