import React from "react";
const DoctorPage = (props) => {
  let {
    name = "Dr. Sarala N",
    designation = "Clinical Nutritionist ( Consultant )",
  } = props.doctor;
  return (
    <>
      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-5 text-center" data-aos="fade-right">
              <img
                src="/assets/img/home/doctors/dr-sarla-new.png"
                className="img-fluid doctorsImg"
                alt="Dr. Sarala - Lead Aesthetic Dermatologist & Consultant Dermatologist at Cutis"
              />
              {/* <br /> */}
              {/* <br /> */}
              {/* <a
                target="_blank"
                href="https://appointment.cutis.org.in/step-second/doctorid/5"
                className="btn btn-outline-info"
              >
                Book Appointment
              </a> */}
            </div>
            <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>{name}</h3>
              <div className="small-separator"></div>
              <p className="designation">{designation}</p>
              {/* <br /> */}

              <p>
                Food Science & Technology, Master of Science (Dietetics and Food
                service Management), Certified in Clinical Nutrition
              </p>
              {/* <br /> */}
              <h5 className="medalPTag">
                <strong className="medalPTagStrong">ACADEMICS</strong>
              </h5>
              <ul className="presentationsUl">
                <li classname="presentationsLi">
                  Food Science and Technology - University of Agriculture
                  Sciences, Hassan
                </li>
                <li classname="presentationsLi">
                  MSc (DFSM) - Mount Caramel college Bangalore
                </li>
                <li>Clinical Nutrition- VLCC</li>
              </ul>

              <h5 className="medalPTag">
                <strong className="medalPTagStrong">AREA OF INTEREST</strong>
              </h5>
              <ul className="presentationsUl">
                <li classname="presentationsLi">Clinical Nutrition</li>
                <li classname="presentationsLi">Product Development</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DoctorPage;
