import React from "react";
import { Helmet } from "react-helmet";
import { blogSchema } from "./Blogschem";
export default function BlogSchema({ id }) {
  // if (!id) {
  //   return "";
  // }
  const blogSchemaFilter = blogSchema.filter((schemaid) => id == schemaid.id);

  let articleStructuredData2;

  if (blogSchemaFilter.length >= 1) {
    articleStructuredData2 = blogSchemaFilter[0].schemaCode.trim();
  }

  return (
    <>
      <script type="application/ld+json">{`{${articleStructuredData2}}`}</script>
    </>
  );
}
