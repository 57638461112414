import React from "react";
import Layout from "../layout";
import BgSubHeader from "../BgSubHeader";
import ProceduralDermatologyContent from "./ProceduralDermatologyContent";
import EmptySpace from "./EmptySpace";

const ProceduralDermatology = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"FELLOWSHIP IN PROCEDURAL DERMATOLOGY"} />

            <ProceduralDermatologyContent />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default ProceduralDermatology;
