import React from "react";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

// const App = () => (
//   <Container className="p-3">
//     <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
//       <Tab eventKey="home" title=" Educational Programme CACS- 1">
//         <div className="tabData">
//           <ul>
//             <li>Biologicals in Psoriasis</li>
//             <li>Pregnancy Dermatoses</li>
//             <li>Organisation of Dermatology clinic</li>
//             <li>Burning issues in private practice</li>
//             <li>Update of topical in psoriasis</li>
//             <li>Topical steroids in dermatology</li>
//             <li>Botox and fillers</li>
//             <li>An update on methotrexate</li>
//             <li>Dermatosurgical Procedures</li>
//           </ul>
//         </div>
//       </Tab>
//       <Tab eventKey="profile" title=" Educational Programme CACS- 2">
//         <div className="tabData">
//           <ul>
//             <li>Treatment of alopecia areata in children</li>
//             <li>Application of Botox in dermatology</li>
//             <li>Phototherapy in Dermatology</li>
//             <li>Diagnostic dermatology</li>
//             <li>Fungal infections and antifungal therapy in dermatology</li>
//             <li>Photodermatoses and sunscreens</li>
//             <li>Common skin conditions in children, thinking differently.</li>
//             <li>Unfolding potential of topical retinoids</li>
//             <li>Procedural management of acne.</li>
//           </ul>
//         </div>
//       </Tab>
//       <Tab eventKey="contact" title=" Educational Programme CACS- 3">
//         <div className="tabData">
//           <ul>
//             <li>PRP in dermatology</li>
//             <li>Â Acne recent insights</li>
//             <li>Psoriatic Arthritis</li>
//             <li>An approach to Vasculitis</li>
//             <li>HCQS (Hydroxychloroquine) in Dermatology</li>
//             <li>Procedural dermatology outcome</li>
//             <li>Azathioprine in dermatology</li>
//             <li>Minocycline Vs OMP in treatment of vitiligo</li>
//             <li>Whats new in Melasma Management</li>
//           </ul>
//         </div>
//       </Tab>
//       <Tab eventKey="tab4" title="Training Programme Feedback">
//         <div className="tabData">
//           <p>
//             We also conduct training programs for aspiring dermatologists to get
//             hands-on experience with state of the art laser equipments; botox
//             and fillers; body and face sculpting; and various other aspects of
//             laser treatments and procedural dermatology. The trainees can also
//             benefit from spacious operating theaters to learn scar revision,
//             hair transplant, vitiligo surgery etc.
//           </p>
//           <p>
//             Some of the feedback we received for our training programme are:
//           </p>
//           <ul>
//             <li>
//               “One platform for all procedures, best place to learn at short
//               time”
//             </li>
//             <li>
//               “Co-operation and willingness of the faculties in clarifying the
//               queries”
//             </li>
//             <li>
//               “Tips given by the faculty in each procedure is excellent,
//               especially Dr Chandrashekar’s tips to establish lasers for
//               beginners, excellent.”
//             </li>
//             <li>
//               “Regarding this training, best part was that they teach you very
//               practical things. It was the best.”
//             </li>
//             <li>“All practical tips and practice methods were very good”</li>
//             <li>
//               “Knowledge shared by the trainers is excellent and very modest
//               towards training.”
//             </li>
//           </ul>
//         </div>
//       </Tab>
//     </Tabs>
//   </Container>
// );

// export default App;

const App = () => (
  <Container className="p-3">
    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
      <Tab eventKey="home" title=" Aesthetics">
        <div className="tabData">
          <ul>
            <li>Moisturizers</li>
            <li>Cleansers + Face wash </li>
            <li>Sunscreens</li>
            <li>Light and lasers in acne</li>
            <li>Botulinum toxin - cosmetic & non cosmetic indications</li>
            <li>Melasma - newer drugs and treatment modalities</li>
            <li>Camouflage</li>
            <li>Laser safety measures</li>
            <li>Mesotherapy</li>
            <li>HIFU .</li>
          </ul>
        </div>
      </Tab>
      <Tab eventKey="profile" title="Dermatosurgery">
        <div className="tabData">
          <ul>
            <li>Anatomy of face </li>
            <li>Hand and foot surgeries</li>
            <li>Sclerotherapy</li>
            <li>Thread lift</li>
            <li>Ear lobe repair</li>
            <li>Nerve block</li>
            <li>Blepharoplasty</li>
            <li>Ablative lasers in dermatology</li>
            <li>Biofillers</li>
            <li>Surgical management of ulcer.</li>
          </ul>
        </div>
      </Tab>
      <Tab eventKey="contact" title=" Pediatric dermatology">
        <div className="tabData">
          <ul>
            <li>Approach to a case of non cicatricial alopecia</li>
            <li>Emergencies in paediatric dermatology</li>
            <li>Diaper dermatitis</li>
            <li>Physiological lesions in neonate</li>
            <li>Pathological lesions in neonate</li>
            <li>Neonatal skin care</li>
            <li>Topicals in atopic dermatitis</li>
          </ul>
        </div>
      </Tab>
      <Tab eventKey="tab4" title="Clinical Dermatology">
        <div className="tabData">
          <ul>
            <li>Unfolding potential of topical retinoids</li>
            <li>Biologicals in Psoriasis</li>
            <li>Pregnancy Dermatoses</li>
            <li>Topical treatment in psoriasis</li>
            <li>Photodermatoses and sunscreens</li>
            <li>Psoriatic Arthritis</li>
            <li>An approach to Vasculitis</li>
            <li>HCQS (Hydroxychloroquine) in Dermatology</li>
            <li>Azathioprine in dermatology</li>
            <li>Minocycline Vs OMP in treatment of vitiligo</li>
            <li>Phototherapy in Dermatology</li>
            <li>Fungal infections and antifungal therapy in dermatology</li>
            <li>Topical steroids in dermatology</li>
            <li>An update on methotrexate</li>
          </ul>
        </div>
      </Tab>
    </Tabs>
  </Container>
);

export default App;
