import React from "react";
import Layout from "../layout";
import VideoList from "./VideoList";

import BgSubHeader from "../BgSubHeader";
import EmptySpace from "../academics/EmptySpace";
const PatientStories = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"PATIENT EDUCATIONAL VIDEOS"} />
            <EmptySpace height="30" />
            {/* <iframe className="iframeClassA" title={"Title"} src={"https://www.youtube.com/embed/q4B1OcGUc8I"} frameBorder='0'></iframe> */}

            <VideoList />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default PatientStories;
