import React from "react";

const Two = ({ service }) => {
  let { title, link } = service;
  // console.log("navClassName");
  return (
    <>
      <li>
        <a href={link} className="blueText">
          {title}
        </a>
      </li>
    </>
  );
};

export default Two;
