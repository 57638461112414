import React from "react";
import ServiceSideBarMenu from "./ServiceSideBarMenuLink";
import ServiceSideBarMenuLink from "./SidebarServices.json";
const Index = () => {
  let testServiceList = ServiceSideBarMenuLink;

  let testlink = window.location.href;
  let testlink2 = testlink.split("/").slice(3).join("/").replace("#", "");

  let testlink3 = "/" + testlink2;
  let upServiceObj = testServiceList.find((x) => x.mainlink === testlink3);
  let testlist = upServiceObj.subMenu;

  let testnewServiceList = [testlist[0], testlist[1], testlist[2], testlist[3]];

  let testtotalLength = testnewServiceList.length;
  return (
    <>
      <div className="sidebarStartDiv">
        <div className="serviceList">
          <h2>Related Services</h2>
          <ul>
            {testnewServiceList &&
              testnewServiceList.map((service, index) => (
                <ServiceSideBarMenu
                  key={service.id}
                  service={service}
                  totalLength={testtotalLength}
                  index={index}
                />
              ))}
          </ul>
        </div>

        <div className="book_appointment_div" style={{ textAlign: "center" }}>
          <a
            href="https://appointment.cutis.org.in/"
            style={{ margin: "0px auto" }}
          >
            <button className="book-appointment">Book Appointment</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Index;
