import React from "react";
import Layout from "./layout";
import ContactForm from "./home/Contact";

import BgSubHeader from "./BgSubHeader";

const Contact = () => {
  // let { pathname } = window.location;
  // // console.log("href", pathname);

  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"CONTACT US"} />
            <ContactForm />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Contact;
