import React from "react";
import Layout from "./layout";
import NurseEducators from "./Nurses";

import BgSubHeader from "./BgSubHeader";

const Nurse = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"NURSES"} />
            <NurseEducators />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Nurse;
