import React from "react";
import Layout from "../layout";
import CarrerForm from "../CarrerForm";

import BgSubHeader from "../BgSubHeader";
import Tab from "./Tab";
import EmptySpace from "./EmptySpace";

const EducationalProgramme = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"EDUCATIONAL PROGRAMME"} />
            <EmptySpace height={30} />
            <Tab />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default EducationalProgramme;
