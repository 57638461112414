import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ModalVideo from "react-modal-video";

import EmptySpace from "../academics/EmptySpace";
import VideoListData from "../../data/Gallery/video.json";
import evtVideoListData from "../../data/Gallery/events_video.json";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CutisEvents from "./VideosEvents";
import galleryArr from "../../data/Gallery/events.json";

const ImageGallery = ({ imgData = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [galleryList, setGalleryList] = useState([]);

  //	console.log("imgData",imgData);

  const setOpenFunc = (tf = true) => {
    setIsOpen(tf);
    let pIndex = 0;
    if (imgData.id) {
      pIndex = imgData.id - 1;
    }
    setPhotoIndex(pIndex);
  };

  useEffect(() => {
    let newArr = [];
    for (let ga of galleryArr) {
      let docImg = require(`../../assets/img/gallery/events/${ga.image}`);
      docImg = docImg.default;
      newArr.push(docImg);
    }
    setGalleryList(newArr);
  }, []);

  let { docImg = "", title = "" } = imgData;

  return (
    <>
      <div className="col-lg-3 imageGallery">
        <img
          onClick={() => setOpenFunc(true)}
          src={docImg}
          className="img-fluid1 imageGalleryImg"
          alt={title}
        />
        {/* 
				<div className="imageText">
					<p>{title}</p>
				</div> */}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={galleryList[photoIndex]}
          nextSrc={galleryList[(photoIndex + 1) % galleryList.length]}
          prevSrc={
            galleryList[
            (photoIndex + galleryList.length - 1) % galleryList.length
            ]
          }
          onCloseRequest={() => setOpenFunc(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryList.length - 1) % galleryList.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryList.length)
          }
        />
      )}
    </>
  );
};
const VideoItem = ({ video }) => {
  const [isOpen, setOpen] = useState(false);
  const vvid = video.vid;
  const src = "https://img.youtube.com/vi/" + vvid + "/0.jpg";
  const titl = video.title;
  const alt = video.alt;
  const [showBtn, setShowBtn] = useState(true);

  const btnShow = () => {
    // console.log("fuvc btnShow", video.id);
    setShowBtn(true);
  };

  const btnHide = () => {
    setShowBtn(false);
    // console.log("fuvc  btnHide", video.id);
  };

  return (
    <div key={video.id} className="col-lg-4 col-md-4 col-xs-12 videoDiv">
      {/* <div className='player-wrapper'>
				<iframe className="iframeClass" title={video.title} src="https://www.youtube.com/embed/q4B1OcGUc8I" frameBorder='0'></iframe>
				<h6 className="videoTestTitle">{video.title}</h6>
			</div> */}

      {/* onMouseEnter={() => btnShow()} onMouseLeave={() => btnHide()}  */}

      <img className="img-fluid" src={src} alt={alt} />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={vvid}
        onClose={() => setOpen(false)}
      />
      <a href="#" onClick={() => setOpen(true)}>
        {showBtn == true && (
          <>
            <div className="play-btn">
              <span class="ripple">
                <i className="icofont-ui-play custom-video-icon-video-detail"></i>
              </span>
            </div>
            <div className="imageGallery-videos">
              <div className="imageText-videos">
                <p className="video-title ">{titl}</p>{" "}
              </div>
            </div>
          </>
        )}
      </a>
    </div>
  );
};
const Images = () => {
  const [imageTopSectionList, setImageTopSectionList] = useState([]);

  useEffect(() => {
    let galleryNewArr = galleryArr.map((ga) => {
      let docImg = require(`../../assets/img/gallery/events/${ga.image}`);
      docImg = docImg.default;
      ga.docImg = docImg;
      return ga;
    });

    // console.log("galleryNewArr", galleryNewArr);
    setImageTopSectionList(galleryNewArr);
  }, []);

  return (
    <div className="container">
      <div className="row">
        {imageTopSectionList &&
          imageTopSectionList.map((img) => (
            <ImageGallery key={img.id} imgData={img} />
          ))}
      </div>
    </div>
  );
};

const VideoList = ({ page, pgname = "" }) => {
  let itemList;
  let clsnm = "play-btn";
  if (pgname == "") {
    itemList = VideoListData;
  } else if (pgname == "events") {
    clsnm = "play-btn d-none";
    itemList = evtVideoListData;
  }

  const [isOpen, setOpen] = useState(false);
  return (
    // <Container className="p-3">
    //   <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
    //     <Tab eventKey="home" title=" Videos">
    <div className="tabData">
      <div className="container videoTestList">
        <div>
          {/* <img
            className="img-fluid"
            src="/assets/img/gallery/videos/latest-video.jpg"
            alt=""
          /> */}
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="L61p2uyiMSo"
            onClose={() => setOpen(false)}
          />
          <a href="#" onClick={() => setOpen(true)}>
            <div className={clsnm}>
              <span class="ripple">
                <i className="icofont-ui-play custom-video-icon"> </i>
              </span>
            </div>
          </a>
        </div>
        <EmptySpace height={30} />
        <div className="row">
          {itemList.map((video) => (
            <VideoItem key={video.id} video={video} />
          ))}
        </div>
      </div>
    </div>
    /* </Tab>
        <Tab eventKey="profile" title=" Images">
          <div className="tabData">
            <Images></Images>
          </div>
        </Tab>
      </Tabs>
    </Container> */
  );
};

export default VideoList;
