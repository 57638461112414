import React, { useState } from "react";
function Readmore({ children, maxCharacterCount }) {
  const text = children;
  const [isTruncated, isNotTruncated] = useState(false);
  const resultString = isTruncated ? text.slice(0, 100) : true;
  function isTruncatedtoggle() {
    isNotTruncated(!isTruncated);
  }
  return (
    <p>
      {resultString}
      <span onClick={isTruncatedtoggle} className="readmorebtn">
        {isTruncated ? "Read Less" : "Read More"}
      </span>
    </p>
  );
}

const DoctorPage = (props) => {
  let {
    name = "Dr. Madura C",
    designation = "Medical Director, Chief Hair Transplant & Dermatosurgeon",
  } = props.doctor;
  return (
    <>
      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-5 text-center" data-aos="fade-right">
              <img
                src="/assets/img/home/doctors/Dr-Madhura-C.jpg"
                className="img-fluid doctorsImg"
                alt="Dr. Madhura C"
              />
              <br />
              <br />
              <a
                target="_blank"
                href="https://appointment.cutis.org.in/step-second/doctorid/4"
                className="btn btn-outline-info"
              >
                Book Appointment
              </a>
            </div>
            <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>{name}</h3>
              <div className="small-separator"></div>
              <p className="designation">{designation}</p>
              <br />

              <h5 className="medalPTag">
                <strong className="medalPTagStrong">ACADEMICS </strong>
              </h5>
              <ul className="presentationsUl">
                <li classname="presentationsLi">
                  MBBS – Vijayanagar Institute of Medical Sciences(2000),
                  Bellary Karnataka.
                </li>
                <li classname="presentationsLi">
                  MD (Dermatology, Venereology and Leprosy) 2005 – Jawaharlal
                  Nehru Medical College, Belgaum, Karnataka
                </li>
                <li classname="presentationsLi">
                  FRGUHS (Dermatosurgery) 2012 -Bangalore Medical College
                  Research Institute, Bangalore
                </li>
                <li>
                  6 years of experience as Asst Professor in Dept of
                  dermatology, SS institute of medical sciences, Davangere.
                </li>

                <li>
                  Currently working at ‘CUTIS’ Academy of Cutaneous Sciences,
                  Bangalore as Medical Director, Chief Hair Transplant &
                  Dermatosurgeon From 2012 to till date.
                </li>
                {/* Consultant dermatologist and dermatosurgeon and
                  hair transplant surgeon. From 2012 to till date. */}
                <li>Total experience of post MD is 12 years</li>
                <li>
                  Has done more than 18000 dermatosurgeries, more than 600
                  transplants and 75 scar revisions.
                </li>
              </ul>
              <h5 className="medalPTag">
                <strong className="medalPTagStrong">CURRENT POSITION</strong>
              </h5>
              <ul className="presentationsUl">
                <li classname="presentationsLi">
                  Medical Director, Chief Hair Transplant & Dermatosurgeon at
                  ‘CUTIS’ Academy of Cutaneous Sciences, Bangalore.
                  {/* Consultant dermatologist and dermatosurgeon, ‘CUTIS’ Academy
                  of Cutaneous Sciences, Bangalore. */}
                </li>
                <li classname="presentationsLi">
                  Coordinator for fellowship programme at Cutis, affiliated to
                  RGUHS
                </li>
              </ul>
              <Readmore>
                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    MEMBERSHIP IN FOLLOWING ASSOCIATIONS
                  </strong>
                </h5>
                <ul>
                  <li>
                    Life Membership- IADVL(Indian Association of Dermatologists,
                    Venereologists&Leprologists) Membership No. LM/KT/4287.
                  </li>
                  <li>
                    Member of International Society of Dermatologic Surgery
                    (ISDS)
                  </li>
                  <li>
                    Member of Association Of Cutaneous Surgeons of India
                    [ACS(I)]
                  </li>
                  <li>
                    Member of Association of Asian hair restoration surgeons
                    [AHRS]
                  </li>
                  <li>Member of CSI</li>
                </ul>
                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    KEY SKILLS & ACHIEVEMENTS
                  </strong>
                </h5>
                <ul>
                  <li>
                    An ambitious Dermatologist, who is interested in working for
                    an institution with opportunities for training and
                    interested to practice dermatosurgery and cosmetology.
                  </li>
                  <li>
                    Completed dissertation ( Title: Clinico-histopathological
                    Study of Lichen planus and its association with HCV
                    infection) under able guidance of Prof. Dr . B.
                    Siddaramappa, MD.
                  </li>
                  <li>
                    Good clinical exposure besides routine dermatological
                    procedures during MD course and in SS institute as assistant
                    professor. Participated in many camps & conducted clinics,
                    slide shows and lectures for undergraduates.{" "}
                  </li>
                  <li>
                    Has undergone IMAI – WHO training programme, at St Johns
                    medical college and was trainer and mentor for davangere
                    district.
                  </li>
                  <li>
                    Worked as Scientific secretary- workshop at WCOCD 2017
                  </li>
                  <li>Currently Member SIG trichology HT Group </li>
                  <li>Member SIG dermatosurgery</li>
                  <li>Scientific secretory Dermacon Workshops 2019</li>
                </ul>

                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    EXPERIENCE IN DERMATOLOGICAL PROCEDURES
                  </strong>
                </h5>
                <ul>
                  <li>Chemical Peels, Electrosurgery and cryotherapy</li>
                  <li>
                    Simple office based procedures(Skin biopsy, mole removal,
                    cyst excision etc.)
                  </li>
                  <li>Dermal fillers and Botox and Lasers</li>
                  <li>Platelet rich plasma(PRP) therapy.</li>
                  <li>
                    Hair transplantation(FUE, FUT & Body hair transplantation),
                    Eyebrow and moustache transplantation
                  </li>
                  <li>
                    Flap surgeries, Autologous fat transplantation, Vitiligo
                    surgeries, Nail surgeries, Scar revision and Sclerotherapy.
                  </li>
                </ul>
                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    AREAS OF INTEREST:
                  </strong>
                </h5>

                <ul>
                  <li>Scar revision</li>
                  <li>Hair transplantation</li>
                  <li>Flap surgeries</li>
                  <li>Autologous fat transplantation</li>
                  <li>Nail surgeries</li>
                </ul>
                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    Attended many national and state conferences and presented
                    many papers and posters.
                  </strong>
                </h5>
                <ul>
                  <li>
                    Homozygous familial hypercholesteremia with multiple
                    xanthomas.
                  </li>
                  <li>Association of lichen planus with HCV infection.</li>
                  <li>Ulcerative lupus vulgaris on penis – A case report</li>
                  <li>
                    A study of cutaneous manifestations of chikungunya fever.
                  </li>
                  <li>Dyscromatosisheriditariauniversalis – A case report.</li>
                  <li>
                    Molluscumcontagiosum overlying strawberry hemangioma .
                  </li>
                  <li>Histoid leprosy – A report of 4 cases.</li>
                  <li>LPP or ashy dermatosis diagnostic dilemma???</li>
                </ul>

                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    PRESENTATION IN CONFERENCES / CMES/ WORKSHOPS AS FACULTY
                  </strong>
                </h5>
                <ul>
                  <li>Scar revision</li>
                  <li>Hair transplantation</li>
                  <li>Flap surgeries</li>
                  <li>Autologous fat transplantation</li>
                  <li>Nail surgeries</li>
                </ul>
                {/* <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    ORGANIZATIONAL SKILLS{" "}
                  </strong>
                </h5>
                <ul className="presentationsUl">
                  <li classname="presentationsLi">
                    Conducted torrent live derrmatosurgery workshop at Cutis
                    academy, 2016
                  </li>
                  <li classname="presentationsLi">
                    Scientific secretary- workshop at WCOCD 2017
                  </li>
                  <li classname="presentationsLi">
                    Scientific secretary Haircon 2018
                  </li>
                  <li classname="presentationsLi">
                    Currently Member SIG Dermatosurgery 2018-19
                  </li>
                  <li classname="presentationsLi">
                    Scientific secretary Workshops and target course Dermacon
                    international 2019
                  </li>
                  <li classname="presentationsLi">
                    Scientific secretary Roots 2019
                  </li>
                  <li classname="presentationsLi">
                    Conducted 12 observational workshops on dermatosurgery and
                    lasers at Cutis academy in 2014 to 2016
                  </li>
                </ul>
                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">
                    PUBLICATIONS AND RESEARCH{" "}
                  </strong>
                </h5>
                <ul className="presentationsUl">
                  <li classname="presentationsLi">
                    Presented scientific papers and posters at several Regional,
                    National and International conferences.
                  </li>
                  <li classname="presentationsLi">
                    Has contributed to a chapter in Handbook of dermatologic
                    drug therapy, text book of lasers and hair transplantation.
                  </li>
                  <li classname="presentationsLi">
                    She has delivered talks at the state as well as the national
                    forum.
                  </li>
                  <li classname="presentationsLi">
                    Areas of interest : Hair transplantation, Scar revisions and
                    laser medicine.
                  </li>
                </ul> */}
              </Readmore>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DoctorPage;
