import React from "react";
import SectionTitle from "./home/SectionTitle";

const iframeStyle = { border: 0, width: "100%", height: "350px" };

const Nurses = () => {
  return (
    <>
      <div className="container">
        <div className="container_nurse">
          <SectionTitle title={"Job Description Nurses"} desc={""} />
          <ul>
            <li>
              Staff Nurse under the guidance of Dermatologists, perform laser /
              other related procedures such as acne, psoriasis or eczema etc.,
              and cosmetic procedures such as chemical peeling, laser
              resurfacing etc.,
            </li>
            <li>
              The Staff Nurse need to assist in providing preoperative and
              post-operative care for patients who have undergone surgery.
            </li>
            <li>
              The Staff Nurse should also assist in surgeries, performing
              biopsies and excision, freezing skin lesions, providing treatment
              of leg veins and removing tattoos.
            </li>
            <li>
              To efficiently perform the laser procedures that they have been
              trained for under the guidance of the dermatologists
            </li>
            <li>
              The Staff Nurse has to assist the doctor in laser and other
              dermatology related procedures
            </li>
            <li>
              The Staff Nurse has to have the knowledge about the laser machine
              how they work, precautions to be taken during procedure and risks
              involved.
            </li>
            <li>
              Staff Nurse have to take the responsibility of the laser machines/
              laser rooms that they have been assigned to and take care of day
              to day cleanliness and functioning of the machines and need to
              report to the management if anything needs attention
            </li>
            <li>
              The procedure floor Staff Nurse should make sure that all the
              items such as gauze pieces, instruments etc. are clearly
              sterilized and kept ready for procedures
            </li>
            <li>
              The procedure floor Staff Nurse is responsible for fumigation of
              the minor OT as per the infection control protocol.
            </li>
            <li>
              The procedure floor Staff Nurse are required to regularly maintain
              their registers and assigned paper work.
            </li>
            <li>
              The procedure floor Staff Nurse need to be punctual, regular and
              have a healthy patient relationship
            </li>
            <li>
              If the procedure Staff Nurse face any challenges or difficulties
              during procedures, they are required to immediately report and
              seek advice form the doctor in-charge, and patient safety should
              be their first priority.
            </li>
            <li>
              Administrative duties might include answering telephone calls and
              e-mails from patients.
            </li>
            <li>
              Establish good interdepartmental co-ordination & communication.
            </li>
            <li>
              Be adaptive to any other additional role given by management.
            </li>
          </ul>
          <p>
            <a
              href="../../assets/img/pdf/application-fellowship.docx"
              target="_blank"
              rel="noopener"
            >
              Click Here &nbsp;
            </a>
            to download application form.
          </p>
          <p>
            After filling the above form, please email us at :
            <a href="mailto:admin@cutis.org.in">admin@cutis.org.in</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Nurses;
