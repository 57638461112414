import React from "react";
import FaqItem from "./FaqItem";
import { Link } from "react-router-dom";

const FaqMainList = ({ dataArr, title }) => {
  // console.log("dataArr", dataArr);
  // console.log("dataArr", title);
  return (
    <>
      {}
      {title != "" && (
        <h3 className="faqhd" index={title}>
          {title}
        </h3>
      )}

      <ul className="faq-list" data-aos="fade-up" data-aos-delay="100">
        {dataArr &&
          dataArr.map((arrObj, index) => (
            <FaqItem arrObj={arrObj} index={index} key={index} />
          ))}
      </ul>
    </>
  );
};

const FaqList = ({ arr = [], tlt = "" }) => {
  // console.log("arr", arr);
  return (
    <>
      {arr.map(({ data: dataArr = [] }, index) => {
        if (tlt != "" && arr[index].title == tlt) {
          return (
            <a href={arr[index].link}>
              <FaqMainList
                dataArr={dataArr}
                key={index}
                title={arr[index].title}
              />
            </a>
          );
        } else if (tlt == "") {
          return (
            <a href={arr[index].link}>
              <FaqMainList
                dataArr={dataArr}
                key={index}
                title={arr[index].title}
              />
            </a>
          );
        }
      })}
    </>
  );
};

export default FaqList;
