import React from "react";
import Layout from "../layout";
import CarrerForm from "../CarrerForm";

import BgSubHeader from "../BgSubHeader";
import TablePage from "./TablePage";
import EmptySpace from "./EmptySpace";

const Observership = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"OBSERVERSHIP"} />
            <EmptySpace height={30} />
            <TablePage />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Observership;
