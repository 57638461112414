import React from "react";
import NavBarrList from "../../data/navbar.json";
import $ from "jquery";

const Footer = () => {
  React.useEffect(() => {
    setTimeout(() => {
      if ($(".nav-menu").length) {
        var $mobile_nav = $(".nav-menu").clone().prop({
          class: "mobile-nav d-lg-none",
        });
        $("body").append($mobile_nav);
        $("body").prepend(
          '<span className="menuText">Menu</span><a href="#" className="smobitrigger icofont-navigation-menu"><span>Menu</span></a>'
        );
        $("body").append('<div class="mobile-nav-overly"></div>');

        $(document).on("click", ".mobile-nav-toggle", function (e) {
          $("body").toggleClass("mobile-nav-active");
          $(".mobile-nav-toggle i").toggleClass(
            "icofont-navigation-menu icofont-close"
          );
        });

        $(document).on("click", ".mobile-nav .drop-down > a", function (e) {
          e.preventDefault();
          $(this).next().slideToggle(300);
          $(this).parent().toggleClass("active");
        });

        $(document).click(function (e) {
          var container = $(".mobile-nav, .mobile-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            if ($("body").hasClass("mobile-nav-active")) {
              $("body").removeClass("mobile-nav-active");
              $(".mobile-nav-toggle i").toggleClass(
                "icofont-navigation-menu icofont-close"
              );
              $(".mobile-nav-overly").fadeOut();
            }
          }
        });
      } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
        // console.log("SKDBHGJDKHJKFHGJDFHKGJFD");
        $(".mobile-nav, .mobile-nav-toggle").hide();
      }
    }, 1000);
  }, []);

  let ServiceList = NavBarrList[0].subMenu;
  return (
    <>
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <a href={"/"} className="logo mr-auto ">
                    <img
                      className=" logo-mobile"
                      src="/assets/img/cutis-logo.png"
                      alt="Cutis Logo"
                    />
                  </a>
                  <br />
                  <br />
                  <p className="footerAddress">
                    Cutis Address: 5/1, 4th Main, MRCR Layout, Magadi Main Rd,
                    Govindaraja Nagar Ward, MC Layout, Vijayanagar, Bengaluru,
                    Karnataka 560040
                    <br />
                    <p className="phone-links">
                      <strong>Phone: </strong>
                      <a href="tel:08023401200">080-2340-1200 (Consultation)</a>
                      <br />
                      {/* <a href="tel:9845925111">98459-25111 (Procedure)</a>
                      <br /> */}
                      <a href="tel:6366588485">63665-88485 (Cutis Kart)</a>
                      <br />
                      <a href="tel:6366589797">63665-89797 (Laboratory)</a>
                      <br />
                      <a href="tel:6366969572">63669-69572 (Pharmacy)</a>
                    </p>
                    <strong>Email:</strong>
                    <a href="mailto:care@cutis.org.in" className="email-links">
                      care@cutis.org.in
                    </a>
                    <br />
                  </p>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h3>Useful Links</h3>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/blog">Blogs</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/careers">Career</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/privacy-policy">Privacy policy</a>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="https://www.cristello.in/" target="_blank">
                      {" "}
                      Cutis x Cristello
                    </a>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="https://epicorium.com/" target="_blank">
                      {" "}
                      Epicorium{" "}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h3>Our Services</h3>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/service-at-cutis">Service At Cutis</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/skin/atopic-dermatitis">Skin</a>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/hair/hair-loss">Hair</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/nail/ingrow-toe-nail">Nail</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/nail/ingrow-toe-nail">Skin Surgery</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/hair/hair-transplantation">
                      Hair Transplantation
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/aesthetics">Aesthetic</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/pediatric-dermatology">
                      Pediatric Dermatology
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="/service/geriatric-dermatology">
                      Geriatric Dermatology
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h3 id="footer-timings">Timings</h3>
                <p className="pl-3 pb-3">
                  <strong>Monday, Tuesday, Friday :</strong>&nbsp;9 AM to 8 PM
                  <br></br>
                  <br></br>
                  <strong>Wednesday, Thursday :</strong>&nbsp;10 AM to 8 PM
                  <br></br>
                  <br></br>
                  <strong>Saturday :</strong>&nbsp;9 AM to 5 PM
                </p>
                <div className="social-links mt-3">
                  <a
                    href="https://www.facebook.com/CutisHospitals/  "
                    className="facebook"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/cutishospitalbengaluru/"
                    className="instagram"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  {/* <a href="#" className="google-plus">
                    <i className="bx bxl-skype"></i>
                  </a> */}
                  <a
                    href="https://www.linkedin.com/company/cutis-hospital-skin-hair-nail"
                    className="linkedin"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/c/CutisHospital"
                    className="youtube"
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          style={{
            textAlign: "center",
            padding: "0px",
            margin: "5px 0px 0px 0px",
          }}
        >
          <strong>Designed</strong> and <strong>Developed</strong> by{" "}
          <strong>
            <a href="https://digikit.in/" style={{ color: "#ee5a2a" }}>
              DIGIKIT
            </a>
          </strong>
        </p>
      </footer>
      {/* End Footer */}

      <div id="preloader">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            style={{ width: "auto", height: "70px" }}
            alt=""
            src="/assets/img/logo_reveal_final.gif"
          />
        </div>
      </div>
      {/* 
      <a href="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </a> */}
    </>
  );
};

export default Footer;
