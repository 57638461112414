import React from "react";

const DoctorPage = (props) => {
  let {
    name = "Dr. B.S. Chandrashekar",
    designation = "Chairman and Managing Director",
  } = props.doctor;
  return (
    <>
      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-5 text-center" data-aos="fade-right">
              <img
                src="/assets/img/home/doctors/Dr-Chandrashekar-BS.jpg"
                className="img-fluid doctorsImg"
                alt="Dr. B S Chandrashekar"
              />
              <br />
              <br />
              <a
                target="_blank"
                href="https://appointment.cutis.org.in/step-second/doctorid/2"
                className="btn btn-outline-info"
              >
                Book Appointment
              </a>
            </div>
            <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>{name}</h3>
              <div className="small-separator"></div>
              <p className="designation">{designation}</p>
              <p>
                Dr B S Chandrashekar has a data base of more than 15 lac
                patients on various dermatological diseases including acne,
                pigmentation and hair disorders. Caters to patients of
                procedural dermatology including chemical peels, laser hair
                reduction, resurfacing lasers and invasive procedures like scar
                revision and hair restoration surgery. Has more than 6 lac
                patients on Trichology. Currently supervises more than 25000
                dermatology procedures per annum
              </p>

              <h5 className="medalPTag">
                <strong className="medalPTagStrong">MEDALS / HONORS - </strong>
                <strong className="medalPTagStrong">RECIPIENT OF:</strong>
              </h5>
              <ol className="medalOl">
                <li classname="medallimb8">
                  Mysore University Golden Jubilee medal for Dermatology &ndash;
                  1995
                </li>
                <li classname="medallimb8">
                  Mysore University Topper in M.D. Dermatology &ndash; 1995
                </li>
                <li className="medalLiMB0">
                  Fellow of Diagnostic Excellence &ndash; GLAXO INDIA &ndash;
                  1997
                </li>
              </ol>

              <h5 className="medalPTag">
                <strong className="medalPTagStrong">
                  PAPERS / PRESENTATIONS:{" "}
                </strong>
              </h5>
              <p>
                Has several publications in National and International Journals.
              </p>
              <p>
                Has delivered Lectures at Regional, National and International
                forums on topics of Acne, Hair, Pigmentation, Aesthetics, Lasers
                and light based devices, Clinical dermatology and Organization
                of Dermatology Office.
              </p>
              <p>Has authored various textbooks</p>
              <p>
                Has conducted more than 30 clinical trials on Acne,
                Pigmentation, Psoriasis, Hair, Lasers and Aesthetics for
                National and Global Companies.
              </p>

              <p>
                <strong>
                  Dr. B S Chandrashekar is not only into patient care and
                  research oriented activities, but he also dedicates his time
                  into academic work to cater to the state and national needs.
                </strong>
              </p>

              <ul className="presentationsUl">
                <li classname="presentationsLi">
                  Has worked as organizing secretary for International
                  Cosmetology Conference- Cosmecon 2006
                </li>
                <li classname="presentationsLi">
                  Joint organizing secretary for Dermacon- National IADVL
                  Congress &ndash; 2009
                </li>
                <li classname="presentationsLi">
                  Secretary for Bangalore Dermatological Society which is
                  regarded as most vibrant Academic Society of IADVL 2011-2013.
                </li>
                <li classname="presentationsLi">
                  Convener &ndash; SIG Trichology 2015-2016
                </li>
                <li classname="presentationsLi">
                  Organizing Secretary / CEO &ndash; World Congress Of Cosmetic
                  Dermatology 2017
                </li>
                <li classname="presentationsLi">
                  Past President &ndash; Karnataka IADVL 2016-2017
                </li>
                <li classname="presentationsLiLast">
                  Scientific Chairman &ndash; Dermacon international 2019
                </li>
              </ul>

              <p>
                <strong>Fields of Interest – </strong> Trichology, Clinical
                Dermatology, Investigative Dermatology, Clinical trials and
                Lasers / Aesthetics
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DoctorPage;
