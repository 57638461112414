import React from "react";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import EmptySpace from "./EmptySpace";
import SectionTitle from "../home/SectionTitle";
import downloadPdf from "../../assets/application-fellowship.docx";
import notification from "../../assets/notification-23-24.pdf";
import application from "../../assets/application-fellowship-23-24-1.pdf";
import essay from "../../assets/essay.pdf";
import fellowhipImg from "../../assets/img/felloqhip_img1.jpg";
const ProceduralDermatologyContent = () => (
  <>
    <Container className="p-3 d-flex justify-content-center"></Container>
    <Container className="p-3">
      {/* <EmptySpace height={30} /> */}

      <section className="procedural_tab">
        <Tabs
          defaultActiveKey="FellowshipDermatologyApplication"
          className="fellowship-tabs"
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="FellowshipDermatologyApplication" title="">
            <div className="tabData table-width justify-content-center align-items-center">
              <SectionTitle
                title={
                  "CUTIS IS PLEASED TO ANNOUNCE ITS  PROCEDURAL DERMATOLOGY COURSES. ENROLL NOW TO ENHANCE YOUR CAREER PROSPECTS."
                }
              />

              <p className="fellowshipTabPTag fellowshipTabPTagApplication ">
                For More Information Please check the details
                <ul>
                  <li>
                    <a href="/procedural-dermatology-brochure.pdf">
                      Procedural Dermatology Brochure
                    </a>
                  </li>
                  <li className="procedural_tab_brochurelink">
                    <a href="/application-for-fellowship-programme-in-procedural-dermatology-2024-2026-batch.pdf">
                      <strong> Click here to download the - </strong>{" "}
                      Application For Fellowship Programme In Procedural
                      Dermatology - 2024-2026 Batch
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </Tab>
        </Tabs>
      </section>
    </Container>
  </>
);

export default ProceduralDermatologyContent;

{
  /* <Tab eventKey="Results" title="Results -  2022 batch">
<div className="tabData table-width justify-content-center align-items-center">
  <p className="fellowshipTabPTag">
    
    <strong>
      List Of Selected Candidates - Fellowship Programme - 2022 Batch
    </strong>
  </p>
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: DERMATOSURGERY</strong>
    <br />
    <br />
    <Table striped bordered hover width="476px">
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dr. Ruta Ujjval Joshi</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Faleiro karla Nadine</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Dr. Kulkarni Ojas Dipak</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Dr. Abishek Kumar K. V</td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: AESTHETIC DERMATOLOGY</strong>
    <br />
    <br />

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dr. Ritu Agrawal</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Supreet Kaur Dhillon</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Dr. Prasanna. E. d</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Dr. Ashwini. K. C </td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: PEDIATRIC DERMATOLOGY</strong>
    <br />
    <br />

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dr. Shilpa Mary Philip</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Kadimi Lalithya</td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
  <p className="fellowshipTabPTag">
    <strong>SUBJECT: TRICHOLOGY</strong>
    <br />
    <br />

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Selected Candidates</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>

          <td>Dr. Vaishnavi. R. Batchu</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Dr. Niveditha. N</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Dr. Shaik Sunaina</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Dr. Sandesh Kumar. C</td>
        </tr>
      </tbody>
    </Table>
  </p>
  <br />
  <br />
</div>
</Tab> */
}
