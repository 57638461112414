import React from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import publications from '../../data/academics/publications.json'


const App = () => {
    // console.log("publications",publications);
    //  console.log(publications[0].answer);
    return (
        <Container className="p-3">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Published Articles</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Books Published</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Future Plans</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div dangerouslySetInnerHTML={{ __html: publications[0].answer }}></div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div dangerouslySetInnerHTML={{ __html: publications[1].answer }}></div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div dangerouslySetInnerHTML={{ __html: publications[2].answer }}></div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    );
}

export default App;
