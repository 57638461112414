import React from "react";

const Two = ({ service }) => {
  let { title, link, subMenu = [], navClassName = "" } = service;
  // console.log("navClassName");
  const handleClick = (Linkindex) => {
    localStorage.setItem("link", Linkindex);
  };
  return (
    <>
      {subMenu.length > 0 ? (
        <>
          <li className="drop-down navClassNameLi">
            <a
              className="blueText"
              href={link}
              // onClick={(i) => {
              //   handleClick(link);
              // }}
            >
              {title}
            </a>
            <ul className={navClassName} id="navbar-scroll">
              {subMenu.map((sub) => (
                <Two key={sub.id} service={sub} />
              ))}
            </ul>
          </li>
        </>
      ) : (
        <li>
          <a
            href={link}
            className="blueText"
            onClick={(i) => {
              handleClick(link);
            }}
          >
            {title}
          </a>
        </li>
      )}
    </>
  );
};

export default Two;
