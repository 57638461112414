import React from "react";
import Layout from "../layout";
import ChiefDerm from "./ChiefDerm";
import LeadDerm from "./LeadDerm";
import LeadAsth from "./LeadAsth";
import LeadDiet from "./LeadDietitian";
import BgSubHeader from "../BgSubHeader";

const Doctor = () => {
  let { pathname } = window.location;
  // console.log("href", pathname);

  let doctor = {};

  let pageTitle = "";

  if (pathname == "/lead-dermatosurgeon") {
    pageTitle = "CHIEF HAIR TRANSPLANT AND DERMATOSURGEON";
  } else if (pathname == "/chief-dermatologist") {
    pageTitle = "CHIEF DERMATOLOGIST";
  } else if (pathname == "/lead-aesthetic-dermatologist") {
    pageTitle = "SENIOR CONSULTANT & LEAD AESTHETIC DERMATOLOGIST";
  } else if (pathname == "/nutritionist") {
    pageTitle = "CLINICAL NUTRITIONIST";
  }

  const getDoctorPage = () => {
    if (pathname == "/lead-dermatosurgeon") {
      return <LeadDerm doctor={doctor} />;
    } else if (pathname == "/chief-dermatologist") {
      return <ChiefDerm doctor={doctor} />;
    } else if (pathname == "/lead-aesthetic-dermatologist") {
      return <LeadAsth doctor={doctor} />;
    } else if (pathname == "/nutritionist") {
      return <LeadDiet doctor={doctor} />;
    }
  };

  // console.log(pageTitle);

  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader titleClass="doctorBgSubHeader " title={pageTitle} />
            {getDoctorPage()}
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Doctor;
