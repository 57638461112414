//  PACKAGES
import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//  COMPONENTS

import Home from "./home";
// import Home from "../CutisHome/Home";
// import ServicesOne from "./services/One";
// import ServicesTwo from "./services/Two";
import Service from "./services";

import Blog from "./blog";
import Faq from "./Faqs";
import PatientStories from "./PatientStories";
import PrivacyPolicy from "./PrivacyPolicy";
import Helemt from "./Helmet";
import Video from "./gallery/Video";

import Doctor from "./doctors/Doctor";
import DoctorList from "./doctors/DoctorList";

import Contact from "./Contact";
import NurseEducator from "./NurseEducator";
import Nurse from "./Nurse";
import Carrer from "./Carrer";
import ConsultantC from "./ConsultantC";
import PediatricDermatologist from "./PediatricDermatologistMain";
import VisitingConsultantMain from "./VisitingConsultantMain";
import Register from "./Register";

import EducationalProgramme from "./academics/EducationalProgramme";
import Observership from "./academics/Observership";
import Publications from "./academics/Publications";
import Fellowship from "./academics/Fellowship";
import ProceduralDermatology from "./academics/ProceduralDermatology";

import DocDetailPage from "./doctors/DocDetailPage";

import AboutUs from "./AboutUs";
import PatientTestimonials from "./PatientTestimonials";
import CustomerTestimonials from "./CelebrityTestimonials";

import ImagePage from "./gallery/ImagePage";
import ImagePageFac from "./gallery/ImagePageFac";
import ImagePageEvent from "./gallery/ImagePageEvent";
import Handout from "./gallery/Handout";
import BlogMain from "./gallery/BlogMain";
import EventVideos from "./gallery/EventVideos";
import Error from "../data/ErrorPage";
import Sitemap from "./Sitemap";
import Preloader from "./gallery/Preloader";
import HospitalSchema from "./HospitalSchema";
import Redirection from "./Redirection";
// import EducationalProgramme from './academics/EducationalProgramme';
// import EducationalProgramme from './academics/EducationalProgramme';

//  ROUTER

const Routes = () => (
  <Router>
    <Helemt />
    <HospitalSchema />
    {/* <Redirection /> */}
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/service/:id" component={Service} />
      <Route path="/blog/:id" component={Blog} />
      <Route path="/blog" component={BlogMain} />
      <Route path="/faqs" component={Faq} />
      <Route path="/patient-stories" component={PatientStories} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />

      <Route path="/patient-educational-video" component={Video} />
      <Route path="/cutis-events" component={EventVideos} />
      <Route
        path="/our-doctors-at-scientific-forums"
        component={ImagePageEvent}
      />
      <Route path="/infrastructure-at-cacs" component={ImagePageFac} />
      {/* <Route path="/departments-staffs" component={ImagePage} /> */}
      <Route path="/chief-dermatologist" component={Doctor} />
      <Route path="/lead-dermatosurgeon" component={Doctor} />
      <Route path="/lead-aesthetic-dermatologist" component={Doctor} />
      <Route path="/nutritionist" component={Doctor} />
      <Route path="/consultants" component={DoctorList} />
      <Route path="/doctor/:docName/:id" component={DocDetailPage} />
      <Route path="/contact" component={Contact} />
      <Route path="/careers" component={Carrer} />
      <Route path="/nurse-educators" component={NurseEducator} />
      <Route path="/consultant" component={ConsultantC} />
      <Route
        path="/pediatric-dermatologist"
        component={PediatricDermatologist}
      />
      <Route path="/visiting-consultant" component={VisitingConsultantMain} />

      <Route path="/nurses" component={Nurse} />
      <Route path="/registrar" component={Register} />
      <Route path="/educational-programme" component={EducationalProgramme} />
      <Route path="/fellowship" component={Fellowship} />
      <Route path="/procedural-dermatology" component={ProceduralDermatology} />
      <Route path="/observership" component={Observership} />
      <Route path="/publications" component={Publications} />
      <Route path="/about" component={AboutUs} />
      <Route path="/patient-testimonials" component={PatientTestimonials} />
      <Route path="/celebrity-testimonials" component={CustomerTestimonials} />
      <Route path="/handout" component={Handout} />
      <Route path="/sitemap" component={Sitemap} />
      <Route path="/preloader" component={Preloader} />
      {/* <Route path="/404" component={Redirection} /> */}
      <Route path="/404" component={Error} />
      {/* <Redirect to="/404" /> */}
      {/* <Route path="/service-one" component={ServicesOne}/>
            <Route path="/service-three" component={Service}/> */}
      <Route path="*" component={Redirection} />
    </Switch>
  </Router>
);

export default Routes;
