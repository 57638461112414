import React from "react";
import Layout from "../layout";
import ImageList from "./LightboxPageFac";
import ImageList2 from "./LightboxPageFacExt";
import ImageList3 from "./LightboxPageFacProc";
import ImageList4 from "./LightboxPageFacOpd";
import ImageList5 from "./LightboxPageFacPhar";
import ImageList6 from "./LightboxPageFacGround";
import BgSubHeader from "../BgSubHeader";
import EmptySpace from "../academics/EmptySpace";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const PatientStories = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero" className="infa">
            <BgSubHeader title={"INFRASTRUCTURE AT CACS"} />

            <Container className="p-3">
              <Tabs defaultActiveKey="Exterior" id="uncontrolled-tab-example">
                <Tab eventKey="Exterior" title=" Exterior">
                  <div className="tabData">
                    <ImageList2 />
                  </div>
                </Tab>
                <Tab eventKey="ground" title=" Ground Floor">
                  <div className="tabData">
                    <ImageList6 />
                  </div>
                </Tab>
                <Tab eventKey="opd" title="OPD">
                  <div className="tabData">
                    <ImageList4 />
                  </div>
                </Tab>
                <Tab eventKey="Procedure" title=" Procedure Floor">
                  <div className="tabData">
                    <ImageList3 />
                  </div>
                </Tab>

                <Tab eventKey="pharmacy" title=" Pharmacy">
                  <div className="tabData">
                    <ImageList5 />
                  </div>
                </Tab>
              </Tabs>
            </Container>
          </section>
        </main>
      </Layout>

      {/* <Tab eventKey="home" title=" Interior">
        <div className="tabData">
          <ImageList />
        </div>
      </Tab> */}
    </>
  );
};

export default PatientStories;
