import React, { useEffect } from "react";

import { Redirect } from "react-router-dom";
import ErrorPage from "../data/ErrorPage";

export default function Redirection() {
  const RedirectionArray = [
    {
      link: ["/acne-banglore.html"],
      redirect: "/service/skin/pimples",
    },
    {
      link: ["/acne-banglore.html"],
      redirect: "/service/skin/pimples",
    },
    {
      link: ["/acne-banglore.html"],
      redirect: "/service/skin/pimples",
    },
    {
      link: ["/wp-content/themes/cutis/assets/img"],
      redirect: "/",
    },
    {
      link: ["/body-shaping"],
      redirect: "/service/body-shaping",
    },
    {
      link: ["/wp-content/plugins/revslider/public/assets/js"],
      redirect: "/",
    },
    {
      link: ["/wp-admin/admin-ajax.php"],
      redirect: "/",
    },
    {
      link: ["/aesthetic-dermatology"],
      redirect: "/service/aesthetics",
    },
    {
      link: ["/fellowship-doctors"],
      redirect: "/fellowship",
    },
    {
      link: ["/registrars"],
      redirect: "/",
    },
    {
      link: ["/author/cutis/feed"],
      redirect: "/",
    },
    {
      link: ["/hair/hairfall-control-and-hair-regrowth"],
      redirect: "/service/hair/hairfall-control-and-hair-regrowth",
    },
    {
      link: ["/wp-content/themes/cutis/assets/img"],
      redirect: "/",
    },
    {
      link: ["/body-shaping"],
      redirect: "/service/body-shaping",
    },
    {
      link: ["/static/media/notification-for-fellowship-cutis.878bc5b8.pdf"],
      redirect: "/fellowship",
    },
    {
      link: ["/;"],
      redirect: "/",
    },
    {
      link: ["/wp-admin/admin-ajax.php"],
      redirect: "/",
    },
    {
      link: ["/static/media/application-fellowship-23-24.8c5be325.pdf"],
      redirect: "/fellowship",
    },

    {
      link: ["/aesthetic-dermatology"],
      redirect: "/lead-aesthetic-dermatologist",
    },
    {
      link: ["/fellowship-doctors"],
      redirect: "/fellowship",
    },
    {
      link: ["/static/media/notification-for-fellowship-cutis.878bc5b8.pdf"],
      redirect: "/",
    },
    {
      link: ["http://cutis.org.in/body-shaping-banglore.html"],
      redirect: "/service/body-shaping",
    },
    {
      link: ["/body-shaping-banglore.html"],
      redirect: "/service/body-shaping",
    },
    {
      link: ["/registrars"],
      redirect: "/registrar",
    },
    {
      link: ["/hair/hairfall-control-and-hair-regrowth"],
      redirect: "/service/hair/hairfall-control-and-hair-regrowth",
    },
    {
      link: ["/author/cutis/feed"],
      redirect: "/",
    },
    {
      link: ["http://cutis.org.in/b"],
      redirect: "/",
    },
    {
      link: ["/b"],
      redirect: "/",
    },
    {
      link: ["/skin/eczema"],
      redirect: "/service/skin/atopic-dermatitis-treatment-in-bangalore",
    },
    {
      link: [
        "/wp-includes/js/dist/vendor/wp-polyfill-dom-rect.min.js?ver=3.42.0",
      ],
      redirect: "/",
    },
    {
      link: [
        "/wp-includes/js/dist/vendor/wp-polyfill-dom-rect.min.js?ver=3.42.0",
      ],
      redirect: "/",
    },
    {
      link: ["/covid-19-help-desk"],
      redirect: "/contact",
    },
    {
      link: ["/surgery-banglore.html"],
      redirect: "/service/service-at-cutis",
    },
    {
      link: ["/facilities-at-cacs"],
      redirect: "/about",
    },
    {
      link: ["/skin/atopic-dermatitis"],
      redirect: "/service/skin/atopic-dermatitis-treatment-in-bangalore",
    },
    {
      link: ["/skin/atopic-dermatitis"],
      redirect: "/service/skin/atopic-dermatitis-treatment-in-bangalore",
    },
    {
      link: ["/hair"],
      redirect: "/service/hair/hair-loss",
    },
    {
      link: ["/taking-care-of-your-scalp"],
      redirect: "/blog/taking-care-of-your-scalp",
    },

    {
      link: ["/departments-staffs"],
      redirect: "/consultants",
    },
    {
      link: ["/departments-staffs/"],
      redirect: "/consultants",
    },
    {
      link: ["/pediatric-banglore.html"],
      redirect: "/service/pediatric-dermatology",
    },
    {
      link: ["/hair-transplantation-banglore.html"],
      redirect: "/service/hair/hair-transplantation",
    },
    {
      link: ["/service/lasers-an"],
      redirect: "/service/lasers-and-lights",
    },
    {
      link: ["/blog/2"],
      redirect: "/blog",
    },
    {
      link: ["/blog/Hydration"],
      redirect: "/blog/tips-for-hydrating-and-nourishing-your-skin",
    },
    {
      link: ["/blog/8"],
      redirect: "/blog",
    },
    {
      link: ["/blog/4"],
      redirect: "/blog",
    },
    {
      link: ["/blog/6"],
      redirect: "/blog",
    },
    {
      link: [
        "/wp-includes/js/dist/vendor/wp-polyfill-node-contains.min.js?ver=3.42.0",
      ],
      redirect: "/",
    },
    {
      link: ["/embed"],
      redirect: "/",
    },
    {
      link: ["/404"],
      redirect: "/",
    },
    {
      link: ["/doctor/dr.-shruthi-madhavi.g/9"],
      redirect: "/consultants",
    },
  ];
  function removeSlash(link) {
    if (link.slice(-1) === "/") {
      return link.slice(0, -1);
    } else {
      return link;
    }
  }

  useEffect(() => {
    let WebLink = removeSlash(window.location.pathname);

    const redirectObj = RedirectionArray.find((item) =>
      item.link.includes(WebLink)
    );

    if (redirectObj) {
      window.location.href = redirectObj.redirect;
    }

    if (!redirectObj) {
      if (window.location.pathname != "/404") {
        window.location.href = "/";
      }
    }

    // if (redirectObj) {
    //   return <Redirect to={redirectObj.redirect} />;
    // } else {
    //   return <Redirect to="/404" />;
    // }
  }, []);

  // return <ErrorPage />;
}
