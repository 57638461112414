import React from "react";
import Container from "react-bootstrap/Container";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import doctorList from "../../data/doctors/doctors_consultants.json";

const DocListDetail = ({ doc }) => {
  let {
    id,
    firstname = "",
    lastname = "",
    title = "",
    image = "",
    qualification = "",
    academics = "",
    research = "",
    link = "",
    bookAppiontmentLink = "",
    alt = "",
  } = doc;
  let docImg = require(`../../assets/img/doctors/${image}`);

  let name = firstname;
  if (lastname && lastname !== ".") {
    name = name + " " + lastname;
  }
  const docUrl = `/doctor/${name
    .split(" ")
    .join("-")
    .toLocaleLowerCase()}/${id}`;

  console.log(link);
  console.log(bookAppiontmentLink);
  return (
    <Col className="doctorListCol" xs={12} sm={6}>
      <a href={docUrl}>
        {/* <Link to={{pathname:docUrl}}> */}
        <Card>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <Card.Img
                  className="docCardImage"
                  src={docImg.default}
                  alt={alt}
                />
              </Col>
              <Col xs={6}>
                <Card.Title className="docCardName">{firstname}</Card.Title>
                <h6 className="docCardTitle">
                  {`${title}`}
                  <br />
                  <br /> {` ${qualification}`}
                </h6>
                <hr />

                {/* <p>Know More...</p> */}

                <br />
                <br />
                {bookAppiontmentLink ? (
                  <a
                    target="_blank"
                    href={link}
                    className="btn btn-outline-info  consultant-book-btn"
                  >
                    Book Appointment
                  </a>
                ) : (
                  ""
                )}
                {/* <a
                  target="_blank"
                  href={link}
                  className="btn btn-outline-info  consultant-book-btn"
                >
                  Book Appointment
                </a> */}

                {/* <h5>Academics</h5>
                                <div dangerouslySetInnerHTML={{ __html: academics }} />

                                <h5>{"Publications & Research"}</h5>
                                <div dangerouslySetInnerHTML={{ __html: research }} /> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </a>
      {/* </Link> */}
    </Col>
  );
};

const DoctorListContent = () => {
  console.log("doctorList", doctorList);

  return (
    <Container className="p-3">
      <CardDeck>
        <Row>
          {doctorList &&
            doctorList.map((doc) => {
              if (doc.doctorShow == true) {
                return <DocListDetail doc={doc} key={doc.id} />;
              }
            })}
        </Row>
      </CardDeck>
    </Container>
  );
};

export default DoctorListContent;
