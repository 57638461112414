import React from "react";

export default function Preloader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src="../../assets/img/logo_reveal_final.gif"></img>
    </div>
  );
}
