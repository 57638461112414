import React from "react";
import Layout from "../layout";
import BgSubHeader from "../BgSubHeader";
import DocDetail from "./DocDetail";

import doctorList from "../../data/doctors/doctors_consultants.json";

const DocDetailPage = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  let docId = pathnameArr[3];
  //  console.log("id",id);

  const doctor = doctorList.find((member) => member.id === docId);
  if (!doctor) {
    window.location.href = "/consultants";
  }
  let {
    id,
    firstname = "",
    lastname = "",
    title = "",
    image = "",
    qualification = "",
    academics = "",
    research = "",
    membership = "",
  } = doctor;
  firstname = firstname.toUpperCase();

  let desc = `${title} - ${qualification}`;

  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader
              titleClass="doctorBgSubHeader   "
              title={firstname}
              docDesc={desc}
            />
            <DocDetail doctor={doctor} />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default DocDetailPage;
