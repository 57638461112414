import React from "react";
import Layout from "../layout";
import BgSubHeader from "../BgSubHeader";
import FellowshipPage from "./FellowshipPage";
import EmptySpace from "./EmptySpace";

const Fellowship = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"FELLOWSHIP"} />
            <EmptySpace height={30} />
            <FellowshipPage />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Fellowship;
