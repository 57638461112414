import React from "react";

const DoctorPage = ({ doctor }) => {
  let {
    firstname = "",
    lastname = "",
    title = "",
    image = "",
    qualification = "",
    academics = "",
    research = "",
    bookAppiontmentLink = "",
    position,
  } = doctor;

  let name = `${firstname} ${lastname}`;

  let docImg = require(`../../assets/img/doctors/${image}`);

  let desc = `${title} - ${position ? position : qualification}`;

  return (
    <>
      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-5 text-center" data-aos="fade-right">
              <img
                src={docImg.default}
                className="img-fluid doctorsImg"
                alt=""
              />
              <br />
              <br />
              {bookAppiontmentLink ? (
                <a
                  target="_blank"
                  href="https://appointment.cutis.org.in/"
                  className="btn btn-outline-info"
                >
                  Book Appointment
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>{name}</h3>
              <div className="small-separator"></div>
              <p className="designation">{desc}</p>
              <br />

              <h5 className="medalPTag medalPTag_consul">
                <strong className=" medalPTagStrong_cosul medalPTagStrong">
                  Academics
                </strong>
              </h5>

              <p dangerouslySetInnerHTML={{ __html: academics }} />

              <h5 className="medalPTag medalPTag_consul ">
                <strong className=" medalPTagStrong_cosul medalPTagStrong  ">
                  Publications and Research
                </strong>
              </h5>
              <div dangerouslySetInnerHTML={{ __html: research }} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DoctorPage;
