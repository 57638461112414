import React from "react";
import Container from "react-bootstrap/Container";
import Gallery from "react-grid-gallery";

import galleryArr from "../../data/blogs.json";

const BlogList = ({ imgData = {} }) => {
  let {
    docImg = "",
    title = "",
    text = "",
    docLink = "",
    image = "",
    link = "",
    date = "",
  } = imgData;
  return (
    <>
      <div className="col-sm-6 mb-4">
        <a href={link}>
          <img
            src={image}
            className="w-100 border-dark border img-size-blog"
            alt={title}
          />
        </a>
        <h5 className="my-2">{title}</h5>
        <i className="blogDT">{date}</i>
        <p className="text-justify">{text}</p>
        <a href={link} className="btn btn-outline-info m-auto">
          Read More
        </a>
      </div>
    </>
  );
};

//export default HandoutList;

const App = () => {
  const [imageTopSectionList, setImageTopSectionList] = React.useState([]);

  React.useEffect(() => {
    let galleryNewArr = galleryArr.map((ga) => {
      let dt = new Date(ga.date).toDateString();
      ga.date = dt.substr(3, dt.length);
      // let docImg = ga.image;
      // docImg = docImg.default;
      // ga.docImg = docImg;
      // let docLink = ga.link;
      // docLink = docLink.default;
      // ga.docLink = docLink;
      return ga;
    });

    //console.log("galleryNewArr", galleryNewArr);
    setImageTopSectionList(galleryNewArr);
  }, []);

  return (
    <div className="container">
      <div className="row">
        {/* {imageTopSectionList &&
          imageTopSectionList.map((img) => (
            <BlogList key={img.id} imgData={img} />
          ))} */}
          {galleryArr.map((img) =>(
            <BlogList key={img.id} imgData={img}/>
          )) }
      </div>
    </div>
  );
};
export default App;
