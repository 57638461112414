import React from "react";

export default function HospitalSchema() {
  if (window.location.pathname.split("/").includes("blog")) {
    return "";
  }

  const HospitalSchemas = `<script type='application/ld+json'>
    {
        "@context": "http://www.schema.org",
        "@type": "Hospital",
        "name": "Cutis Hospital - Skin Hair Nail : Best Dermatology Hospital in Bangalore",
        "url": "https://cutis.org.in/",
        "logo": "https://cutis.org.in/assets/img/cutis-logo-with-tm-600-new.png",
        "priceRange": "$",
        "image": “https://cutis.org.in/static/media/nabh-website.b0754a5f.png",
        "description": "Cutis Hospital Bangalore is the leading dermatology clinic in Bangalore, the foremost clinic for comprehensive skin, hair, and nail services.",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "5/1, 4th Main, MRCR Layout, Magadi Main Rd, Govindaraja Nagar Ward, MC Layout, Vijayanagar",
            "addressLocality": "Bengaluru",
            "addressRegion": "Karnataka",
            "postalCode": "560040",
            "addressCountry": "India"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "12.9768507",
            "longitude": "77.5449516"
        },
        "hasMap": "https://www.google.com/maps/place/CUTIS+HOSPITAL+-+SKIN+HAIR+NAIL+:+Best+Dermatology+Hospital+in+Bangalore/@12.976851,77.544952,16z/data=!4m6!3m5!1s0x3bae3e7745e40249:0xe19453ab9e37d117!8m2!3d12.9768507!4d77.5449516!16s%2Fg%2F1tf68hj1",
        "openingHours": "Mo 09:00-20:00 Tu 09:00-20:00 We 10:00-20:00 Th 10:00-20:00 Fr 10:00-20:00 Sa 00:00-17:00",
        "telephone": "080-2340-1200"
    }
    </script>
`;

  return (
    <iframe srcDoc={HospitalSchemas} title="Blog Schema" width="0" height="0" />
  );
}
