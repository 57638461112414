import React from "react";
import Layout from "./layout";
import Faq from "./home/Faq";
import Pat from "./home/ps";
// import ServiceData from './ServiceData';
// import MoreServices from '../home/featuredService/MajorServices';

import BgSubHeader from "./BgSubHeader";

import patientStoriesFaq from "../data/Faq/patientStories.json";

import img1 from "../assets/img/service/bg/05.jpg";

const img1Div = { backgroundImage: "url(" + img1 + ")" };
const PatientStories = () => {
  // let { pathname } = window.location;
  // console.log("href", pathname);
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"PATIENT STORIES"} />
            {/* 
            
                        <div className="serviceContent">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ServiceData service={service}/>
                                    </div>
                                </div>
                        </div> */}
            <Pat faqArr={patientStoriesFaq} />
          </section>
          {/* <MoreServices/> */}
        </main>
      </Layout>
    </>
  );
};

export default PatientStories;
