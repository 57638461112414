import React from "react";
import Layout from "./layout";
import EmptySpace from "./academics/EmptySpace";
import BgSubHeader from "./BgSubHeader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const celebsvideos = [
  {
    videoId: "8",
    videoLink: "https://www.youtube.com/embed/SvJjcbdCyI0",
  },

  {
    videoId: "7",
    videoLink: "https://www.youtube.com/embed/xE7Ehi-VDtk",
  },

  {
    videoId: "6",
    videoLink: "https://www.youtube.com/embed/5C8op2Xg_eI",
  },

  {
    videoId: "5",
    videoLink: "https://www.youtube.com/embed/okR3-W-MTgw",
  },

  {
    videoId: "4",
    videoLink: "https://www.youtube.com/embed/QFdwd0_w-F8",
  },

  {
    videoId: "3",
    videoLink: "https://www.youtube.com/embed/Lit5x-1EzJU",
  },

  {
    videoId: "2",
    videoLink: "https://www.youtube.com/embed/TwFUOslrB2E",
  },
  {
    videoId: "1",
    videoLink: "https://www.youtube.com/embed/5bj7LD3bum4",
  },
];

const CelebrityTestimonials = () => {
  function CelebSchema() {
    const video1 = `

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Actress Bhavana Rao shares her experience with Cutis Hospital Bangalore",
      "description": "Popular Sandalwood Actress Bhavana Rao shares the awesome experience she had with Cutis Hospital Bangalore. 
    We are gratified that we could help you overcome your skin problems. Thank You for recommending CUTIS.
    
    #cutisbangalore #cutishospitalbangalore #cutisbangaloreskinhairnail #bhavanarao #actress #celebrity #celebritytestimonial
    
    Visit Us @ https://www.cutis.org.in/
    Follow Us on:
    Facebook: https://www.facebook.com/CutisHospitals
    Instagram: https://www.instagram.com/cutishospit...",
      "thumbnailUrl": "https://i9.ytimg.com/vi/5bj7LD3bum4/mqdefault.jpg",
      "uploadDate": "2021-07-06",
      "duration": "PT1M57S",
      "contentUrl": "https://www.youtube.com/watch?v=5bj7LD3bum4&t=1s",
      "embedUrl": "https://www.youtube.com/embed/5bj7LD3bum4"
    }
    </script>
      
  `;
    const video2 = `
   
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Mr. Dil Raju, Indian Film Producer shares his experience with Cutis Hospital Bangalore",
  "description": "Thank You Mr. Dil Raju, Indian Film Producer for sharing your experience with Cutis. We are happy to hear that we could help you overcome your hair loss problems.
 
#cutisbangalore #cutishospitalbangalore #cutisbangaloreskinhairnail #dilraju #indianfilmproducer #celebrity #celebritytestimonial

Visit Us @ https://www.cutis.org.in/
Follow Us on:
Facebook: https://www.facebook.com/CutisHospitals
Instagram: https://www.instagram.com/cutishospit...",
  "thumbnailUrl": "https://i9.ytimg.com/vi/TwFUOslrB2E/mq1.jpg",
  "uploadDate": "2021-08-07",
  "duration": "PT0M35S",
  "contentUrl": "https://www.youtube.com/watch?v=TwFUOslrB2E",
  "embedUrl": "https://www.youtube.com/embed/TwFUOslrB2E"
}
</script>


`;
    const video3 = `
   
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Actor Sadhu Kokila shares his experience with Cutis",
      "description": "Actor Sadhu Kokila shares his wonderful experience with Cutis. 
    We are delighted that you liked our services and thank you for recommending Cutis to everyone for skin, hair, and nail problems. 
    
    #cutisbangalore #cutishospitalbangalore #cutisbangaloreskinhairnail #sadhukokila #actor #celebrity
    
    Visit Us @ https://www.cutis.org.in/
    Follow Us on:
    Facebook: https://www.facebook.com/CutisHospitals
    Instagram: https://www.instagram.com/cutishospit...",
      "thumbnailUrl": "https://i9.ytimg.com/vi/Lit5x-1EzJU/maxresdefault.jpg",
      "uploadDate": "2021-06-07",
      "duration": "PT1M57S",
      "contentUrl": "https://www.youtube.com/watch?v=Lit5x-1EzJU",
      "embedUrl": "https://www.youtube.com/embed/Lit5x-1EzJU"
    }
    </script>
    
    

`;
    const video4 = `
    

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Mrs. Pratibha Saunshimath, shares her journey with Cutis.",
      "description": "Mrs. Pratibha Saunshimath, the first South Indian to hold Mrs Asia International, Mrs Photogenic 2015, Mrs India 2015, and now Director Mrs Karnataka sharing her wonderful experience with Cutis. We are glad that you loved our services. 
    Thank you for sharing your journey with us and recommending Cutis to everyone for their skin, hair related problems. 
    
    Visit Us @ www.cutis.org.in
    Follow Us on:
    Facebook: https://www.facebook.com/CutisHospitals
    Instagram: https://www.instagram.com/cutishospit...
    
    #cutisbangalore #cutishospitalbangalore #cutisbangaloreskinhairnail #pratibhasaunshimath",
      "thumbnailUrl": "https://i9.ytimg.com/vi/QFdwd0_w-F8/mq1.jpg",
      "uploadDate": "2021-05-07",
      "duration": "PT8M13S",
      "contentUrl": "https://www.youtube.com/watch?v=QFdwd0_w-F8&t=2s",
      "embedUrl": "https://www.youtube.com/embed/QFdwd0_w-F8"
    }
    </script>
    
    
    

`;
    const video5 = `
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Actor Prem, shares his journey with Cutis.",
      "description": "Our very own Lovely Star Nenapirali Prem shares his wonderful experience with Cutis. 
    Team Cutis is glad that you loved our services.
    Thank you for sharing your journey with us and recommending Cutis to everyone for their skin-related problems.",
      "thumbnailUrl": "https://i9.ytimg.com/vi/okR3-W-MTgw/mq3.jpg",
      "uploadDate": "2021-04-16",
      "duration": "PT1M20S",
      "contentUrl": "https://www.youtube.com/watch?v=okR3-W-MTgw",
      "embedUrl": "https://www.youtube.com/embed/okR3-W-MTgw"
    }
    </script>
    
      
`;
    const video6 = `
   

    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Rakshita Prem, Actress & Film Producer, Shares Her Journey with Cutis.",
      "description": "Listen to what our beloved Rakshitha Prem has to say about Cutis and her experience here. 
    
    We are always happy to have you here at Cutis.. you bring so much joy and positivity each time you are around!",
      "thumbnailUrl": "https://i9.ytimg.com/vi/5C8op2Xg_eI/mq3.jpg",
      "uploadDate": "2021-03-17",
      "duration": "PT2M57S",
      "contentUrl": "https://www.youtube.com/watch?v=5C8op2Xg_eI",
      "embedUrl": "https://www.youtube.com/watch?v=5C8op2Xg_eI"
    }
    </script>
    
    
`;
    const video7 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Actress Anitha Bhat shares her experience with Cutis Hospital Bangalore",
  "description": "Sandalwood Actress Anitha Bhat shares her experience with Cutis Hospital Bangalore. 
We are delighted that we could help you overcome your skin and hair-related problems. Thank You for recommending CUTIS.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/xE7Ehi-VDtk/mqdefault.webp",
  "uploadDate": "2021-11-25",
  "duration": "PT1M43S",
  "contentUrl": "https://youtu.be/xE7Ehi-VDtk",
  "embedUrl": "https://www.youtube.com/embed/xE7Ehi-VDtk"
}
</script>
`;
    return (
      <div>
        <iframe srcDoc={video1} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video2} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video3} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video4} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video5} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video6} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video7} title="Video Schema" width="0" height="0" />
      </div>
    );
  }
  return (
    <>
      <Layout>
        <section id="serviceHero">
          <BgSubHeader title={"CELEBRITY TESTIMONIALS"} />
          <EmptySpace height={10} />
          <CelebSchema />
          <div className="container mb-4 ">
            <div className="row align-items-center justify-content-center">
              {celebsvideos.map((value, index) => (
                <div className="col-10 col-md-4  mt-4 " style={video_container}>
                  <span className="card p-1 border-0" key={index}>
                    <iframe
                      width="100%"
                      height="200px"
                      src={value.videoLink}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </span>
                  {/* <p style={styles}>{value.patientTitle}</p> */}
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

const styles = {
  backgroundColor: "white",
  textAlign: "center",
  alignItems: "center",
  fontSize: "20px",
};
const video_container = {};
export default CelebrityTestimonials;
