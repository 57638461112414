import React from "react";
import Layout from "./layout";
import EmptySpace from "./academics/EmptySpace";
import BgSubHeader from "./BgSubHeader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const patientVideos = [
  {
    patientVideoId: "12",
    patientVideoLink: "https://www.youtube.com/embed/pol463ZouIk?controls=0",
    patientTitle: "Hair Transplantation Testimonial",
  },
  {
    patientVideoId: "11",
    patientVideoLink: "https://www.youtube.com/embed/LuOJaBZCCtU?controls=0",
    patientTitle: "Hormonal Acne Treatment",
  },
  {
    patientVideoId: "10",
    patientVideoLink: "https://www.youtube.com/embed/BytMK7auZNQ?controls=0",
    patientTitle: "Hormonal Acne Treatment",
  },

  {
    patientVideoId: "9",
    patientVideoLink: "https://www.youtube.com/embed/OkRVu11MHhk?controls=0",
    patientTitle: "Hormonal Acne Treatment",
  },

  {
    patientVideoId: "8",
    patientVideoLink: "https://www.youtube.com/embed/3NnV57jca4k?controls=0",
    patientTitle: "Hormonal Acne Treatment",
  },

  {
    patientVideoId: "7",
    patientVideoLink: "https://www.youtube.com/embed/_ZD9Bh8Im5A?controls=0",
    patientTitle: "Hormonal Acne Treatment",
  },

  {
    patientVideoId: "6",
    patientVideoLink: "https://www.youtube.com/embed/Dn1kbgGiSvg?controls=0",
    patientTitle: "Hormonal Acne Treatment",
  },

  {
    patientVideoId: "5",
    patientVideoLink: "https://www.youtube.com/embed/MqO6ewP-4uk?controls=0",
    patientTitle: "",
  },

  {
    patientVideoId: "4",
    patientVideoLink: "https://www.youtube.com/embed/BB50fiMvV9Y?controls=0",
    patientTitle: "Hyperpigmentation ",
  },

  {
    patientVideoId: "3",
    patientVideoLink: "https://www.youtube.com/embed/Edxm16BvaEQ?controls=0",
    patientTitle: "Hemangioma ( Birth marks ) ",
  },

  {
    patientVideoId: "2",
    patientVideoLink: "https://www.youtube.com/embed/DSUxo6IpXJo?controls=0",
    patientTitle: "",
  },

  {
    patientVideoId: "1",
    patientVideoLink: "https://www.youtube.com/embed/rZWeIwn20qM?controls=0",
    patientTitle: "Hair Transplantation",
  },
];
const PatientTestimonials = () => {
  function Homqe() {
    const video1 = `
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Cutis Patient Stories | Hair Transplantation",
      "description": "Thank You Mr. Arvind for sharing your experience with Cutis. We are happy to hear that we could help you overcome your hair loss problems. 
    
    #cutisbangalore #cutishospitalbangalore #cutisbangaloreskinhairnail #haircare #hairloss #baldness #hairtreatment #hairtransplant #hairregrowth #patientstories #patienttestimonial
    
    Visit Us @ https://www.cutis.org.in/",
      "thumbnailUrl": "https://i9.ytimg.com/vi/rZWeIwn20qM/maxresdefault.jpg",
      "uploadDate": "2021-07-26",
      "duration": "PT1M49S",
      "contentUrl": "https://www.youtube.com/watch?v=rZWeIwn20qM",
      "embedUrl": "https://www.youtube.com/embed/rZWeIwn20qM"
    }
    </script>    
  `;
    const video2 = `
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Patient Stories | Patient Testimonial | Cutis Hospital Bangalore",
      "description": "We are glad that you liked our service and could get over your skin problems. Thank you for recommending Cutis to everyone for Skin, Hair, and Nail problems.
    
    #cutisbangalore #cutishospitalbangalore #cutisbangaloreskinhairnail #patientstories #patienttestimonial
    
    Visit Us @ https://www.cutis.org.in/",
      "thumbnailUrl": "https://i9.ytimg.com/vi/DSUxo6IpXJo/mq1.jpg",
      "uploadDate": "2021-06-23",
      "duration": "PT1M2S",
      "contentUrl": "https://www.youtube.com/watch?v=DSUxo6IpXJo&t=3s",
      "embedUrl": "https://www.youtube.com/embed/DSUxo6IpXJo"
    }
    </script>
`;
    const video3 = `
   
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cutis Patient Stories - Hemangioma ( Birth marks ) | Cutis Hospital Bangalore",
  "description": "Today we are going to share a story of a patient who suffered from Hemangioma since birth and currently undertaking the treatment at Cutis Hospital Bangalore.

Thank you Mrs. Manjula for sharing your story with the world.

#cutishospitalbanglore #cutispatientstories",
  "thumbnailUrl": "https://i9.ytimg.com/vi/Edxm16BvaEQ/mqdefault.jpg",
  "uploadDate": "2021-09-01",
  "duration": "PT2M41S",
  "contentUrl": "https://www.youtube.com/watch?v=Edxm16BvaEQ",
  "embedUrl": "https://www.youtube.com/embed/Edxm16BvaEQ"
}
</script>


`;
    const video4 = `
    
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cutis Patient Stories | Skin Care | Hyperpigmentation | Cutis Hospital Bangalore",
  "description": "Thank You, Ashwini for sharing your lovely experience with Cutis. We are happy to hear that we could help you overcome your skin problems.

#cutishospitalbanglore #cutisbangalore​​ #cutisbangaloreskinhairnail​ #cutispatientstories",
  "thumbnailUrl": "https://i9.ytimg.com/vi/BB50fiMvV9Y/maxresdefault.jpg?",
  "uploadDate": "2021-09-06",
  "duration": "PT1M8S",
  "contentUrl": "https://www.youtube.com/watch?v=BB50fiMvV9Y",
  "embedUrl": "https://www.youtube.com/embed/BB50fiMvV9Y"
}
</script>

`;
    const video5 = `
    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Patient stories, Cutis Hospital, Vijay Kumar",
      "description": "Cutis Patient Stories | Skin Care| Port-wine stain
    
    Thank you, Vijay Kumar for sharing your lovely experience on behalf of your daughter with Cutis. We are happy to hear that we could help you.",
      "thumbnailUrl": "https://i9.ytimg.com/vi/MqO6ewP-4uk/mq1.jpg",
      "uploadDate": "2021-10-05",
      "duration": "PT3M4S",
      "contentUrl": "https://www.youtube.com/watch?v=MqO6ewP-4uk",
      "embedUrl": "https://www.youtube.com/embed/MqO6ewP-4uk"
    }
    </script>
      
`;
    const video6 = `
   
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cutis Patient Stories - Hormonal Acne Treatment | Cutis Hospital Bangalore",
  "description": "Here is a story of a patient who had acne problems due to hormonal changes and is currently taking treatment at Cutis Hospital Bangalore.
Thank You, Manasa for sharing your story.

#cutishospitalbanglore #cutispatientstories",
  "thumbnailUrl": "https://i9.ytimg.com/vi/Dn1kbgGiSvg/maxresdefault.jpg",
  "uploadDate": "2021-10-05",
  "duration": "PT1M38S",
  "contentUrl": "https://www.youtube.com/watch?v=Dn1kbgGiSvg",
  "embedUrl": "https://www.youtube.com/embed/Dn1kbgGiSvg"
}
</script>

`;
    const video7 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cutis Patient Stories | Linear Porokeratosis",
  "description": "Thank You Ms. Shlisha for sharing your experience with Cutis. We are happy that we could help you overcome your skin problems.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/LuOJaBZCCtU/mq2.jpg",
  "uploadDate": "2021-11-18",
  "duration": "PT7M30S",
  "contentUrl": "https://youtu.be/LuOJaBZCCtU",
  "embedUrl": "https://www.youtube.com/embed/LuOJaBZCCtU"
}
</script>

 `;
    const video8 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cutis Patient Stories | Hyper pigmentation",
  "description": "Thank You Mrs. Saya for sharing your experience with Cutis. We are happy that we could help you overcome your skin problems.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/BytMK7auZNQ/mq2.jpg",
  "uploadDate": "2021-11-12",
  "duration": "PT1M14S",
  "contentUrl": "https://youtu.be/BytMK7auZNQ",
  "embedUrl": "https://www.youtube.com/embed/BytMK7auZNQ"
}
</script>
 `;
    const video9 = ` <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cutis patient stories | Skin care | PCOD",
  "description": "Thank You, Dr. Priyanka for sharing your lovely experience with Cutis. We are happy to hear that we could help you overcome your skin problems.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/OkRVu11MHhk/mq1.jpg",
  "uploadDate": "2021-11-09",
  "duration": "PT1M15S",
  "contentUrl": "https://youtu.be/OkRVu11MHhk",
  "embedUrl": "https://www.youtube.com/embed/OkRVu11MHhk"
}
</script>

`;
    const video10 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Patient stories | Cutis Hospital | Sambhrami",
  "description": "Thank You, Sambhrami for sharing your lovely experience with Cutis. We are happy to hear that we could help you overcome your skin problems.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/3NnV57jca4k/mq3.jpg",
  "uploadDate": "2021-10-28",
  "duration": "PT2M45S",
  "contentUrl": "https://youtu.be/3NnV57jca4k",
  "embedUrl": "https://www.youtube.com/embed/3NnV57jca4k"
}
</script>
 `;
    const video11 = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Patient stories | Cutis Hospital | Jeevitha rani",
  "description": "Thank You, Jeevitha for sharing your lovely experience with Cutis. We are happy to hear that we could help you overcome your skin problems.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/_ZD9Bh8Im5A/mq3.jpg",
  "uploadDate": "2021-10-21",
  "duration": "PT1M51S",
  "contentUrl": "https://youtu.be/_ZD9Bh8Im5A",
  "embedUrl": "https://www.youtube.com/embed/_ZD9Bh8Im5A"
}
</script>
 `;

    return (
      <div>
        <iframe srcDoc={video1} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video2} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video3} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video4} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video5} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video6} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video7} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video8} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video9} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video10} title="Video Schema" width="0" height="0" />
        <iframe srcDoc={video11} title="Video Schema" width="0" height="0" />
      </div>
    );
  }

  return (
    <>
      <Layout>
        <main id="main">
          <BgSubHeader title={"PATIENT TESTIMONIALS"} />
          <EmptySpace height={10} />
          <Homqe />
          <div className="container mb-4 ">
            <div className="row align-items-center justify-content-center">
              {patientVideos.map((value, index) => (
                <div className="col-10 col-md-4 mt-4 " style={video_container}>
                  <span className="card p-1 border-0" key={index}>
                    <iframe
                      width="100%"
                      height="200px"
                      src={value.patientVideoLink}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </span>
                  {/* <p style={styles}>{value.patientTitle}</p> */}
                </div>
              ))}
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

const video_container = {};
export default PatientTestimonials;
