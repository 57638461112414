import React from 'react'

//  EMPTY SPACE 
const EmptySpace = ({height=0}) => {
    let heightPx = `height_${height}`;
    return (
        <React.Fragment>
            <div className={`${heightPx}`}></div>
        </React.Fragment>
    )
}
export default EmptySpace;