import React from "react";
import { useEffect } from "react";
import Blogjson from "../../data/blogs.json";
import { blogSchema } from "./Blogschem";
import docData from "../../data/doctors/doctors_consultants.json";
import doc from "../../assets/img/doctors/ritu_agarwal.jpg";
import { useState } from "react";
const Index = ({ blog }) => {
  const [authorData, setAuthorData] = useState([]);
  function setdate(dt) {
    let _dt = new Date(dt).toDateString();
    return _dt.substr(3, _dt.length);
  }
  let FaqContent;
  let FaqMainContent;

  Blogjson.map((title, index) => {
    if (title.title === blog.title) {
      FaqMainContent = [Blogjson[index]];
      FaqContent = Blogjson[index].data;
    }
  });
  useEffect(() => {
    if (blog.doctorId) {
      let doctorFilter = docData.filter((item) => item.id == blog.doctorId);

      setAuthorData(doctorFilter[0]);
    }
  }, []);
  const getImagePath = (name) => {
    return require(`../../assets/img/doctors/${name}`).default;
  };
  return (
    <>
      <div className="service-details-content blog-details-content">
        <div className="row">
          <div className="col-md-8">
            <div className="service-thumbnail-wrapper">
              <img src={blog.image} alt={blog.title} className="service-img" />
            </div>
          </div>
          <div className="col-md-4">{/* <ServiceSideBar/> */}</div>
        </div>

        <div className="service-details-info">
          <h2>{blog.title}</h2>
          <hr className="mb-1"></hr>
          <div className="d-flex mb-3">
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-calendar2-event-fill  text-info"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM11.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
              </svg>
              &nbsp;&nbsp;
              {setdate(blog.date)}
            </span>
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill text-info"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              &nbsp;&nbsp;
              <b>Posted By :</b> {blog.posted}
            </span>
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-flag-fill text-info"
                viewBox="0 0 16 16"
              >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
              </svg>
              &nbsp;&nbsp;
              <b>Category :</b> {blog.category}
            </span>
            <span className="ml-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chat-dots-fill text-info"
                viewBox="0 0 16 16"
              >
                <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              &nbsp;&nbsp;
              {blog.comments}
            </span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: blog.detailed_text }} />
        </div>
        {authorData && (
          <div className="blog_author_details">
            {/* <img src="../../assets/img/doctors/ritu_agarwal.jpg" /> */}

            {authorData?.image && <img src={getImagePath(authorData?.image)} />}

            <h3>{authorData.firstname}</h3>
            <p>{authorData.title}</p>
          </div>
        )}

        {/* {
          <iframe
            srcDoc={SelectedBlogSchema[0]?.schemaCode}
            title="Faq Schema"
            width="0"
            height="0"
          />
        } */}
      </div>
    </>
  );
};

export default Index;
