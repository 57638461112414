import React from "react";

const FaqItem = ({ arrObj, index, tlt }) => {
  let { id = "", question = "", answer = "", img = "" } = arrObj;
  // console.log("index", index);
  let hrefIndex = index + 1;

  let aClass = "collapsed";
  let divClass = "collapse";

  if (index == 0) {
    aClass = "";
    divClass = "collapse show";
  }

  let aFaqIndex = `#faq${hrefIndex}`;
  let divFaqIndex = `faq${hrefIndex}`;

  return (
    <>
      <li>
        <a data-toggle="collapse" href={aFaqIndex} className={aClass}>
          {question}
          <i className="icofont-minus-square"></i>
        </a>

        <div id={divFaqIndex} className={divClass} data-parent=".faq-list">
          {img != "" && <img className="w-100" src={img} />}
          <div dangerouslySetInnerHTML={{ __html: answer }}></div>
        </div>
      </li>
    </>
  );
};

export default FaqItem;
