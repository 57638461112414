import React from "react";
import ServiceSideBar from "./ServiceSideBar";
import Gallery from "./Gallery";
import Testimonal from "../../CutisHome/Testimonials";

import ImageList from "../gallery/HandoutList";

import FaqJson from "../../data/Faq/home.json";
import ServiceAccordian from "./ServiceAccordian";
import FaqItem from "../home/FaqItem";
import FaqList from "../home/FaqList";
import { FaqSchema } from "./ServiceDataFaq.js";
const Index = ({ service }) => {
  let videoListItems = [];
  let videoListItemsShow = false;
  if (service.hasOwnProperty("videos")) {
    for (let value of Object.values(service.videos))
      videoListItems.push("https://www.youtube.com/embed/" + value);
    videoListItemsShow = true;
  }

  function Videoplayer(props) {
    return (
      <iframe
        src={props.link}
        height="250px"
        width="350px"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }
  // console.log(FaqJson);
  // console.log(FaqJson[0].faqServiceTitle);

  // console.log(service.faqTitle);

  // const faqLink = FaqJson.filter((title) => {
  //   return title.faqServiceTitle;
  // });
  let FaqContent;
  let FaqMainContent;

  FaqJson.map((title, index) => {
    if (title.faqServiceTitle === service.faqTitle) {
      FaqMainContent = [FaqJson[index]];
      FaqContent = FaqJson[index].data;
    }
  });

  // console.log(FaqMainContent[0].title);
  // FaqSchema.map((items) => {
  //   console.log(items.title === FaqMainContent[0].title);
  // });
  const SelectedFaqSchema = FaqSchema.filter((items) => {
    return items.title === FaqMainContent[0].title;
  });

  // console.log(SelectedFaqSchema[0].schemaCode);
  // console.log(FaqContent);
  // console.log(FaqMainContent);
  // console.log("raghu");
  // console.log(FaqSchema);
  // FaqJson.map((items) => {
  //   return items.title === FaqSchema.title;
  // });

  // FaqSchema.map((items) => {
  //   console.log(items);
  // });

  return (
    <>
      <div className="service-details-content">
        {videoListItemsShow ? (
          <div className="section-title section-service-video-title mt-5">
            <h2>Service Videos</h2>
          </div>
        ) : (
          " "
        )}

        <div className="container mb-5">
          <div className="row align-items-center justify-content-center">
            {videoListItems.map((service) => (
              <div className="col-sm-10 col-md-6 col-lg-4 d-flex justify-content-center">
                <Videoplayer link={service} />
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="service-thumbnail-wrapper">
              <img
                src={service.innerImg}
                alt={service.title}
                className="service-img"
              />
            </div>
          </div>
          <div className="col-md-4">
            <ServiceSideBar />
          </div>
        </div>
        <div className="service-details-info">
          <h2>{service.title.toUpperCase()}</h2>
          <div dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }} />
        </div>

        <div className="mt-4">
          <ImageList tlt={service.title} />
        </div>

        {/* <div className="service-accordian">
          <h4>{FaqMainContent.title}</h4>
          <ServiceAccordian data={FaqContent} /> 
          <FaqList arr={FaqMainContent} />
         <ServiceAccordian Faqdata={FaqContent} /> 
        </div> */}

        {service.faqDisplay && (
          <div className="section-title section-title-faq mt-5 ">
            <h2>Faq</h2>
          </div>
        )}
        {service.faqDisplay && (
          <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-12 faqSecDiv faqServicesDiv">
                  {/* <div className="section-title"></div> */}
                  <FaqList arr={FaqMainContent} />
                </div>
              </div>
            </div>
          </section>
        )}
        {
          <iframe
            srcDoc={SelectedFaqSchema[0]?.schemaCode}
            title="Faq Schema"
            width="0"
            height="0"
          />
        }
        {/* <div className="col-lg-12 faqSecDiv">
          <div className="section-title whiteBg">
            <h2>Frequently Asked Questioins</h2>
            <p>
              Because of environmental changes skin disease will also be on rise
              . With Corona around, you are not able to meet skin specialist /
              Dermatologist. So, here are the tips to follow and get relieved
              from skin disease.
            </p>
          </div>

          <FaqList arr={FaqMainContent} />
        </div> */}

        <Gallery />
        <Testimonal />
      </div>
    </>
  );
};

export default Index;
