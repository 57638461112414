import React from "react";
const DoctorPage = (props) => {
  let {
    name = "Dr. Chaithra Shenoy",
    designation = "Medical Superintendent & HOD Aesthetic Department",
  } = props.doctor;
  return (
    <>
      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-5 text-center" data-aos="fade-right">
              <img
                src="/assets/img/home/doctors/DR-CHAITHRA-SHENOY-MD.jpg"
                className="img-fluid doctorsImg"
                alt="Dr. Chaithra Shenoy - Lead Aesthetic Dermatologist & Consultant Dermatologist at Cutis"
              />
              <br />
              <br />
              <a
                target="_blank"
                href="https://appointment.cutis.org.in/step-second/doctorid/5"
                className="btn btn-outline-info"
              >
                Book Appointment
              </a>
            </div>
            <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>{name}</h3>
              <div className="small-separator"></div>
              <p className="designation">{designation}</p>
              <br />

              <h5 className="medalPTag">
                <strong className="medalPTagStrong">ACADEMICS </strong>
              </h5>
              <ul className="presentationsUl">
                <li classname="presentationsLi">
                  MMCRI, Mysore in M.D.DERMATOLOGY – 2009 to 2012
                </li>
                <li classname="presentationsLi">
                  Fellowship in Aesthetic Dermatology 2016
                </li>
                <li>
                  FRGUHS (Aesthetic Dermatology) from CUTIS Academy of Cutaneous
                  Sciences, Bengaluru affiliated to Rajiv Gandhi University of
                  Health Sciences, Bangalore.
                </li>
              </ul>

              <h5 className="medalPTag">
                <strong className="medalPTagStrong">
                  PUBLICATIONS AND RESEARCH{" "}
                </strong>
              </h5>
              <ul className="presentationsUl">
                <li classname="presentationsLi">
                  Special field of interests- Injectables, Pigment Lasers and
                  Chemical peels.
                </li>
                <li classname="presentationsLi">
                  Her work has been published in National and International
                  Journals.
                </li>
                <li>
                  Complication of laser and light based devices therapy in
                  patients with skin of colour I J D V | Volume 85 | Issue 1 |
                  January-February 201;24-31
                  <br />
                  <strong>Authors :</strong> Chandrashekar B S, Chaithra Shenoy,
                  Madura C
                </li>
                <li>
                  Effectiveness and safety of a novel topical depigmenting agent
                  in epidermal pigmentation: an open-label, non-comparative
                  study: International Journal of Research in Dermatology
                  October-December 2018 | Vol 4 | Issue 4
                  <br />
                  <strong>Authors:</strong> B. S. Chandrashekar, Chaithra
                  Shenoy, Lakshmi Narayana N.
                </li>
                <li>
                  3. Tofacitinib (Selective Janus Kinase Inhibitor 1 and 3): A
                  Promising Therapy for the Treatment of Alopecia Areata: A Case
                  Report of Six Patients
                  <br />
                  <strong>Authors :</strong> Chandrashekar B S, Chaithra Shenoy,
                  R ArtiPriya
                </li>
                <li>
                  4. WATER LILY IN subcutaneous plane- Preventing complications
                  of unplanned excision. Int J Dermatol. 2017 Jul;56(7):767-769
                  <br />
                  <strong>Authors :</strong> Madura, C, Chaithra Shenoy,Girish,
                  M.L, Chandrashekar B.S
                </li>{" "}
              </ul>
              <div>
                <h5 className="medalPTag">
                  <strong className="medalPTagStrong">Presentations</strong>
                </h5>
                <ul className="presentationsUl">
                  <li>
                    <strong> “Periorbital Rejuvenation with Lasers”-</strong>
                    World Congress of Cosmetic Dermatology Bangalore 2017
                  </li>
                  <li>
                    <strong></strong> Gold Toning- 595nm Laser for Acne and
                    Facial redness World Congress of Cosmetic Dermatology
                    Bangalore 2017
                  </li>
                  <li>
                    {" "}
                    <strong>“Sensitive skin”-</strong> Mid Cuticon Chikmagalur
                    2017
                  </li>
                  <li>
                    <strong>“Hair cosmetics “- </strong> Bangalore Dermatology
                    Society 2019{" "}
                  </li>
                  <li>
                    <strong> Xeroderma Pigmentosum-</strong> a rare case
                    series”- in the Award Paper session at 1st CUTICON- KN 2010.
                  </li>
                  <li>
                    <strong>
                      “A study of clinico-epidemiological profile of cutaneous
                      manifestation in HIV positive patients and its correlation
                      with CD4 counts”-
                    </strong>{" "}
                    in the Free paper session (DERMACON 2011.
                  </li>
                  <li>
                    <strong>
                      “Pemphigus Vulgaris in 3rd trimester of Pregnancy- a rare
                      presentation”-
                    </strong>
                    in the Award Paper session at 2nd CUTICON-KN 2011.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DoctorPage;
