import React from "react";
import Layout from "../layout";
import BgSubHeader from "../BgSubHeader";
import DoctorListContent from "./DoctorListContent";

const DoctorList = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader
              titleClass=" big-text-consul doctorBgSubHeader "
              title={"CONSULTANTS"}
            />
            <DoctorListContent />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default DoctorList;
