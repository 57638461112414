import React from "react";
import Container from "react-bootstrap/Container";
import Gallery from "react-grid-gallery";

import galleryArr from "../../data/Gallery/handout.json";

const HandoutList = ({ imgData = {}, tlt = false }) => {
  let { docImg = "", title = "", text = "", docLink = "", alt = "" } = imgData;
  let istlt = "col-sm-3 mb-4";
  if (tlt) {
    istlt = "col-sm-3 mb-4 m-auto";
  }
  return (
    <>
      <div className={`${istlt} handoutscontainer`}>
        <img src={docImg} className="w-100 border-dark border" alt={alt} />
        <h3 className="my-2">{title}</h3>
        <p className="text-justify">{text}</p>
        <a
          href={docLink}
          target="_blank"
          className="btn btn-outline-info m-auto"
        >
          Download <i class="bx bxs-down-arrow-alt"></i>
        </a>
      </div>
    </>
  );
};

//export default HandoutList;

const App = ({ tlt = "" }) => {
  const [imageTopSectionList, setImageTopSectionList] = React.useState([]);
  let tltcls = "hndout section-title d-none";

  React.useEffect(() => {
    let galleryNewArr = galleryArr.map((ga) => {
      let docImg = require(`../../assets/img/gallery/handout/${ga.image}`);
      docImg = docImg.default;
      ga.docImg = docImg;
      let docLink = require(`../../assets/img/gallery/handout/${ga.link}`);
      docLink = docLink.default;
      ga.docLink = docLink;
      return ga;
    });
    if (tlt != "") {
      var _i = galleryNewArr.filter((d) => {
        if (d.title == tlt) {
          return d;
        }
      }).length;
      if (_i > 0) {
        //tltcls = "section-title";
        setTimeout(() => {
          eval('$(".hndout.section-title").removeClass("d-none")');
        }, 1000);
      }
    }
    // console.log("galleryNewArr", galleryNewArr);
    setImageTopSectionList(galleryNewArr);
  }, []);
  return (
    <>
      <div className={tltcls}>
        <h2>HANDOUTS</h2>
      </div>
      <div className="container">
        <div className="row">
          {imageTopSectionList &&
            imageTopSectionList.map((img, index) => {
              if (tlt != "" && img.title == tlt) {
                return <HandoutList key={img.id} imgData={img} tlt={true} />;
              } else if (tlt == "") {
                return <HandoutList key={img.id} imgData={img} />;
              }
            })}
        </div>
      </div>
    </>
  );
};
export default App;
