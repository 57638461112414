import React from "react";
import Layout from "../layout";
import ImageList from "./LightboxPageReact";

import BgSubHeader from "../BgSubHeader";
import EmptySpace from "../academics/EmptySpace";

const PatientStories = () => {
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <BgSubHeader title={"OUR DOCTORS AT SCIENTIFIC FORUMS"} />
            <EmptySpace height="30" />
            <ImageList />
          </section>
        </main>
      </Layout>
    </>
  );
};

export default PatientStories;
