import React from "react";
import "./HomeStyle.css";
// npm install slick-carousel --save
// npm install slick-carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../assets/img/home/major_services/hairtreatment.jpg";
import img2 from "../assets/img/home/major_services/laserhair.jpg";
import img3 from "../assets/img/home/major_services/acne.jpg";
import img4 from "../assets/img/home/major_services/antiaging.jpg";
import img5 from "../assets/img/home/major_services/acnescars.jpg";
import img6 from "../assets/img/home/major_services/complexion.jpg";
import img7 from "../assets/img/home/major_services/pigmentation.jpg";
import { Link } from "react-router-dom";

function MajorServices() {
  const mjorServicesData = [
    {
      id: 1,
      customUrl: "/service/hair/hair-transplantation",
      heading: "Hair Transplant",
      content:
        "CACS provides a permanent solution for hair loss, providing a safe & relatively painless treatment to restore & grow hair naturally.",
      imageUrl: `${img1}`,
    },
    {
      id: 2,
      customUrl: "/service/laser-hair-reduction",
      heading: "Laser Hair Reduction",
      content:
        "CACS offers four types of laser technology like IPL (Ellipse), Alexandrite (Clarity), Nd:YAG (Fotona), and Diode laser (Lumenis), to remove unwanted hair. It is a safe and painless procedure and does not damage your skin.",
      imageUrl: `${img2}`,
    },
    {
      id: 3,
      customUrl: "/service/skin/pimples",
      heading: "Acne",
      content:
        "Acne is one of the most common skin conditions that affect both men & women. Depending on the severity of your acne, CACS offers Chemical peels and lasers for active acne.",
      imageUrl: `${img3}`,
    },
    {
      id: 4,
      customUrl: "/service/anti-ageing",
      heading: "Anti-aging",
      content:
        "CACS provides several treatments that will slow down & reverse the aging process. (Different techniques are applied depending on the type, texture & age of skin.)",
      imageUrl: `${img4}`,
    },
    {
      id: 5,
      customUrl: "/service/aesthetics/acne-scar",
      heading: "Acne Scar",
      content:
        "CACS specializes in treating all types of acne scars by utilizing the surgical and nonsurgical modality of  treatment",
      imageUrl: `${img5}`,
    },
    {
      id: 6,
      customUrl: "/service/aesthetics/skin-radiance",
      heading: "Skin Radiance",
      content:
        "Facial skin radiance is the most commonly sought desire of the patients. Skin radiance adds to facial attractiveness, boosts confidence, and improves the presentability of an individual.",
      imageUrl: `${img6}`,
    },
    {
      id: 7,
      customUrl: "/service/skin/pigmentation",
      heading: "Pigmentation",
      content:
        "CACS offers various advanced treatment options for Pigmentation that helps in reducing melanin content safely and effectively",
      imageUrl: `${img7}`,
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    cseEase: "linear",
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    swipeToSlide: true,
  };
  //   const Title =
  //     window.location.href == "http://localhost:3000/"
  //       ? `h1 className="text-center text-til my-3 mb-3">
  // Leading Dermatology Hospital in Bangalore
  // </h1>`
  //       : `h2 className="text-center text-til my-3 mb-3">
  // Leading Dermatology Hospital in Bangalore
  // </h2>`;

  let TitleTag = false;
  if (
    window.location.href === "https://cutis.org.in/" ||
    window.location.href === "http://localhost:3000/"
  ) {
    TitleTag = true;
  }
  const CustomTag = TitleTag ? `h2` : `h1`;

  return (
    <>
      <div
        className="container my-3 my-sm-5"
        id="majors_services_home"
        data-aos="fade-up"
      >
        <div className="need-heading" style={{ textAlign: "center" }}>
          {TitleTag && <h1>LEADING DERMATOLOGY HOSPITAL IN BANGALORE</h1>}
        </div>
        <div className="need-heading">
          <h2 className="text-center text-til my-3">MAJOR SERVICES</h2>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col">
            <Slider {...settings}>
              {mjorServicesData.map((value, index) => (
                <div className="m-service-card" key={index}>
                  <Link
                    to={value.customUrl}
                    className="myCutomLinkCSS"
                    target="_blank"
                  >
                    <div className="m-service-box p-3 text-center card">
                      <div className="m-service-img">
                        <img
                          src={value.imageUrl}
                          alt={value.heading}
                          loading="lazy"
                        />
                      </div>
                      <div className="m-service-content">
                        <Link
                          to={value.customUrl}
                          className="myCutomLinkCSS"
                          target="_blank"
                        >
                          <h2 className="text-center my-2">{value.heading}</h2>
                        </Link>
                        <p>{value.content}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default MajorServices;
