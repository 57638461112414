import React from "react";
import ReactDOM from "react-dom";
// import Helmet from './components/Helmet';
//  import './index.css';
import Router from "./components/Router";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-modal-video/scss/modal-video.scss";
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';

ReactDOM.render(
  <>
    {/* <Helmet /> */}
    <Router />
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
